import classes from "./CourseLessonRead.module.css";
import { useContext, useEffect, useState } from "react";
import { calculateReadingTime } from "../../HelperFunctions/calclateReadingTime";
import { CourseContext } from "../../Context/CourseContext";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

type CourseLessonReadProps = {
  activeModuleWeeks: any;
};

const CourseLessonRead = ({ activeModuleWeeks }: CourseLessonReadProps) => {
  const [isInvalid, setIsInvalid] = useState(true);
  const [activeContent, setActiveContent] = useState<any>();
  const [ranCOmplete, setRanComplete] = useState(false);

  // Context
  const {
    setReadingProgressObject,
    setEntityProgress,
    setEntityProgressObject,
    getCourseModules,
    setBookmarksHandler,
    setBookmark,
    deleteBookmarks,
    getCoursesById,
  } = useContext(CourseContext);

  // Router
  const currentParams = new URLSearchParams(window.location.search);
  const activeContentId = currentParams?.get("activeContentId");
  const activeWeekId = currentParams?.get("activeWeek");
  const activeModuleId = currentParams?.get("activeModule");
  const activeCohort = currentParams?.get("activeCohort");

  const { courseId } = useParams();

  useEffect(() => {
    if (activeModuleWeeks) {
      setActiveContent(
        activeModuleWeeks
          .find((data: any) => {
            return data?.id === Number(activeWeekId);
          })
          ?.records?.find((data: any) => {
            return data?.title === activeContentId?.toString();
          })
      );

      if (activeContent?.type !== "reading") {
        setIsInvalid(true);
      } else {
        setIsInvalid(false);
      }
    }

    // eslint-disable-next-line
  }, [activeModuleWeeks, activeWeekId, activeContentId]);

  useEffect(() => {
    if (activeContent) {
      setReadingProgressObject((prevState) => {
        return {
          ...prevState,
          readingId: activeContent?.id,
          completed: true,
          progress: 100,
        };
      });
    }

    // eslint-disable-next-line
  }, [activeContent]);

  useEffect(() => {
    if (!setBookmark?.isLoading && setBookmark?.data) {
      getCourseModules(courseId as string, activeCohort as string, false, true);
    }

    // eslint-disable-next-line
  }, [setBookmark]);

  useEffect(() => {
    if (ranCOmplete) {
      getCourseModules(courseId as string, activeCohort as string, false, true);
    }

    // eslint-disable-next-line
  }, [ranCOmplete]);

  if (isInvalid) {
    return (
      <section className={classes.header}>
        <h2>Invalid content type</h2>
      </section>
    );
  }

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <div>
          <h2>{activeContent?.title}</h2>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M12 8.5V12.5L15 15.5M21 12.5C21 17.4706 16.9706 21.5 12 21.5C7.02944 21.5 3 17.4706 3 12.5C3 7.52944 7.02944 3.5 12 3.5C16.9706 3.5 21 7.52944 21 12.5Z"
                stroke="#2BA792"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>
              {calculateReadingTime(activeContent?.reading_contents || "")}
            </span>
          </span>
        </div>
        <div
          onClick={() => {
            if (activeContent?.bookmarks) {
              deleteBookmarks(activeContent?.bookmarks?.id);
            } else {
              setBookmarksHandler(
                activeContent?.id,
                "reading",
                courseId as string,
                activeModuleId as string,
                activeWeekId as string
              );
            }
          }}
        >
          {setBookmark.isLoading ? (
            <CircularProgress
              size={"1rem"}
              color="inherit"
              style={{ color: "inherit" }}
            />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill={activeContent?.bookmarks ? "#011627" : "none"}
            >
              <path
                d="M5 5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L12 17.5L5 21V5Z"
                stroke="#011627"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </div>

      <div
        className={classes.contentReadSection}
        dangerouslySetInnerHTML={{ __html: activeContent?.reading_contents }}
      ></div>
      <div className={classes.completedContainer}>
        <div
          className={classes.completed}
          onClick={() => {
            if (
              (activeContent as any)?.reading_progresses ||
              !(activeContent as any)?.reading_progresses?.completed
            ) {
              setRanComplete(true);
              setEntityProgress(
                activeContent?.id,
                courseId as string,
                activeModuleId as string,
                activeWeekId as string,
                "reading"
              );
            }
          }}
        >
          {setEntityProgressObject?.isLoading ? (
            <CircularProgress
              size={"1rem"}
              color="inherit"
              style={{ color: "inherit" }}
            />
          ) : (activeContent as any)?.reading_progresses?.is_completed ? (
            "Completed"
          ) : (
            "Mark as completed"
          )}
        </div>
      </div>
    </section>
  );
};

export default CourseLessonRead;
