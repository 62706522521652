import FAQDropdown from "../../Components/FAQDropdown/FAQDropdown";
import classes from "./ReferralFaqs.module.css";

const ReferralFaqs = () => {
  const faqs = [
    {
      question:
        "The enrolment deadline is coming up - what if the friends I refer miss the deadline?",
      answer: "",
    },
    {
      question: "How does the referral program on iṣẹ́ EdTech work?",
      answer: "",
    },
    {
      question:
        "Is there a time limit for how long it takes me to refer people?",
      answer: "",
    },
    {
      question: "How do I get my rewards?",
      answer: "",
    },
    {
      question: "How can I see how many people I have referred?",
      answer: "",
    },
    {
      question: "Are there any restrictions on who I can refer?",
      answer: "",
    },
  ];
  return (
    <section className={classes.container}>
      <h4>Frequently asked questions</h4>

      {faqs.map((data, i) => {
        return (
          <div className={classes.faq} key={i}>
            <FAQDropdown question={data.question} answer={data.answer} />
          </div>
        );
      })}
    </section>
  );
};

export default ReferralFaqs;
