import { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import EligibilityBackground from "../Containers/EligibilityBackground/EligibilityBackground";
import EligibilityELearning from "../Containers/EligibilityELearning/EligibilityELearning";
import EligibilityFailureContainer from "../Containers/EligibilityFailureContainer/EligibilityFailureContainer";
import EligibilityPreview from "../Containers/EligibilityPreview/EligibilityPreview";
import EligibilitySuccess from "../Containers/EligibilitySuccess/EligibilitySuccess";
import EligibilityTechnical from "../Containers/EligibilityTechnical/EligibilityTechnical";
import { AuthUserContext } from "../Context/AuthUserContext";
import { CourseContext } from "../Context/CourseContext";

const Eligibility = () => {
  // Router
  const location = useLocation();
  const userStep = new URLSearchParams(location.search).get("step");
  const status = new URLSearchParams(location.search).get("status");
  const { courseId } = useParams();

  // COntext
  const { getEligibilityQuestions } = useContext(AuthUserContext);
  const { getCoursesById } = useContext(CourseContext);

  // Effects
  useEffect(() => {
    getEligibilityQuestions(courseId as string);
    getCoursesById(courseId as string);

    // eslint-disable-next-line
  }, []);

  return (
    <Layout closeSideNav>
      {userStep === "1" && status === null ? (
        <EligibilityBackground />
      ) : userStep === "2" && status === null ? (
        <EligibilityTechnical />
      ) : userStep === "3" && status === null ? (
        <EligibilityELearning />
      ) : status === "pass" && userStep === "3" ? (
        <EligibilitySuccess />
      ) : status === "fail" && userStep === "3" ? (
        <EligibilityFailureContainer />
      ) : (
        <EligibilityPreview />
      )}
    </Layout>
  );
};

export default Eligibility;
