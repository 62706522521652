import { Dispatch, SetStateAction } from "react";
import CourseLessonQuizQuestionContaoner from "../Containers/CourseLessonQuizQuestionContaoner/CourseLessonQuizQuestionContaoner";

type CourseLessonQuizProps = {
  allRecords: any;
  setActiveRecordIndex: Dispatch<SetStateAction<any>>;
};

const CourseLessonQuiz = ({
  allRecords,
  setActiveRecordIndex,
}: CourseLessonQuizProps) => {
  return (
    <CourseLessonQuizQuestionContaoner
      allRecords={allRecords}
      setActiveRecordIndex={setActiveRecordIndex}
    />
  );
};

export default CourseLessonQuiz;
