import Layout from "../Components/Layout/Layout";
import CourseLessonsContainer from "../Containers/CourseLessonsContainer/CourseLessonsContainer";

const CourseLessons = () => {
  return (
    <Layout>
      <CourseLessonsContainer />
    </Layout>
  );
};

export default CourseLessons;
