import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { CourseContext } from "../../Context/CourseContext";
import Loader from "../Loader/Loader";

const RequirePayWall = () => {
  // COntext
  const { getCourseModulesObject } = useContext(CourseContext);

  // Location
  const location = useLocation();

  // Context
  const { user } = useContext(AuthUserContext);

  console.log(getCourseModulesObject?.error, getCourseModulesObject?.isLoading);

  return (
    <>
      {!getCourseModulesObject?.isLoading && getCourseModulesObject?.error ? (
        <Navigate to="/courses" replace={true} state={location.pathname} />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default RequirePayWall;
