import { useContext } from "react";
import Button from "../../Components/Button/Button";
import Error from "../../Components/Error/Error";
import Input from "../../Components/Input/Input";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import TextArea from "../../Components/TextArea/TextArea";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { capitalize } from "../../HelperFunctions/capitalize";
import { inputChangeHandler } from "../../HelperFunctions/inputChangeHandler";
import classes from "./ProgramAdvisorsForm.module.css";

const ProgramAdvisorsForm = () => {
  // COntext
  const {
    contactProgramAdvisorObject,
    setContactProgramAdvisorObject,
    contactProgramAdvisorRequest,
    eligibilityContactProgramAdvisor,
  } = useContext(AuthUserContext);

  return (
    <div className={classes.container}>
      <div className={classes.leftSection}>
        <SectionsHeader
          paragraph="WE’RE HERE TO HELP"
          header="Send us a message"
          description="What questions do you have about the program? We're happy to help."
        />
      </div>
      <form className={classes.rightSection}>
        <div className={classes.feedback}>
          {contactProgramAdvisorRequest.data && (
            <Error type="success">
              {capitalize(contactProgramAdvisorRequest?.data as string)}
            </Error>
          )}

          {contactProgramAdvisorRequest.error && (
            <Error type="error">
              {capitalize(contactProgramAdvisorRequest?.error as string)}
            </Error>
          )}
        </div>

        <Input
          label="Name"
          placeholder="e.g John Doe"
          name="name"
          value={contactProgramAdvisorObject.name}
          onChange={(e) => {
            inputChangeHandler(e, setContactProgramAdvisorObject);
          }}
        />
        <Input
          label="Email"
          placeholder="name@gmail.com"
          type="email"
          name="email"
          value={contactProgramAdvisorObject.email}
          onChange={(e) => {
            inputChangeHandler(e, setContactProgramAdvisorObject);
          }}
        />
        <TextArea
          label="Your message"
          placeholder="Kindly write your message here"
          name="message"
          value={contactProgramAdvisorObject.message}
          onChange={(e) => {
            inputChangeHandler(e, setContactProgramAdvisorObject);
          }}
        />
        <div className={classes.buttonSection}>
          <Button
            loading={contactProgramAdvisorRequest.isLoading}
            onClick={(e) => {
              e.preventDefault();
              eligibilityContactProgramAdvisor();
            }}
          >
            <span className={classes.buttonInner}>
              <span>Send message</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17 8L21 12M21 12L17 16M21 12L3 12"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ProgramAdvisorsForm;
