import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import Loader from "../Components/Loader/Loader";
import CourseDetailBreadcrumbs from "../Containers/CourseDetailBreadcrumbs/CourseDetailBreadcrumbs";
import CourseDetailGetToKnow from "../Containers/CourseDetailGetToKnow/CourseDetailGetToKnow";
import CourseDetailHeroSection from "../Containers/CourseDetailHeroSection/CourseDetailHeroSection";
import CourseDetailKickStart from "../Containers/CourseDetailKickStart/CourseDetailKickStart";
import CourseDetailWhatYoullLearn from "../Containers/CourseDetailWhatYoullLearn/CourseDetailWhatYoullLearn";
import CourseDetailWhatYouNeed from "../Containers/CourseDetailWhatYouNeed/CourseDetailWhatYouNeed";
import CourseDetailWhenWeStart from "../Containers/CourseDetailWhenWeStart/CourseDetailWhenWeStart";
import { CourseContext } from "../Context/CourseContext";

const CourseDetail = () => {
  // COntext
  const {
    iseCourseDetail,
    getCoursesById,
    getCourseModules,
    getCourseModulesObject,
  } = useContext(CourseContext);

  // Router
  const { courseId } = useParams();

  // Effects
  useEffect(() => {
    getCoursesById(courseId as string);
    getCourseModules(courseId as string, null, true);

    // eslint-disable-next-line
  }, []);

  if (iseCourseDetail?.isLoading || getCourseModulesObject?.isLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  return (
    <Layout>
      <CourseDetailBreadcrumbs />
      <CourseDetailHeroSection />
      <CourseDetailGetToKnow />
      {getCourseModulesObject?.data && <CourseDetailWhatYoullLearn />}
      <CourseDetailWhenWeStart />
      <CourseDetailWhatYouNeed />
      <CourseDetailKickStart />
    </Layout>
  );
};

export default CourseDetail;
