import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../Components/Button/Button";
import Layout from "../../Components/Layout/Layout";
import { CourseContext } from "../../Context/CourseContext";
import { PaymentContext } from "../../Context/PaymentContext";
import { capitalize } from "../../HelperFunctions/capitalize";
import PaymentMethodContainer from "../PaymentMethodContainer/PaymentMethodContainer";
import classes from "./PaymentMain.module.css";

export type activePlanType =
  | {
      title: string;
      price: string;
      advantages: string[];
      image: string;
      discount?: number;
    }
  | undefined;

const PaymentMain = () => {
  // Router
  const { paymentPlan, courseId } = useParams();
  const navigate = useNavigate();

  // Context
  const { paymentPlans } = useContext(PaymentContext);
  const { getCoursesById } = useContext(CourseContext);

  //   Utils
  const activePlan: activePlanType = paymentPlans.find((data) => {
    return (
      data.title === capitalize((paymentPlan as string)?.replace("-", " "))
    );
  });

  useEffect(() => {
    getCoursesById(courseId as string);

    // eslint-disable-next-line
  }, []);

  return (
    <Layout closeSideNav>
      <section className={classes.container}>
        <div className={classes.header}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            onClick={() => {
              navigate(`/payment/${courseId}`);
            }}
          >
            <path
              d="M10 19L3 12M3 12L10 5M3 12L21 12"
              stroke="#2E2E2E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div className={classes.bodyMain}>
          <div className={classes.switchPayment}>
            <div>
              <div>
                <img src={activePlan?.image} alt={activePlan?.title} />
              </div>
              <h4>{activePlan?.title}</h4>
            </div>
            <div className={classes.leftButtonSection}>
              <Button
                type="tertiary"
                onClick={() => {
                  if (paymentPlan === "full-payment") {
                    navigate(`/payment/${courseId}/instalment-payment`);
                  } else {
                    navigate(`/payment/${courseId}/full-payment`);
                  }
                }}
              >
                Switch payment plan
              </Button>
            </div>
          </div>
          <div className={classes.paymentSection}>
            <PaymentMethodContainer activePlan={activePlan} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PaymentMain;
