import OnboardingLayout from "../../Components/OnboardingLayout/OnboardingLayout";
import signUpImage from "../../Assets/Images/signUp.svg";
import classes from "../LoginPage/LoginPage.module.css";
import Input from "../../Components/Input/Input";
import { Link } from "react-router-dom";
import Button from "../../Components/Button/Button";
import iseLogo from "../../Assets/Images/iseLogo.svg";
import { useContext } from "react";
import { AuthUserContext } from "../../Context/AuthUserContext";
import Error from "../../Components/Error/Error";
import { capitalize } from "../../HelperFunctions/capitalize";

const SignUpPage = () => {
  // COntext
  const { userLoginInfo, setUserLoginInfo, signUp, signupRequest } =
    useContext(AuthUserContext);

  // Utils
  const changeHandler = (e: any) => {
    setUserLoginInfo((prevState) => {
      return { ...prevState, [e?.target?.name]: e.target?.value };
    });
  };

  return (
    <>
      <OnboardingLayout image={signUpImage}>
        <section className={classes.container}>
          <img src={iseLogo} alt="Ise Logo" className={classes.logo} />
          <div className={classes.header}>
            <h4>Create account</h4>
            <p>Create an account with Ise to kickstart your tech career</p>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {signupRequest?.error && (
              <Error type="error">{capitalize(signupRequest.error)}</Error>
            )}
            <Input
              label="Email Address"
              isRequired
              placeholder="name@gmail.com"
              value={userLoginInfo.email as string}
              onChange={changeHandler}
              name="email"
            />
            <span>
              <Input
                label="Password"
                isRequired
                type="password"
                placeholder="************"
                tip="Passwords must be at least 8 characters, one uppercase letter, one lower case letter and one number"
                name="password"
                onChange={changeHandler}
                value={userLoginInfo.password as string}
              />
            </span>

            <div className={classes.rememberAndForgot}>
              <div>
                <input
                  type="checkbox"
                  id="remember"
                  checked={userLoginInfo.isSubscribed}
                  name="isSubscribed"
                  onChange={() => {
                    setUserLoginInfo((prevState) => {
                      return {
                        ...prevState,
                        isSubscribed: !prevState.isSubscribed,
                      };
                    });
                  }}
                />
                <span>I want to receive updates from ise</span>
              </div>
            </div>

            <div className={classes.buttonSection}>
              <Button
                onClick={() => {
                  signUp();
                }}
                loading={signupRequest.isLoading}
              >
                Sign up
              </Button>
            </div>
          </form>

          {/* <div>
            <div className={classes.googleSignUp}>
              <hr />
              <span>
                or <span className={classes.continueWith}>continue with </span>
              </span>
              <hr />
            </div>
            <div className={classes.googleSignUpIcons}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g clipPath="url(#clip0_7901_46682)">
                    <path
                      d="M8.36299 0.789921C5.96502 1.6218 3.89701 3.20073 2.46273 5.2948C1.02844 7.38887 0.303479 9.88769 0.394324 12.4242C0.48517 14.9608 1.38704 17.4013 2.96745 19.3874C4.54787 21.3735 6.72353 22.8005 9.17487 23.4587C11.1622 23.9715 13.2444 23.994 15.2424 23.5243C17.0523 23.1177 18.7257 22.2481 20.0986 21.0005C21.5275 19.6624 22.5647 17.9602 23.0986 16.0768C23.6788 14.0286 23.782 11.8748 23.4005 9.78055H12.2405V14.4099H18.7036C18.5744 15.1483 18.2976 15.853 17.8898 16.4819C17.4819 17.1107 16.9514 17.6509 16.3299 18.0699C15.5408 18.5922 14.651 18.9435 13.718 19.1012C12.7823 19.2752 11.8225 19.2752 10.8867 19.1012C9.93828 18.9053 9.04107 18.5138 8.25237 17.9518C6.98515 17.0548 6.03364 15.7804 5.53362 14.3105C5.02529 12.8131 5.02529 11.1898 5.53362 9.69242C5.88954 8.64283 6.47793 7.68717 7.25487 6.8968C8.14398 5.9757 9.26962 5.31729 10.5083 4.99381C11.747 4.67034 13.0508 4.69429 14.2767 5.06305C15.2345 5.3569 16.1103 5.87057 16.8342 6.56305C17.563 5.83805 18.2905 5.11117 19.0167 4.38242C19.3917 3.99055 19.8005 3.61742 20.1699 3.21617C19.0646 2.18777 17.7674 1.38749 16.3524 0.861171C13.7756 -0.0744733 10.9561 -0.0996178 8.36299 0.789921Z"
                      fill="white"
                    />
                    <path
                      d="M8.35875 0.789367C10.9516 -0.100776 13.7711 -0.0762934 16.3481 0.858742C17.7634 1.38864 19.06 2.19277 20.1637 3.22499C19.7887 3.62624 19.3931 4.00124 19.0106 4.39124C18.2831 5.11749 17.5562 5.84124 16.83 6.56249C16.106 5.87001 15.2302 5.35635 14.2725 5.06249C13.047 4.69244 11.7432 4.66711 10.5042 4.98926C9.26516 5.31141 8.13883 5.96861 7.24875 6.88874C6.47181 7.67912 5.88342 8.63477 5.5275 9.68437L1.64062 6.67499C3.03189 3.91604 5.44078 1.80566 8.35875 0.789367Z"
                      fill="#E33629"
                    />
                    <path
                      d="M0.613354 9.65654C0.822116 8.62111 1.16896 7.61841 1.6446 6.67529L5.53148 9.69217C5.02315 11.1896 5.02315 12.8129 5.53148 14.3103C4.23648 15.3103 2.94085 16.3153 1.6446 17.3253C0.454262 14.9559 0.0912278 12.2562 0.613354 9.65654Z"
                      fill="#F8BD00"
                    />
                    <path
                      d="M12.2352 9.77783H23.3952C23.7768 11.8721 23.6735 14.0259 23.0933 16.0741C22.5594 17.9575 21.5222 19.6597 20.0933 20.9978C18.839 20.0191 17.579 19.0478 16.3246 18.0691C16.9465 17.6496 17.4773 17.1089 17.8852 16.4793C18.2931 15.8498 18.5696 15.1444 18.6983 14.4053H12.2352C12.2333 12.8641 12.2352 11.321 12.2352 9.77783Z"
                      fill="#587DBD"
                    />
                    <path
                      d="M1.64062 17.3251C2.93687 16.3251 4.2325 15.3201 5.5275 14.3101C6.02851 15.7804 6.98138 17.0549 8.25 17.9513C9.04116 18.5107 9.9403 18.899 10.89 19.0913C11.8257 19.2653 12.7855 19.2653 13.7213 19.0913C14.6543 18.9336 15.544 18.5823 16.3331 18.0601C17.5875 19.0388 18.8475 20.0101 20.1019 20.9888C18.7292 22.237 17.0558 23.1073 15.2456 23.5144C13.2476 23.9841 11.1655 23.9616 9.17813 23.4488C7.60632 23.0291 6.13814 22.2893 4.86563 21.2757C3.51886 20.2062 2.41882 18.8587 1.64062 17.3251Z"
                      fill="#319F43"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7901_46682">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M22.2295 9.08847e-05H1.77049C1.30629 -0.0046333 0.859174 0.174984 0.52725 0.499529C0.195326 0.824074 0.00570713 1.26704 0 1.73123V22.2727C0.00673925 22.7362 0.196812 23.1782 0.528624 23.5019C0.860435 23.8256 1.30696 24.0046 1.77049 23.9999H22.2295C22.6937 24.0036 23.1406 23.8234 23.4723 23.4986C23.8041 23.1739 23.9938 22.731 24 22.2668V1.72532C23.9917 1.2625 23.8011 0.821646 23.4696 0.49859C23.138 0.175535 22.6924 -0.00361171 22.2295 9.08847e-05Z"
                    fill="#0076B2"
                  />
                  <path
                    d="M3.28771 8.79405H6.90599V20.4361H3.28771V8.79405ZM5.09785 3C5.51285 3 5.91853 3.12309 6.26356 3.35369C6.60859 3.58429 6.87748 3.91205 7.0362 4.2955C7.19492 4.67895 7.23635 5.10085 7.15524 5.50785C7.07413 5.91485 6.87413 6.28865 6.58054 6.58196C6.28695 6.87527 5.91297 7.07491 5.50589 7.15563C5.09882 7.23635 4.67695 7.19452 4.29365 7.03543C3.91036 6.87634 3.58285 6.60715 3.35258 6.26189C3.12231 5.91664 2.99961 5.51085 3 5.09585C3.00053 4.53981 3.22179 4.00673 3.61515 3.61374C4.00852 3.22075 4.54181 3 5.09785 3ZM9.17567 8.79405H12.6441V10.3924H12.6921C13.1756 9.47735 14.3544 8.51234 16.1146 8.51234C19.7788 8.50435 20.4581 10.9159 20.4581 14.0427V20.4361H16.8398V14.7719C16.8398 13.4233 16.8158 11.6871 14.9597 11.6871C13.1036 11.6871 12.788 13.1576 12.788 14.684V20.4361H9.17567V8.79405Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          </div> */}

          <div className={classes.alternate}>
            Already have an account? <Link to="/sign-in">Login</Link>
          </div>
        </section>
      </OnboardingLayout>
    </>
  );
};

export default SignUpPage;
