import { useState } from "react";
import SectionsNav4 from "../../Components/SectionsNav4/SectionsNav4";
import ProfileAccountSettings from "../ProfileAccountSettings/ProfileAccountSettings";
import ProfileBadges from "../ProfileBadges/ProfileBadges";
// import ProfilePaymentAndBilling from "../ProfilePaymentAndBilling/ProfilePaymentAndBilling";
import ProfileProfile from "../ProfileProfile/ProfileProfile";
import classes from "./Profile.module.css";

const Profile = () => {
  // States
  const [navItems, setNavItems] = useState<any[]>([
    {
      title: "Profile",
      isActive: true,
      activeComponent: <ProfileProfile />,
      route: "user",
    },
    {
      title: "Account setting",
      isActive: false,
      activeComponent: <ProfileAccountSettings />,
      route: "account-setting",
    },
    // {
    //   title: "Payment and billing",
    //   isActive: false,
    //   activeComponent: <ProfilePaymentAndBilling />,
    //   route: "payment-and-billing",
    // },
    {
      title: "Badges",
      isActive: false,
      activeComponent: <ProfileBadges />,
      route: "badges",
    },
  ]);

  //   Utils
  const activeComponent = navItems.find((data) => data.isActive);
  return (
    <section className={classes.container}>
      <div className={classes.sectionsNavSection}>
        <SectionsNav4 navItems={navItems} setNavItems={setNavItems} isRouting />
      </div>
      <div
        className={
          activeComponent?.title === "Profile" ||
          activeComponent?.title === "Account setting"
            ? classes.body
            : undefined
        }
      >
        {activeComponent.activeComponent}
      </div>
    </section>
  );
};

export default Profile;
