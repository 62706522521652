import { useContext } from "react";
import Button from "../../Components/Button/Button";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./ScheduleRightCta.module.css";

const ScheduleRightCta = () => {
  // Context
  const { userEnrolledCOursesCount } = useContext(CourseContext);

  return (
    <div className={classes.container}>
      <h4>Schedule call with Tutor</h4>
      <p>
        Get personalized guidance and answers to your questions. Schedule a call
        with a dedicated tutor for support as you learn
      </p>
      <Button
        type="tertiary"
        disabled={
          userEnrolledCOursesCount?.data?.paidEnrolledCourseCount <= 0 || false
        }
      >
        Schedule a free call
      </Button>
    </div>
  );
};

export default ScheduleRightCta;
