import DropdownWithSearch from "../../Components/DropdownWithSearch/DropdownWithSearch";
import Input from "../../Components/Input/Input";
import classes from "../UserGetToKnowYou/UserGetToKnowYou.module.css";
import subClasses from "./UserBackgroundAndSkills.module.css";
import Button from "../../Components/Button/Button";
import { useContext, useEffect } from "react";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { inputChangeHandler } from "../../HelperFunctions/inputChangeHandler";

const UserBackgroundAndSkills = () => {
  // Context
  const {
    setSearchParams,
    employmentStatus,
    setEmploymentStatus,
    educationLevel,
    setEducationLevel,
    completeProfileData,
    setCompleteProfileData,
    completeProfile,
    completeProfileRequest,
  } = useContext(AuthUserContext);

  // States

  // Effects
  useEffect(() => {
    setSearchParams({
      step: "2",
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (employmentStatus) {
      setCompleteProfileData((prevState) => {
        return { ...prevState, employement_status: employmentStatus };
      });
    }

    if (educationLevel) {
      setCompleteProfileData((prevState) => {
        return { ...prevState, education: educationLevel };
      });
    }

    // eslint-disable-next-line
  }, [employmentStatus, educationLevel]);
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <h4>Background and skills</h4>
        <p>
          We'd use this information to create a unique experience for you on iṣẹ́
        </p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div>
          <DropdownWithSearch
            title="Select your current status"
            options={[
              "Employed (Full-Time)",
              "Employed (Part-Time)",
              "Self-Employed",
              "Unemployed",
              "Student",
            ]}
            label="Employment status"
            isRequired
            errorMessage="Select an option to continue "
            selected={employmentStatus}
            setSelected={setEmploymentStatus}
          />
        </div>

        <div>
          <DropdownWithSearch
            title="Select Education level"
            options={[
              "High School Diploma or Equivalent",
              "Associate's Degree",
              "Bachelor's Degree",
              "Master's Degree",
              "Doctoral Degree (Ph.D.)",
              "Vocational or Technical Certification",
              "College, No Degree",
              "High School Student",
              "Other",
            ]}
            label="Education Level"
            isRequired
            errorMessage="Select an option to continue "
            selected={educationLevel}
            setSelected={setEducationLevel}
          />
        </div>

        <div>
          <Input
            placeholder="Enter your LinkedIn URL"
            label="LinkedIn profile"
            value={completeProfileData.linkedIn_profile}
            onChange={(e) => {
              inputChangeHandler(e, setCompleteProfileData);
            }}
            name="linkedIn_profile"
          />
        </div>

        <div className={`${classes.buttonSection} ${subClasses.buttonSection}`}>
          <Button
            type="secondary"
            onClick={() => {
              setSearchParams({
                step: "1",
              });
            }}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M7.5 16.5L3.5 12.5M3.5 12.5L7.5 8.5M3.5 12.5L21.5 12.5"
                  stroke="#664EFE"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Previous</span>
            </span>
          </Button>
          <Button
            onClick={() => {
              // setSearchParams({
              //   step: "3",
              // });
              completeProfile();
            }}
            loading={completeProfileRequest.isLoading}
            disabled={!employmentStatus || !educationLevel}
          >
            <span>
              <span>Next</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M17.5 8.5L21.5 12.5M21.5 12.5L17.5 16.5M21.5 12.5L3.5 12.5"
                  stroke="#A79AFE"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </Button>
        </div>
      </form>
    </section>
  );
};

export default UserBackgroundAndSkills;
