import { useState } from "react";
import classes from "./FileUploadInput.module.css";

type FileUploadInputProps = {
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  value?: string;
  isRequired?: boolean;
  errorMessage?: string;
  inValidCondition?: boolean;
  tip?: string;
  accept?: string;
  placeholder?: string;
};

const FileUploadInput = ({
  label,
  onChange,
  onBlur,
  value,
  isRequired,
  errorMessage,
  inValidCondition,
  tip,
  accept,
  placeholder,
}: FileUploadInputProps) => {
  // States
  const [invalid, setInvalid] = useState(false);
  const [file, setFile] = useState<null | FileList>(null);

  const changeHandler = (e: any) => {
    const files = e.target.files;

    setFile(files);

    [...files].map((file: any) => {
      if (file.size > 10000000) {
        setInvalid(true);
      } else {
        setInvalid(false);
      }
      return file;
    });

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={classes.container}>
      <p className={classes.label}>
        {label} {isRequired && <span>*</span>}
      </p>
      <label
        htmlFor={label}
        className={`${invalid ? classes.invalid : classes.valid}`}
      >
        <span
          style={
            !(file as FileList) && placeholder
              ? { color: "#737373" }
              : undefined
          }
        >
          {!(file as FileList) && placeholder
            ? placeholder
            : (file as FileList)?.length > 0
            ? (file as FileList)[0]?.name
            : !file && !placeholder
            ? "Select file"
            : "File uploaded"}
        </span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4 16L4 17C4 18.6569 5.34315 20 7 20L17 20C18.6569 20 20 18.6569 20 17L20 16M16 8L12 4M12 4L8 8M12 4L12 16"
            stroke="#737373"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </label>
      <input
        type="file"
        name={label}
        id={label}
        onChange={changeHandler}
        onBlur={(e) => {
          if (isRequired && e.target.value === "") {
            setInvalid(true);
          } else {
            setInvalid(false);
          }
          if (onBlur) onBlur();
        }}
        accept={accept}
        multiple={false}
      />

      {(invalid || inValidCondition) && (
        <span className={classes.errorMessage}>
          {errorMessage || "*invalid"}
        </span>
      )}
      {tip && <span className={classes.tip}>{tip}</span>}
    </div>
  );
};

export default FileUploadInput;
