import { AxiosResponse } from "axios";
import React, {
  createContext,
  SetStateAction,
  useEffect,
  useState,
  Dispatch,
  useContext,
} from "react";
import {
  SetURLSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import requestHandler from "../HelperFunctions/requestHandler";
import { courses, coursesType } from "../Utilities/courses";
import { setNotiticationFunction } from "../Utilities/setNotificationsFunction";
import { AppContext } from "./AppContext";
import { AuthUserContext, requestType } from "./AuthUserContext";
import { PaymentContext } from "./PaymentContext";
import { backend_url } from "../Utilities/global";

type CourseContextProviderTypes = {
  children: React.ReactNode;
};

type CourseContextType = {
  handleFilterChange: (id?: number, filterName?: string) => void;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
  coursesState: coursesType;
  clearFilters: () => void;
  searchIsActive: boolean;
  setSearchisActive: Dispatch<SetStateAction<boolean>>;
  displayReportModal: boolean;
  setDisplayReportModal: Dispatch<SetStateAction<boolean>>;
  getCourses: (load: boolean) => void;
  iseCourses: requestType;
  setIseCourses: Dispatch<SetStateAction<requestType>>;
  iseCourseDetail: requestType;
  setIseCourseDetail: Dispatch<SetStateAction<requestType>>;
  getCoursesById: (id: string) => void;
  getUserEnrolledCoursesCount: () => void;
  userEnrolledCOursesCount: requestType;
  bookmarkCourse: (id: string) => void;
  bookMarkCourseObject: requestType;
  getCourseLayout: (id: string) => void;
  courseLayoutObject: requestType;
  savedCourse: (id: string) => void;
  savedCourseObject: requestType;
  unSaveCourse: (id: string) => void;
  getEnrolledCourses: (load?: boolean) => void;
  getEnrolledCoursesObject: requestType;
  getSavedCourses: (load: boolean) => void;
  getSavedCoursesObject: requestType;
  getCourseModules: (
    id: string,
    cohortId?: string | null,
    load?: boolean,
    useWall?: boolean
  ) => void;
  getCourseModulesObject: requestType;
  getCourseGradesObject: requestType;
  getCourseGrades: () => void;
  getCourseResources: (id: string) => void;
  getCourseResourcesObject: requestType;
  getSchoolFilter: () => void;
  schools: requestType;
  setSchools: Dispatch<SetStateAction<requestType>>;
  modules: any | undefined;
  setModules: Dispatch<SetStateAction<any | undefined>>;
  activeWeek: any[] | undefined;
  setActiveWeek: Dispatch<SetStateAction<any>>;
  getCourseQuizQuestions: requestType;
  setGetCourseQuizQuestions: Dispatch<SetStateAction<requestType>>;
  getCourseQuiz: (id: string, questionNumber: string) => void;
  getBookmarkObjects: requestType;
  setGetBookmarksObject: Dispatch<SetStateAction<requestType>>;
  getBookmarks: (load?: boolean) => void;
  deleteBookmarks: (id: string) => void;
  setBookmark: requestType;
  setSetBookmark: Dispatch<SetStateAction<requestType>>;
  setBookmarksHandler: (
    id: string,
    type: "video" | "reading",
    courseId: string,
    moduleId: string,
    weekId: string
  ) => void;
  setEntityProgressObject: requestType;
  setSetEntityProgressObject: Dispatch<SetStateAction<requestType>>;
  videoProgressObject: {
    watching_hours: number;
    is_completed: boolean;
    resume_time: number;
  };
  setVideoProgressObject: Dispatch<
    SetStateAction<{
      watching_hours: number;
      is_completed: boolean;
      resume_time: number;
    }>
  >;

  readingProgressObject: {
    readingId: string;
    progress: number;
    completed: boolean;
  };
  setReadingProgressObject: Dispatch<
    SetStateAction<{
      readingId: string;
      progress: number;
      completed: boolean;
    }>
  >;
  setEntityProgress: (
    id: string,
    courseId: string,
    moduleId: string,
    weekId: string,
    type: "video" | "reading",
    load?: boolean
  ) => void;
  answerCourseQuiz: (id: string, answer: { answer: string }) => void;
  answerCourseQuizObject: requestType;
  getRecommendedCourses: () => void;
  getRecommendedCoursesObject: requestType;
  rateCourse: (id: string, load?: boolean) => void;
  rateCourseObject: requestType;
  rating: { rating: number; feed_comment: string };
  setRating: Dispatch<
    SetStateAction<{
      rating: number;
      feed_comment: string;
    }>
  >;
};

export const CourseContext = createContext({} as CourseContextType);

const CourseContextProvider = ({ children }: CourseContextProviderTypes) => {
  // Context
  const { setPaymentPlans, paymentPlans, setParticularCourse } =
    useContext(PaymentContext);
  const { setNotifications } = useContext(AppContext);
  const { logout } = useContext(AuthUserContext);

  // States
  const [coursesState] = useState<coursesType>(courses);
  const [iseCourses, setIseCourses] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [iseCourseDetail, setIseCourseDetail] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [searchIsActive, setSearchisActive] = useState(false);
  const [displayReportModal, setDisplayReportModal] = useState(false);
  const [userEnrolledCOursesCount, setUSerEnrolledCoursesCount] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });
  const [bookMarkCourseObject, setBookmarkedCoursesObject] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });
  const [savedCourseObject, setSavedCoursesObject] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [courseLayoutObject, setCourseLayoutObject] = useState<requestType>({
    data: null,
    isLoading: false,
    error: null,
  });

  //   Router
  const [searchParams, setSearchParams] = useSearchParams({
    filters: [],
  });

  const navigate = useNavigate();
  const location = useLocation();

  const handleFilterChange = (id?: number, filterName?: string) => {
    setSearchParams((prevState) => {
      const existingFilters = prevState.get(filterName as string);
      const filtersArray = existingFilters ? existingFilters.split(",") : [];

      let updatedFilters: string[] = [];

      if (id !== undefined && id !== null) {
        if (!filtersArray.includes((id as number)?.toString())) {
          updatedFilters = [...filtersArray, (id as number)?.toString()];
        } else {
          updatedFilters = filtersArray.filter(
            (filter) => filter !== (id as number)?.toString()
          );
        }
      }

      const updatedParams = new URLSearchParams(prevState.toString());

      if (updatedFilters.length > 0) {
        updatedParams.set(filterName as string, updatedFilters.join(","));
      } else {
        updatedParams.delete(filterName as string);
      }

      courseSearchAndFilter(updatedFilters);

      return updatedParams;
    });
  };

  const clearFilters = () => {
    setSearchParams((prevState) => {
      const updatedParams = new URLSearchParams(prevState.toString());
      updatedParams.delete("school");

      return updatedParams;
    });

    getCourses(true);
  };

  // Requests
  const getCourses = (load: boolean = false) => {
    if (iseCourses.isLoading) {
      return;
    }
    if (load) {
      setIseCourses({ isLoading: true, data: null, error: null });
    } else {
      setIseCourses((prevState) => {
        return { ...prevState, isLoading: false };
      });
    }

    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/courses/get-courses`,
    })
      .then((res) => {
        setIseCourses({
          isLoading: false,
          data: (res as any).data,
          error: null,
        });
      })
      .catch((err) => {
        setIseCourses({
          isLoading: true,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const getCoursesById = (id: string) => {
    if (id) {
      setIseCourseDetail({ isLoading: true, data: null, error: null });

      requestHandler({
        method: "GET",
        url: `${backend_url}/api/ise/v1/courses/${id}`,
      })
        .then((res) => {
          setIseCourseDetail({
            isLoading: false,
            data: (res as any).data,
            error: null,
          });
          setParticularCourse({
            isLoading: false,
            data: (res as any).data,
            error: null,
          });

          let newPaymentPlans = [];
          // if ((res as any)?.data?.has_installment) {
          newPaymentPlans = paymentPlans?.map((data) => {
            if (data.title === "Full payment") {
              return {
                ...data,
                price: (res as any).data?.cohort?.price,
                discount: (res as any).data?.cohort?.discount_percentage,
              };
            } else {
              return {
                ...data,
                price: (
                  Math.round(Number((res as any)?.data?.cohort?.price)) * 0.7
                ).toString(),
              };
            }
          });
          // } else {
          //   newPaymentPlans = paymentPlans.slice(0, 1).map((data) => {
          //     return {
          //       ...data,
          //       price: (res as any)?.data?.cohort?.price,
          //     };
          //   });
          // }
          setPaymentPlans(newPaymentPlans);
        })
        .catch((err) => {
          setIseCourseDetail({
            isLoading: false,
            data: null,
            error: err.response?.data?.error
              ? err.response?.data?.error?.responseMessage
              : !err.response?.data?.error
              ? err.response?.data?.responseMessage.toString()
              : err.message,
          });
          setParticularCourse({
            isLoading: false,
            data: null,
            error: err.response?.data?.error
              ? err.response?.data?.error?.responseMessage
              : !err.response?.data?.error
              ? err.response?.data?.responseMessage.toString()
              : err.message,
          });

          if (err?.response?.data?.error?.responseMessage === "Expired Token") {
            navigate("/sign-in", { state: location.pathname });
          }
        });
    }
  };

  const getUserEnrolledCoursesCount = () => {
    if (userEnrolledCOursesCount.isLoading) {
      return;
    }

    setUSerEnrolledCoursesCount((prevState: any) => {
      if (prevState) {
        return { ...prevState, isLoading: true };
      } else {
        return {
          isLoading: true,
          data: null,
          error: null,
        };
      }
    });
    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/enrollments/get-enrolled-course-count`,
    })
      .then((res) => {
        setUSerEnrolledCoursesCount({
          isLoading: false,
          data: (res as any).data,
          error: null,
        });
      })
      .catch((err) => {
        setUSerEnrolledCoursesCount({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err?.request
            ? "There was an issue making this request"
            : err.message
        );

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const bookmarkCourse = (id: string) => {
    setBookmarkedCoursesObject({
      isLoading: true,
      data: null,
      error: null,
    });
    requestHandler({
      method: "POST",
      url: `${backend_url}/api/ise/v1/bookmarks/courses/${id}`,
    })
      .then((res) => {
        setNotiticationFunction(setNotifications, (res as any).data, "success");
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const savedCourse = (id: string) => {
    setSavedCoursesObject({
      isLoading: true,
      data: null,
      error: null,
    });
    requestHandler({
      method: "POST",
      url: `${backend_url}/api/ise/v1/saved-course/courses/${id}`,
    })
      .then((res) => {
        setNotiticationFunction(
          setNotifications,
          "Course has been saved",
          "success"
        );

        setSavedCoursesObject({
          isLoading: false,
          data: null,
          error: null,
        });

        getCourses(false);
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setSavedCoursesObject({
          isLoading: false,
          data: null,
          error: null,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const unSaveCourse = (id: string) => {
    setSavedCoursesObject({
      isLoading: true,
      data: null,
      error: null,
    });
    requestHandler({
      method: "DELETE",
      url: `${backend_url}/api/ise/v1/saved-course/courses/${id}`,
    })
      .then((res) => {
        setNotiticationFunction(
          setNotifications,
          (res as AxiosResponse).data,
          "success"
        );

        setSavedCoursesObject({
          isLoading: false,
          data: null,
          error: null,
        });

        getCourses(false);
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setSavedCoursesObject({
          isLoading: false,
          data: null,
          error: null,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const getCourseLayout = (id: string) => {
    if (courseLayoutObject.isLoading) {
      return;
    }
    setCourseLayoutObject({ isLoading: true, error: null, data: null });
    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/courses/course-layout/${id}`,
    })
      .then((res) => {
        setCourseLayoutObject({
          isLoading: false,
          error: null,
          data: (res as AxiosResponse).data,
        });
      })
      .catch((err) => {
        setCourseLayoutObject({
          isLoading: false,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
          data: null,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          navigate("/sign-in", { state: location.pathname });
        }
      });
  };

  const [getEnrolledCoursesObject, setGetEnrolledCoursesObject] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });

  const [getSavedCoursesObject, setGetSavedCoursesObject] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });

  const getEnrolledCourses = (load: boolean = true) => {
    if (getEnrolledCoursesObject.isLoading) {
      return;
    }

    if (load === true) {
      setGetEnrolledCoursesObject((prevState) => {
        return { ...prevState, isLoading: load, error: null, data: null };
      });
    } else if (load === false) {
      setGetEnrolledCoursesObject((prevState) => {
        return { ...prevState, isLoading: load };
      });
    }

    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/enrollments`,
    })
      .then((res) => {
        setGetEnrolledCoursesObject({
          isLoading: false,
          error: null,
          data: (res as AxiosResponse).data,
        });
      })
      .catch((err) => {
        setGetEnrolledCoursesObject({
          isLoading: false,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
          data: null,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const getSavedCourses = (load: boolean = true) => {
    if (getSavedCoursesObject.isLoading) {
      return;
    }
    if (load === true) {
      setGetSavedCoursesObject((prevState) => {
        return { ...prevState, isLoading: load, error: null, data: null };
      });
    } else if (load === false) {
      setGetSavedCoursesObject((prevState) => {
        return { ...prevState, isLoading: load };
      });
    }

    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/saved-course/courses`,
    })
      .then((res) => {
        setGetSavedCoursesObject({
          isLoading: false,
          error: null,
          data: (res as AxiosResponse).data,
        });
      })
      .catch((err) => {
        setGetSavedCoursesObject({
          isLoading: false,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
          data: null,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const [getCourseModulesObject, setGetCourseModulesObject] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });

  const [getCourseGradesObject, setGetCourseGradesObject] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });

  const [getCourseResourcesObject, setGetCourseResourcesObject] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });

  const getCourseModules = (
    id: string,
    cohortId?: string | null,
    load?: boolean,
    useWall?: boolean
  ) => {
    if (load === true) {
      setGetCourseModulesObject({
        isLoading: true,
        data: null,
        error: null,
      });
    } else {
      setGetCourseModulesObject((prevState) => {
        return { ...prevState, isLoading: false, error: null };
      });
    }

    requestHandler({
      method: "GET",
      url: useWall
        ? `${backend_url}/api/ise/v1/course-modules/course/${id}/${
            cohortId as string
          }`
        : `${backend_url}/api/ise/v1/course-modules/landing-page-record/${id}`,
    })
      .then((res) => {
        setGetCourseModulesObject({
          isLoading: false,
          data: res.data,
          error: null,
        });

        getCourses(false);
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage?.toString()
            : err.message
        );

        setGetCourseModulesObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage?.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const getCourseGrades = () => {
    if (getCourseGradesObject.isLoading) {
      return;
    }
    setGetCourseGradesObject({
      isLoading: true,
      data: null,
      error: null,
    });
    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/student-assignment/grade`,
    })
      .then((res) => {
        setGetCourseGradesObject({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setGetCourseGradesObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const getCourseResources = (id: string) => {
    if (getCourseResourcesObject.isLoading) {
      return;
    }
    setGetCourseResourcesObject({
      isLoading: true,
      data: null,
      error: null,
    });
    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/course-modules/resource/${id}}`,
    })
      .then((res) => {
        setGetCourseResourcesObject({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setGetCourseResourcesObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const [schools, setSchools] = useState<requestType>({
    data: null,
    isLoading: false,
    error: null,
  });

  const getSchoolFilter = () => {
    if (schools.isLoading) {
      return;
    }

    setSchools({
      isLoading: true,
      data: null,
      error: null,
    });
    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/school/filter/list`,
    })
      .then((res) => {
        setSchools({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setSchools({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const currentSearchParams = new URLSearchParams(window.location.search);
  const searchedParams = currentSearchParams.get("search");
  const quizQuestion = currentSearchParams.get("quizQuestion");

  const courseSearchAndFilter = (schoolParam?: any[]) => {
    schoolParam =
      (schoolParam as any[]) &&
      (schoolParam as any[])?.length === 1 &&
      (schoolParam as any[])[0] === "0"
        ? []
        : schoolParam;

    setIseCourses({
      isLoading: true,
      data: null,
      error: null,
    });
    let url: string = `${backend_url}/api/ise/v1/courses/search-and-filter`;
    let newUrl = "";

    if (
      (schoolParam === undefined || schoolParam?.length < 1) &&
      (searchedParams !== null || searchedParams !== undefined)
    ) {
      newUrl = url.concat(`?search=${searchedParams || ""}`);
    }
    if (
      schoolParam &&
      schoolParam.length > 0 &&
      (searchedParams === null ||
        searchedParams === "" ||
        searchParams === undefined)
    ) {
      newUrl = url.concat(
        `?school_id=[${schoolParam as any[]}]&search=${searchedParams || ""}`
      );
    }
    if (
      schoolParam !== undefined &&
      schoolParam.length > 1 &&
      (searchedParams !== null ||
        searchedParams !== "" ||
        searchedParams !== undefined)
    ) {
      newUrl = url.concat(
        `?school_id=[${schoolParam as any[]}]&search=${searchedParams || ""}`
      );
    }

    requestHandler({
      method: "GET",
      url: newUrl,
    })
      .then((res) => {
        setIseCourses({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage?.toString()
            : err.message
        );

        setIseCourses({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage?.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const [getCourseQuizQuestions, setGetCourseQuizQuestions] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });

  const [answerCourseQuizObject, setAnswerCourseQuizObject] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });

  const getCourseQuiz = (id: string, questionNumber: string) => {
    if (getCourseQuizQuestions.isLoading) {
      return;
    }

    setGetCourseQuizQuestions({
      isLoading: true,
      data: null,
      error: null,
    });
    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/quiz-questions/${id}?page_size=1&page=${questionNumber}`,
    })
      .then((res) => {
        const formattedData = {
          ...res.data,
          data: res?.data.data.map((question: any) => ({
            ...question,
            options: JSON.parse(question?.options).map((option: any) => ({
              ...option,
              isActive: false,
            })),
          })),
        };

        setGetCourseQuizQuestions({
          isLoading: false,
          data: formattedData,
          error: null,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setGetCourseQuizQuestions({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const answerCourseQuiz = (id: string, answer: { answer: string }) => {
    if (answerCourseQuizObject.isLoading) {
      return;
    }

    setAnswerCourseQuizObject({
      isLoading: true,
      data: null,
      error: null,
    });
    requestHandler({
      method: "POST",
      url: `${backend_url}/api/ise/v1/quiz-answers/${id}`,
      data: answer,
    })
      .then((res) => {
        setAnswerCourseQuizObject({
          isLoading: false,
          data: res.data,
          error: null,
        });

        currentSearchParams.set(
          "quizQuestion",
          String(Number(quizQuestion) + 1)
        );
        setSearchParams(currentSearchParams.toString());
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setAnswerCourseQuizObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const [setBookmark, setSetBookmark] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  const setBookmarksHandler = (
    contentId: string,
    type: "video" | "reading",
    courseId: string,
    moduleId: string,
    weekId: string
  ) => {
    if (setBookmark.isLoading) {
      return;
    }

    setSetBookmark({
      isLoading: true,
      data: null,
      error: null,
    });

    requestHandler({
      method: "POST",
      url:
        type === "video"
          ? `${backend_url}/api/ise/v1/bookmarks/courses/video/${contentId}/${courseId}/${moduleId}/${weekId}`
          : `${backend_url}/api/ise/v1/bookmarks/courses/reading/${contentId}/${courseId}/${moduleId}/${weekId}`,
    })
      .then((res) => {
        console.log(res, "Book");
        setSetBookmark({
          isLoading: false,
          data: "Added to bookmarks",
          error: null,
        });

        setNotiticationFunction(
          setNotifications,
          "Added to boookmarks",
          "success"
        );
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setSetBookmark({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const [getBookmarkObjects, setGetBookmarksObject] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  const getBookmarks = (load?: boolean) => {
    if (load && load === true) {
      setGetBookmarksObject({
        isLoading: true,
        data: null,
        error: null,
      });
    } else if (load && load === false) {
      setGetBookmarksObject((prevState) => {
        return { ...prevState, isLoading: false };
      });
    }
    if (getBookmarkObjects.isLoading) {
      return;
    }

    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/bookmarks/courses`,
    })
      .then((res) => {
        setGetBookmarksObject({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setGetBookmarksObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const deleteBookmarks = (id: string) => {
    if (setBookmark.isLoading) {
      return;
    }

    setSetBookmark({
      isLoading: true,
    });
    requestHandler({
      method: "DELETE",
      url: `${backend_url}/api/ise/v1/bookmarks/remove/${id}`,
    })
      .then((res) => {
        setSetBookmark({
          isLoading: false,
          data: "Deleted from bookmarks",
          error: null,
        });
        setNotiticationFunction(
          setNotifications,
          "Deleted from bookmarks",
          "success"
        );
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setSetBookmark({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const [getRecommendedCoursesObject, setGetRecommendedCoursesObject] =
    useState<requestType>({ isLoading: false, data: null, error: null });

  const getRecommendedCourses = () => {
    if (getRecommendedCoursesObject?.isLoading) {
      return;
    }

    setGetRecommendedCoursesObject({
      isLoading: true,
      data: null,
      error: null,
    });
    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/students/recommended-courses`,
    })
      .then((res) => {
        setGetRecommendedCoursesObject({
          isLoading: false,
          data: res?.data,
          error: null,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setGetRecommendedCoursesObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  useEffect(() => {
    const functionTimeout = setTimeout(() => {
      if (searchedParams || searchedParams === "") {
        courseSearchAndFilter();
      }
    }, 1000);

    return () => {
      clearTimeout(functionTimeout);
    };

    // eslint-disable-next-line
  }, [searchedParams]);

  const [setEntityProgressObject, setSetEntityProgressObject] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });

  const [videoProgressObject, setVideoProgressObject] = useState({
    watching_hours: 1,
    is_completed: false,
    resume_time: 0,
  });

  const [readingProgressObject, setReadingProgressObject] = useState({
    readingId: "",
    progress: 0,
    completed: false,
  });

  const setEntityProgress = (
    id: string,
    courseId: string,
    moduleId: string,
    weekId: string,
    type: "video" | "reading",
    load?: boolean
  ) => {
    if (!load) {
      setSetEntityProgressObject((prevState) => {
        return { ...prevState, isLoading: false };
      });
    } else {
      setSetEntityProgressObject((prevState) => {
        return { ...prevState, isLoading: true };
      });
    }

    requestHandler({
      method: "PATCH",
      url:
        type === "video"
          ? `${backend_url}/api/ise/v1/video-progress/${id}/${moduleId}/${weekId}/${courseId}`
          : `${backend_url}/api/ise/v1/reading-progress/${moduleId}/${weekId}/${courseId}`,

      data: type === "reading" ? readingProgressObject : videoProgressObject,
    })
      .then((res) => {
        setSetEntityProgressObject({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setSetEntityProgressObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const [rateCourseObject, setRateCourseObject] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [rating, setRating] = useState({
    rating: 0,
    feed_comment: "",
  });

  const rateCourse = (id: string, load?: boolean) => {
    if (!load) {
      setRateCourseObject((prevState) => {
        return { ...prevState, isLoading: false };
      });
    } else {
      setRateCourseObject((prevState) => {
        return { ...prevState, isLoading: true };
      });
    }

    requestHandler({
      method: "POST",
      url: `${backend_url}/api/ise/v1/enrollments/rate/${id}`,
      data: rating,
    })
      .then((res) => {
        setRateCourseObject({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        setRateCourseObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };
  const [modules, setModules] = useState<any>();
  const [activeWeek, setActiveWeek] = useState();
  const activeWeekId = currentSearchParams.get("activeWeek");
  const activeModule = currentSearchParams.get("activeModule");

  useEffect(() => {
    if (getCourseModulesObject?.data) {
      setModules(
        getCourseModulesObject.data?.map((data: any, i: number) => {
          if (String(data?.id) === activeModule) {
            return { ...data, isActive: true };
          } else {
            return { ...data };
          }
        })
      );
    }
  }, [getCourseModulesObject.data, activeModule]);

  useEffect(() => {
    if (getCourseModulesObject?.data) {
      setActiveWeek(
        modules
          ?.find((data: any) => {
            return data?.isActive;
          })
          ?.course_weeks?.filter((data: any) => {
            return data?.id === Number(activeWeekId);
          })[0]
      );
    }

    // eslint-disable-next-line
  }, [activeWeekId, getCourseModulesObject.data]);

  return (
    <CourseContext.Provider
      value={{
        handleFilterChange,
        searchParams,
        coursesState,
        clearFilters,
        setSearchParams,
        searchIsActive,
        setSearchisActive,
        displayReportModal,
        setDisplayReportModal,
        getCourses,
        iseCourses,
        setIseCourses,
        iseCourseDetail,
        setIseCourseDetail,
        getCoursesById,
        getUserEnrolledCoursesCount,
        userEnrolledCOursesCount,
        bookmarkCourse,
        bookMarkCourseObject,
        getCourseLayout,
        courseLayoutObject,
        savedCourse,
        savedCourseObject,
        unSaveCourse,
        getEnrolledCourses,
        getEnrolledCoursesObject,
        getSavedCourses,
        getSavedCoursesObject,
        getCourseModules,
        getCourseModulesObject,
        getCourseGrades,
        getCourseGradesObject,
        getCourseResources,
        getCourseResourcesObject,
        getSchoolFilter,
        schools,
        setSchools,
        modules,
        setModules,
        activeWeek,
        setActiveWeek,
        getCourseQuizQuestions,
        setGetCourseQuizQuestions,
        getCourseQuiz,
        getBookmarkObjects,
        setGetBookmarksObject,
        getBookmarks,
        deleteBookmarks,
        setBookmark,
        setSetBookmark,
        setBookmarksHandler,
        videoProgressObject,
        setVideoProgressObject,
        readingProgressObject,
        setReadingProgressObject,
        setEntityProgress,
        setSetEntityProgressObject,
        setEntityProgressObject,
        answerCourseQuiz,
        answerCourseQuizObject,
        getRecommendedCourses,
        getRecommendedCoursesObject,
        rateCourse,
        rateCourseObject,
        rating,
        setRating,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

export default CourseContextProvider;
