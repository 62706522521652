import { useState } from "react";
import classes from "./ActiveUserCoursesRecommendedAndSavedContainer.module.css";
import SectionsNav from "../../Components/SectionsNav/SectionsNav";
import RecommendedCourses from "../RecommendedCourses/RecommendedCourses";
import SavedCourses from "../SavedCourses/SavedCourses";

const ActiveUserCoursesRecommendedAndSavedContainer = () => {
  // States
  const [navItems, setNavItems] = useState<any[]>([
    {
      title: "Recommended courses",
      isActive: true,
      activeComponent: <RecommendedCourses />,
    },
    {
      title: "Saved courses",
      isActive: false,
      activeComponent: <SavedCourses />,
    },
  ]);

  //   Utils
  const activeComponent = navItems.find((data) => {
    return data.isActive;
  });
  return (
    <section className={classes.container}>
      <SectionsNav navItems={navItems} setNavItems={setNavItems} />
      {activeComponent.activeComponent}
    </section>
  );
};

export default ActiveUserCoursesRecommendedAndSavedContainer;
