import { useContext } from "react";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./CourseLessonModuleList.module.css";

const CourseLessonModuleList = () => {
  const { setSearchParams } = useContext(AuthUserContext);
  const { modules } = useContext(CourseContext);

  const currentSearchParams = new URLSearchParams(window.location.search);

  return (
    <section className={classes.container}>
      <div className={classes.innerContainer}>
        {(modules as any[])?.map((data: any, i: number) => {
          return (
            <div
              className={`${classes.module} ${
                data.isActive ? classes.active : undefined
              }`}
              key={i}
            >
              <p
                onClick={() => {
                  currentSearchParams.set("activeModule", data?.id);
                  setSearchParams(currentSearchParams.toString());
                }}
              >
                <span>Module {i + 1}: </span>
                {data.title}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                style={
                  data?.isActive
                    ? { transform: "rotate(-90deg)" }
                    : { transform: "rotate(0deg)" }
                }
              >
                <path
                  d="M19 9L12 16L5 9"
                  stroke="#2E2E2E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div
                className={classes.weekContainer}
                style={
                  data.isActive ? { maxHeight: "1300px" } : { maxHeight: "0px" }
                }
              >
                {data.course_weeks?.length > 0 ? (
                  data.course_weeks?.map((datum: any, j: number) => {
                    return (
                      <div
                        key={j}
                        className={classes.week}
                        onClick={() => {
                          currentSearchParams.set("activeWeek", datum?.id);
                          setSearchParams(currentSearchParams.toString());
                        }}
                      >
                        <p>
                          Week {j + 1}: {datum.title}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.99984 10V11.3333M3.99984 14H11.9998C12.7362 14 13.3332 13.403 13.3332 12.6667V8.66667C13.3332 7.93029 12.7362 7.33333 11.9998 7.33333H3.99984C3.26346 7.33333 2.6665 7.93029 2.6665 8.66667V12.6667C2.6665 13.403 3.26346 14 3.99984 14ZM10.6665 7.33333V4.66667C10.6665 3.19391 9.4726 2 7.99984 2C6.52708 2 5.33317 3.19391 5.33317 4.66667V7.33333H10.6665Z"
                            stroke="#737373"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.week}>
                    No weeks available for this module
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CourseLessonModuleList;
