import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CourseInteractionLayout from "../Components/CourseInteractionLayout/CourseInteractionLayout";
import Loader from "../Components/Loader/Loader";
import CourseLessonAssignment from "../Containers/CourseLessonAssignment/CourseLessonAssignment";
import CourseLessonQuizInfo from "../Containers/CourseLessonQuizInfo/CourseLessonQuizInfo";
import CourseLessonRead from "../Containers/CourseLessonRead/CourseLessonRead";
import CourseLessonVideo from "../Containers/CourseLessonVideo/CourseLessonVideo";
import { CourseContext } from "../Context/CourseContext";
import CourseLessonQuiz from "./CourseLessonQuiz";

const CourseLessonScreen = () => {
  // Context
  const {
    getCourseModules,
    getCourseModulesObject,
    getCoursesById,
    modules,
    setSearchParams,
  } = useContext(CourseContext);

  // Router
  const { courseId } = useParams();
  const currentParams = new URLSearchParams(window.location.search);
  const lessonType = currentParams.get("type") || "video";
  const activeModuleId = currentParams?.get("activeModule");
  const activeWeekId = currentParams?.get("activeWeek");
  const type = currentParams?.get("type");
  const activeContentId = currentParams?.get("activeContentId");
  const quizPhase = currentParams.get("quizPhase");
  const activeCohort = currentParams.get("activeCohort");

  // States
  const [activeModuleWeeks, setActiveModuleWeeks] = useState();
  const [allRecords, setAllRecords] = useState<any[]>([]);
  const [activeRecordIndex, setActiveRecordIndex] = useState<
    number | undefined
  >();

  useEffect(() => {
    getCoursesById(courseId as string);

    // eslint-disable-next-line
  }, []);

  // Effects
  useEffect(() => {
    if (activeWeekId) {
      setActiveModuleWeeks(
        modules
          ?.find((data: any) => {
            return data?.id === Number(activeModuleId);
          })
          ?.course_weeks?.map((datum: any) => {
            if (String(datum?.id) === activeWeekId) {
              return { ...datum, isActive: true };
            } else {
              return { ...datum, isActive: false };
            }
          })
      );
    }

    // eslint-disable-next-line
  }, [activeWeekId, modules, type]);

  useEffect(() => {
    if (modules) {
      const testRecords: any[] = [];
      modules.forEach((module: any) => {
        module.course_weeks.forEach((week: any) => {
          week.records.forEach((record: any) => {
            const modifiedRecord = {
              ...record,
              moduleId: module?.id,
              weekId: week?.id,
            };
            testRecords.push(modifiedRecord);
          });
        });
      });

      setAllRecords(testRecords);
    }

    // eslint-disable-next-line
  }, [modules, activeWeekId]);

  useEffect(() => {
    if (
      allRecords.length > 0 &&
      activeModuleId &&
      activeWeekId &&
      activeContentId
    ) {
      const activeRecord = allRecords.find(
        (data) =>
          data.moduleId === Number(activeModuleId) &&
          data.weekId === Number(activeWeekId) &&
          data.title === activeContentId.toString()
      );

      if (activeRecord) {
        setActiveRecordIndex(allRecords.indexOf(activeRecord));
      } else {
        setActiveRecordIndex(0);
      }
    } else {
      setActiveRecordIndex(0);
    }

    // eslint-disable-next-line
  }, [allRecords, activeWeekId, activeModuleId, activeContentId]);

  useEffect(() => {
    if (
      activeRecordIndex &&
      allRecords.length > 0 &&
      !getCourseModulesObject.isLoading
    ) {
      currentParams?.set(
        "activeModule",
        String(allRecords[activeRecordIndex]?.moduleId)
      );
      currentParams?.set(
        "activeWeek",
        String(allRecords[activeRecordIndex]?.weekId)
      );
      currentParams?.set("type", allRecords[activeRecordIndex]?.type);
      currentParams?.set(
        "activeContentId",
        allRecords[activeRecordIndex]?.title
      );
      setSearchParams(currentParams.toString());
    }
    // eslint-disable-next-line
  }, [activeRecordIndex]);

  useEffect(() => {
    if (type && type !== "quiz") {
      currentParams.delete("quizPhase");
      currentParams.delete("quizPhase");
      currentParams.delete("quizId");

      setSearchParams(currentParams.toString());
    }

    // eslint-disable-next-line
  }, [type]);

  if (getCourseModulesObject.isLoading) {
    return (
      <CourseInteractionLayout>
        <Loader />
      </CourseInteractionLayout>
    );
  }

  return (
    <CourseInteractionLayout>
      {lessonType === "video" ? (
        <CourseLessonVideo
          activeModuleWeeks={activeModuleWeeks}
          allRecords={allRecords}
          activeRecordIndex={activeRecordIndex}
          setActiveRecordIndex={setActiveRecordIndex}
        />
      ) : lessonType === "reading" ? (
        <CourseLessonRead activeModuleWeeks={activeModuleWeeks} />
      ) : lessonType === "assignment" ? (
        <CourseLessonAssignment />
      ) : lessonType === "quiz" && !quizPhase ? (
        <CourseLessonQuizInfo allRecords={allRecords} />
      ) : lessonType === "quiz" && quizPhase === "ongoing" ? (
        <CourseLessonQuiz
          allRecords={allRecords}
          setActiveRecordIndex={setActiveRecordIndex}
        />
      ) : null}
    </CourseInteractionLayout>
  );
};

export default CourseLessonScreen;
