import classes from "./CourseFeedbackContainer.module.css";
import { useState } from "react";
import TextArea from "../../Components/TextArea/TextArea";
import Button from "../../Components/Button/Button";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import CourseFeedbackModalbody from "./CourseFeedbackModalBody";
import { useNavigate } from "react-router-dom";

const CourseFeedbackContainer = () => {
  // Router
  const navigate = useNavigate();

  // States
  const [ratings, setRatings] = useState([
    {
      number: 1,
      isActive: false,
    },
    {
      number: 2,
      isActive: false,
    },
    {
      number: 3,
      isActive: false,
    },
    {
      number: 4,
      isActive: false,
    },
    {
      number: 5,
      isActive: false,
    },
  ]);
  const [displayModal, setDisplayModal] = useState(false);

  const markRating = (index: number) => {
    const ratingsCopy = ratings.map((data, i) => {
      if (i <= index) {
        return { ...data, isActive: true };
      } else {
        return { ...data, isActive: false };
      }
    });

    setRatings(ratingsCopy);
  };

  return (
    <section className={classes.container}>
      {displayModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayModal(false);
          }}
          body={
            <CourseFeedbackModalbody
              onClick={() => {
                setDisplayModal(false);
                navigate("/courses");
              }}
            />
          }
        />
      )}
      <div className={classes.innerContainer}>
        <h4>How did you do this week?</h4>
        <div className={classes.rateSection}>
          <p>1. How would you rate your overall progress in the past week? </p>

          <div>
            <span>Poor</span>
            {ratings.map((data, i) => {
              return (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill={data.isActive ? "#fdc500" : "none"}
                  key={data.number}
                  onClick={() => {
                    markRating(i);
                  }}
                >
                  <g clipPath="url(#clip0_8489_18678)">
                    <path
                      d="M19.049 2.92664C19.3483 2.00538 20.6517 2.00538 20.951 2.92664L24.2662 13.1282C24.4 13.5402 24.784 13.8191 25.2172 13.8191L35.9438 13.8195C36.9125 13.8196 37.3153 15.0591 36.5316 15.6285L27.8538 21.9339C27.5033 22.1885 27.3567 22.6398 27.4905 23.0518L30.8049 33.2536C31.1042 34.1749 30.0498 34.941 29.2661 34.3716L20.5878 28.067C20.2373 27.8124 19.7627 27.8124 19.4122 28.067L10.7339 34.3717C9.95024 34.941 8.89581 34.1749 9.19512 33.2536L12.5095 23.0518C12.6433 22.6398 12.4967 22.1885 12.1462 21.9339L3.46838 15.6285C2.68472 15.0591 3.08747 13.8196 4.05616 13.8195L14.7828 13.8191C15.216 13.8191 15.6 13.5402 15.7338 13.1282L19.049 2.92664Z"
                      stroke="#2E2E2E"
                      strokeWidth="2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8489_18678">
                      <rect width="40" height="40" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              );
            })}
            <span>Excellent</span>
          </div>
        </div>

        <TextArea
          label="2. Did you face any challenges or difficulties while 
studying this week? If yes, please provide details."
          placeholder="Placeholder Text"
        />

        <div className={classes.rateSection}>
          <p>
            3. On a scale of 1-5, how engaging and interactive did you find the
            course materials?
          </p>
          <div>
            <span>Poor</span>
            {ratings.map((data, i) => {
              return (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill={data.isActive ? "#fdc500" : "none"}
                  key={data.number}
                  onClick={() => {
                    markRating(i);
                  }}
                >
                  <g clipPath="url(#clip0_8489_18678)">
                    <path
                      d="M19.049 2.92664C19.3483 2.00538 20.6517 2.00538 20.951 2.92664L24.2662 13.1282C24.4 13.5402 24.784 13.8191 25.2172 13.8191L35.9438 13.8195C36.9125 13.8196 37.3153 15.0591 36.5316 15.6285L27.8538 21.9339C27.5033 22.1885 27.3567 22.6398 27.4905 23.0518L30.8049 33.2536C31.1042 34.1749 30.0498 34.941 29.2661 34.3716L20.5878 28.067C20.2373 27.8124 19.7627 27.8124 19.4122 28.067L10.7339 34.3717C9.95024 34.941 8.89581 34.1749 9.19512 33.2536L12.5095 23.0518C12.6433 22.6398 12.4967 22.1885 12.1462 21.9339L3.46838 15.6285C2.68472 15.0591 3.08747 13.8196 4.05616 13.8195L14.7828 13.8191C15.216 13.8191 15.6 13.5402 15.7338 13.1282L19.049 2.92664Z"
                      stroke="#2E2E2E"
                      strokeWidth="2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8489_18678">
                      <rect width="40" height="40" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              );
            })}
            <span>Excellent</span>
          </div>
        </div>

        <TextArea
          label="4. Is there any specific topic or concept that you found particularly challenging? If yes, please specify."
          placeholder="Placeholder Text"
        />

        <TextArea
          label="5. Is there any other feedback or suggestion you would like to share with us?"
          placeholder="Placeholder Text"
        />
        <div className={classes.buttonContainer}>
          <Button
            onClick={() => {
              setDisplayModal(true);
            }}
          >
            <span>Submit</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M14 5L21 12M21 12L14 19M21 12L3 12"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default CourseFeedbackContainer;
