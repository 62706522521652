import { useContext } from "react";
import { Link } from "react-router-dom";
import Button from "../../Components/Button/Button";
import Error from "../../Components/Error/Error";
import { AppContext } from "../../Context/AppContext";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { ReferralContext } from "../../Context/ReferralContext";
import { capitalize } from "../../HelperFunctions/capitalize";
import classes from "./ReferralSpreadTheWord.module.css";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

type SpreadTheWordModalBodyProps = {
  onClick: () => void;
};

const SpreadTheWordModalBody = ({ onClick }: SpreadTheWordModalBodyProps) => {
  // Context
  const {
    referralLinkReceiver,
    setReferralLinkReceiver,
    sendStudentReferralLink,
    sendstudentReferralLinkObject,
  } = useContext(ReferralContext);

  const { user } = useContext(AuthUserContext);
  const { copyToClipBoard } = useContext(AppContext);

  return (
    <div className={classes.modalContainer}>
      <div className={classes.modalHeader}>
        <h4>Spread the word. Refer a friend</h4>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          onClick={onClick}
        >
          <path
            d="M6 18.5L18 6.5M6 6.5L18 18.5"
            stroke="#2E2E2E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <p>
        Refer your friends to iṣẹ́ EdTech, and you can enjoy exclusive benefits.
        Let's build a thriving community of learners.
      </p>

      {sendstudentReferralLinkObject?.data && (
        <Error type="success">{sendstudentReferralLinkObject?.data}</Error>
      )}
      {sendstudentReferralLinkObject?.error && (
        <Error type="error">
          {capitalize(sendstudentReferralLinkObject?.error)}
        </Error>
      )}

      <div className={classes.section}>
        <h5>Send as an invite</h5>
        <p>
          Enter your friend’s email to send them an automatic invite to join iṣẹ́
          EdTech.
        </p>
        <div className={classes.emailInput}>
          <input
            type="email"
            placeholder="myfriend’smail@gmail.com"
            value={referralLinkReceiver}
            onChange={(e) => {
              setReferralLinkReceiver(e.target.value);
            }}
          />
          <Button
            onClick={sendStudentReferralLink}
            loading={sendstudentReferralLinkObject.isLoading}
            disabled={!referralLinkReceiver}
          >
            Invite
          </Button>
        </div>
      </div>

      <div className={classes.section}>
        <h5>Share via social media</h5>
        <p>
          You can also share your referral link by copying and sending it and
          sharing it on your social media
        </p>
        <div className={classes.modalSocialShare}>
          <div>
            <p>{`${window.location.origin}/sign-up?referralId=${user?.data?.referral_id}`}</p>
            <span
              onClick={() => {
                copyToClipBoard(
                  `${window.location.origin}/sign-up?referralId=${user?.data?.referral_id}`
                );
              }}
            >
              <span>Copy</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
              >
                <path
                  d="M6.75 14C6.3375 14 5.98438 13.8531 5.69063 13.5594C5.39688 13.2656 5.25 12.9125 5.25 12.5V3.5C5.25 3.0875 5.39688 2.73438 5.69063 2.44063C5.98438 2.14687 6.3375 2 6.75 2H13.5C13.9125 2 14.2656 2.14687 14.5594 2.44063C14.8531 2.73438 15 3.0875 15 3.5V12.5C15 12.9125 14.8531 13.2656 14.5594 13.5594C14.2656 13.8531 13.9125 14 13.5 14H6.75ZM6.75 12.5H13.5V3.5H6.75V12.5ZM3.75 17C3.3375 17 2.98438 16.8531 2.69063 16.5594C2.39687 16.2656 2.25 15.9125 2.25 15.5V5H3.75V15.5H12V17H3.75Z"
                  fill="#664EFE"
                />
              </svg>
            </span>
          </div>
          <span>
            <FacebookShareButton
              url={`https://ise.africa/sign-up?referralId=${user?.data?.referral_id}`}
              title="Use my referral link from Ise Africa here: "
            >
              <FacebookIcon size={48} round />
            </FacebookShareButton>
          </span>

          <span>
            <TwitterShareButton
              url={`https://ise.africa/sign-up?referralId=${user?.data?.referral_id}`}
              title="Use my referral link from Ise Africa here: "
            >
              <TwitterIcon size={48} round />
            </TwitterShareButton>
          </span>

          <span>
            <LinkedinShareButton
              url={`https://ise.africa/sign-up?referralId=${user?.data?.referral_id}`}
              title="Use my referral link from Ise Africa here: "
            >
              <LinkedinIcon size={48} round />
            </LinkedinShareButton>
          </span>
        </div>
      </div>

      <div className={classes.modalButtonSection}>
        <Button type="secondary">View history</Button>
        <Button onClick={onClick}>Done</Button>
      </div>

      <div className={classes.policy}>
        To learn more, view our{" "}
        <Link to="/referral/policy" onClick={onClick}>
          Referral policy{" "}
        </Link>
      </div>
    </div>
  );
};

export default SpreadTheWordModalBody;
