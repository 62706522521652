import { useContext, useState } from "react";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import { AppContext } from "../../Context/AppContext";
import { AuthUserContext } from "../../Context/AuthUserContext";
import classes from "./ReferralSpreadTheWord.module.css";
import SpreadTheWordModalBody from "./SpreadTheWordModalBody";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

const ReferralSpreadTheWord = () => {
  // Context
  const { user } = useContext(AuthUserContext);
  const { copyToClipBoard } = useContext(AppContext);

  // States
  const [displayModal, setDisplayModal] = useState(false);

  return (
    <>
      {displayModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayModal(false);
          }}
          body={
            <SpreadTheWordModalBody
              onClick={() => {
                setDisplayModal(false);
              }}
            />
          }
        />
      )}
      <section className={classes.container}>
        <h4>Spread the word. Refer a friend</h4>
        <p>Refer your friends to isé and enjoy exclusive benefits.</p>
        <p>Copy your personal link</p>

        <div className={classes.info}>
          <span>{`${window.location.origin}/sign-up?referralId=${user?.data?.referral_id}`}</span>
          <span
            onClick={() => {
              copyToClipBoard(
                `${window.location.origin}/sign-up?referralId=${user?.data?.referral_id}`
              );
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M5.83366 10.6641H4.50033C3.76395 10.6641 3.16699 10.0671 3.16699 9.33073V3.9974C3.16699 3.26102 3.76395 2.66406 4.50033 2.66406H9.83366C10.57 2.66406 11.167 3.26102 11.167 3.9974V5.33073M7.16699 13.3307H12.5003C13.2367 13.3307 13.8337 12.7338 13.8337 11.9974V6.66406C13.8337 5.92768 13.2367 5.33073 12.5003 5.33073H7.16699C6.43061 5.33073 5.83366 5.92768 5.83366 6.66406V11.9974C5.83366 12.7338 6.43061 13.3307 7.16699 13.3307Z"
                stroke="#2E2E2E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
        <div className={classes.or}>
          <hr /> <span>or</span> <hr />
        </div>
        <div className={classes.socialShare}>
          <p>Share on social media</p>
          <div>
            <span>
              <FacebookShareButton
                url={`https://ise.africa/sign-up?referralId=${user?.data?.referral_id}`}
                title="Use my referral link from Ise Africa here: "
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </span>

            <span>
              <TwitterShareButton
                url={`https://ise.africa/sign-up?referralId=${user?.data?.referral_id}`}
                title="Use my referral link from Ise Africa here: "
              >
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </span>

            <span>
              <LinkedinShareButton
                url={`https://ise.africa/sign-up?referralId=${user?.data?.referral_id}`}
                title="Use my referral link from Ise Africa here: "
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </span>
          </div>
        </div>
        <div className={classes.or}>
          <hr /> <span>or</span> <hr />
        </div>
        <p>Send as email invite</p>
        <div className={classes.info}>
          <span> myfriend’smail@gmail.com</span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              onClick={() => {
                setDisplayModal(true);
              }}
            >
              <path
                d="M11.3333 5.33594L14 8.0026M14 8.0026L11.3333 10.6693M14 8.0026L2 8.0026"
                stroke="#2E2E2E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
      </section>
    </>
  );
};

export default ReferralSpreadTheWord;
