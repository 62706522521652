import { useContext, useState } from "react";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import ProfileSectionContainer from "../../Components/ProfileSectionContainer/ProfileSectionContainer";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { changeHandler } from "../../Utilities/inputChangeHandler";
import classes from "./ProfileAccountManageAccounts.module.css";

const ProfileAccountManageAccounts = () => {
  // Context
  const {
    passwordUpdate,
    setPasswordUpdate,
    updatePasswordHandler,
    updatePasswordHandlerObject,
  } = useContext(AuthUserContext);

  // States
  const [displayInput, setDiplayInput] = useState(false);

  return (
    <div className={classes.container}>
      <ProfileSectionContainer
        header="Manage password"
        paragraph="Keep your account secure. Update your password here."
      >
        <Input
          label="Current password"
          type="password"
          placeholder="Enter current password"
          name="oldPassword"
          onChange={(e) => {
            changeHandler(e, setPasswordUpdate);
          }}
          value={passwordUpdate?.oldPassword}
        />

        {!displayInput && (
          <Button
            type="null"
            onClick={() => {
              setDiplayInput(true);
            }}
          >
            Change password
          </Button>
        )}
        {displayInput && (
          <>
            <Input
              label="New password"
              type="password"
              placeholder="Enter new password "
              name="newPassword"
              onChange={(e) => {
                changeHandler(e, setPasswordUpdate);
              }}
              value={passwordUpdate?.newPassword}
            />
            <Input
              label="Confirm new password"
              type="password"
              placeholder="Enter new password "
              tip="Must match the new password"
              name="confirmPassword"
              onChange={(e) => {
                changeHandler(e, setPasswordUpdate);
              }}
              value={passwordUpdate?.confirmPassword}
            />
            <div className={classes.buttonSection}>
              <Button
                type="secondary"
                onClick={() => {
                  setDiplayInput(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  passwordUpdate?.newPassword !==
                  passwordUpdate?.confirmPassword
                }
                onClick={updatePasswordHandler}
                loading={updatePasswordHandlerObject?.isLoading}
              >
                Save new password
              </Button>
            </div>
          </>
        )}
      </ProfileSectionContainer>
    </div>
  );
};

export default ProfileAccountManageAccounts;
