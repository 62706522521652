import classes from "./CourseDetailPageSpeakWithTutor.module.css";
import DropdownWithSearch from "../../Components/DropdownWithSearch/DropdownWithSearch";
import TextArea from "../../Components/TextArea/TextArea";
import FileUploadInput from "../../Components/FileUploadInput/FileUploadInput";
import Button from "../../Components/Button/Button";
import { useContext, useEffect, useState } from "react";
import { SupportContext } from "../../Context/SupportContext";

type ReportAnIssueContainerProps = {
  onClick: () => void;
};

const ReportAnIssueContainer = ({ onClick }: ReportAnIssueContainerProps) => {
  // context
  const { setSupportForm, sendSupportObject, sendSupport } =
    useContext(SupportContext);

  // States
  const [subject, setSubject] = useState("");

  // Utils
  const imageHandler = (e: any) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
      }
    };
    reader.readAsDataURL(e.target.files[0]);

    setSupportForm((prevState) => {
      return { ...prevState, image: e.target.files[0] };
    });
  };

  // Effects
  useEffect(() => {
    setSupportForm((prevState) => {
      return { ...prevState, subject };
    });

    // eslint-disable-next-line
  }, [subject]);

  return (
    <div className={classes.modalcontainer}>
      <h4>Report an issue</h4>
      <p>
        Facing issues in your learning journey? Share the details below, and
        your tutor will assist you promptly. Stay updated through your email.
        We're here for you!
      </p>

      <div className={classes.doNotShare}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M13 16H12V12H11M12 8H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            stroke="#2E2E2E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <p>
          Do not share sensitive information (attachments or text). Ex. Your
          credit card details or personal ID numbers.
        </p>
      </div>

      <DropdownWithSearch
        label="Select an issue you would like to report"
        options={[
          "Content improvement",
          "Video issues",
          "Audio issues",
          "Content confusion",
          "Offensive content",
        ]}
        isRequired
        title="Select"
        selected={subject}
        setSelected={setSubject}
      />

      <TextArea
        isRequired
        label="Describe the issue"
        placeholder="Provide a detailed description of the problem you're facing."
        onChange={(e) => {
          setSupportForm((prevState) => {
            return { ...prevState, description: e.target.value };
          });
        }}
      />

      <div className={classes.attachFile}>
        <FileUploadInput
          label="Attach file "
          tip="Attach any relevant screenshots or files to help us understand the issue better."
          onChange={imageHandler}
        />
      </div>

      <div className={classes.buttonSection}>
        <Button type="secondary" onClick={onClick}>
          Close
        </Button>
        <Button loading={sendSupportObject.isLoading} onClick={sendSupport}>
          Submit issue
        </Button>
      </div>
    </div>
  );
};

export default ReportAnIssueContainer;
