import { useContext, useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import { ReferralContext } from "../../Context/ReferralContext";
import classes from "./ReferralYourProgress.module.css";

const ReferralYourProgress = () => {
  // Context
  const { referralLevels, studentReferrals } = useContext(ReferralContext);

  // States
  const [benefits, setBenefits] = useState([]);

  // Utils
  const numberOfReferrals: number = studentReferrals?.data?.length || 0;
  const referralBreakPoints = (referralLevels.data &&
    referralLevels.data.map((data: any) => {
      return data.max_referral_number;
    })) || [2, 4, 8, 12, 16, 20];

  const alteredumberOfReferrals =
    numberOfReferrals === 0 ? numberOfReferrals + 1 : numberOfReferrals;
  let referralBreakPointNumber: number[] = [];
  let referalValues: number[] = [];

  for (let i = 0; i < alteredumberOfReferrals; i++) {
    referralBreakPointNumber = referralBreakPoints.filter((data: number) => {
      return data <= i;
    });
  }

  const lastElementInReferralBreakPointNumberIndex =
    referralBreakPoints.indexOf(
      referralBreakPointNumber[referralBreakPointNumber.length - 1]
    );

  const referralsArray = [
    ...referralBreakPointNumber,
    referralBreakPoints[lastElementInReferralBreakPointNumberIndex + 1],
  ];

  for (let i = 0; i <= referralsArray[referralsArray.length - 1]; i++) {
    referalValues.push(i);
  }

  const activeReferralStatus =
    referralLevels?.data?.length > 0 &&
    referralLevels.data?.find((data: any) => {
      return (
        data.max_referral_number === referralsArray[referralsArray.length - 1]
      );
    });

  useEffect(() => {
    if (referralLevels.data)
      if (activeReferralStatus) {
        setBenefits(activeReferralStatus?.benefits);
      }

    // eslint-disable-next-line
  }, [referralLevels.data, activeReferralStatus]);

  return (
    <section className={classes.container}>
      <h4>Your progress</h4>
      <p>
        This is how you are performing, spread the word and let’s build a
        community.
      </p>

      <div className={classes.progressWarning}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21ZM16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11H16Z"
            stroke="#2E2E2E"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
        <span>
          Invite{" "}
          <span>
            {referralsArray[referralsArray.length - 1] - numberOfReferrals}{" "}
            friends
          </span>{" "}
          to unlock {activeReferralStatus?.name}
        </span>
      </div>

      <div className={classes.progressCounter}>
        <div className={classes.progressNumbers}>
          {referalValues.map((data) => {
            return <span key={data}>{data}</span>;
          })}
        </div>

        <div className={classes.progressBars}>
          {referalValues?.slice(1)?.map((data) => {
            return (
              <div key={data}>
                <ProgressBar
                  notShowPercentage
                  percentage={data <= numberOfReferrals ? 100 : 0}
                  subStyle={{ color: "#FFD029" }}
                  progressBarStyle={{ background: "#B9B9B9" }}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className={classes.pioneerContainer}>
        <h4>{activeReferralStatus?.name}</h4>
        <ul>
          {benefits?.map((data: any) => {
            return (
              <li key={data.id}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M2.5 6.5L4.5 8.5L9.5 3.5"
                      stroke="#F7F7F7"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>

                <span>{data?.value}</span>
              </li>
            );
          })}
        </ul>
      </div>
      {referralsArray[referralsArray.length - 1] === numberOfReferrals && (
        <div className={classes.buttonContainer}>
          <Button>Claim Reward</Button>
        </div>
      )}
    </section>
  );
};

export default ReferralYourProgress;
