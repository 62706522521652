import { useContext, useEffect, useState } from "react";
import DropdownWithSearch from "../../Components/DropdownWithSearch/DropdownWithSearch";
import classes from "./PaymentMethodContainer.module.css";
import mastercard from "../../Assets/Images/mastercard.svg";
import visa from "../../Assets/Images/visa.svg";
import paystack from "../../Assets/Images/paystack.svg";
import flutterwave from "../../Assets/Images/flutterwave.svg";
import activePaymentNotice from "../../Assets/Images/activePaymentNotice.svg";
import { capitalize } from "../../HelperFunctions/capitalize";
import Input from "../../Components/Input/Input";
import Button from "../../Components/Button/Button";
import {
  formatAmountWithCommas2dp,
  removeCommasFromAmount,
} from "../../HelperFunctions/amountToString";
import { activePlanType } from "../PaymentMain/PaymentMain";
import { PaymentContext } from "../../Context/PaymentContext";
import Loader from "../../Components/Loader/Loader";
import { useParams } from "react-router-dom";

type PaymentMethodContainerType = {
  activePlan: activePlanType;
};

const PaymentMethodContainer = ({ activePlan }: PaymentMethodContainerType) => {
  const {
    paymentProiders,
    getPaymentProviders,
    setPaymentProviders,
    baseCurrency,
    setBaseCurrency,
    exchangerates,
    checkout,
    getCurrentExchangeRate,
    checkoutObject,
    getCoupon,
    getCouponObject,
  } = useContext(PaymentContext);

  // Router
  const { courseId, paymentPlan } = useParams();

  // States
  const [couponCode, setCouponCode] = useState("");

  //   Utils
  const subTotal = exchangerates?.error
    ? Number(removeCommasFromAmount(activePlan?.price as string))
    : Number(removeCommasFromAmount(activePlan?.price as string)) *
      Number(exchangerates?.data as string);

  const [discount, setDiscount] = useState(
    exchangerates?.error
      ? (activePlan?.discount as number)
      : (activePlan?.discount as number) *
          Number(exchangerates?.data as string) || 0
  );

  // Effects
  useEffect(() => {
    getPaymentProviders();
    getCurrentExchangeRate();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (baseCurrency) {
      getCurrentExchangeRate();
    }

    // eslint-disable-next-line
  }, [baseCurrency]);

  useEffect(() => {
    if (getCouponObject.data) {
      const discountedAmount =
        (Number(activePlan?.price) as number) *
        ((getCouponObject.data?.discount_percentage as number) / 100);

      if (exchangerates.error) {
        setDiscount(discountedAmount || (activePlan?.discount as number) || 0);
      } else {
        setDiscount(
          discountedAmount * Number(exchangerates?.data as string) ||
            (activePlan?.discount as number) *
              Number(exchangerates?.data as string) ||
            0
        );
      }
    } else {
      setDiscount(0);
    }

    if (getCouponObject.error) {
      setDiscount(0);
    }

    // eslint-disable-next-line
  }, [paymentPlan, getCouponObject]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div>
          <h4>Payment method</h4>
          <p>Change how you make payments on ISE</p>
        </div>
        <div>
          <p>Pay in</p>
          <span>
            <DropdownWithSearch
              title="NGN"
              options={exchangerates.error ? ["NGN"] : ["USD", "NGN"]}
              selected={baseCurrency}
              setSelected={setBaseCurrency}
            />
          </span>
        </div>
      </div>

      <div className={classes.paymentOptionContainer}>
        {paymentProiders.isLoading ? (
          <Loader />
        ) : (
          (paymentProiders?.data as any[])?.map((data, i) => {
            return (
              <div className={classes.paymentOptionOuter} key={i}>
                <div
                  className={classes.paymentOption}
                  style={
                    data.isActive
                      ? { borderRadius: "10px 10px 0 0", borderBottom: "none" }
                      : { borderRadius: "10px" }
                  }
                  onClick={() => {
                    const paymentOptionsCopy = (
                      paymentProiders?.data as any[]
                    ).map((data, index) => {
                      if (i === index) {
                        return { ...data, isActive: !data.isActive };
                      } else {
                        return { ...data, isActive: false };
                      }
                    });

                    setPaymentProviders((prevState) => {
                      return { ...prevState, data: paymentOptionsCopy };
                    });
                  }}
                >
                  <input
                    type="radio"
                    id={data.name}
                    checked={data.isActive}
                    onChange={(e) => {
                      const paymentOptionsCopy = (
                        paymentProiders?.data as any[]
                      )?.map((data, index) => {
                        if (i === index) {
                          return { ...data, isActive: !data.isActive };
                        } else {
                          return { ...data, isActive: false };
                        }
                      });

                      setPaymentProviders((prevState) => {
                        return { ...prevState, data: paymentOptionsCopy };
                      });
                    }}
                  />
                  <img
                    src={
                      data.name.toLowerCase() === "flutterwave"
                        ? flutterwave
                        : paystack
                    }
                    alt={data.name}
                  />
                  <div className={classes.cardTypes}>
                    <div>
                      <img src={mastercard} alt="Mastercard" />
                    </div>
                    <div>
                      <img src={visa} alt="Visa Card" />
                    </div>
                  </div>
                </div>
                <div
                  className={classes.activeNoticeOuter}
                  style={
                    data.isActive
                      ? { maxHeight: "200px" }
                      : { maxHeight: "0px" }
                  }
                >
                  <div className={classes.activeNotice}>
                    <img src={activePaymentNotice} alt="Payment notice" />
                    <p>
                      When you click 'Checkout,' your payment is securely
                      processed by {capitalize(data.name)}.
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      <div className={classes.couponContainer}>
        <div>
          <Input
            label="Promo code"
            placeholder="Enter coupon code"
            tip="Discount applied"
            style={{ marginBottom: "0px" }}
            onChange={(e) => {
              setCouponCode(e.target.value);
            }}
          />
        </div>
        <div>
          <Button
            type="tertiary"
            loading={getCouponObject.isLoading}
            onClick={() => {
              getCoupon(couponCode);
            }}
          >
            Apply
          </Button>
        </div>
      </div>

      <div className={classes.totalContainer}>
        <div>
          <div className={classes.totalData}>
            <span>SubTotal</span>
            <span>
              {baseCurrency?.toUpperCase() === "NGN" ? "NGN" : "USD"}
              <span>
                {exchangerates?.isLoading
                  ? "--.--"
                  : formatAmountWithCommas2dp(subTotal)}
              </span>
            </span>
          </div>

          <div className={classes.totalData}>
            <span>Discount</span>
            <span>
              {baseCurrency?.toUpperCase() === "NGN" ? "NGN" : "USD"}
              <span>
                {exchangerates?.isLoading
                  ? "--.--"
                  : formatAmountWithCommas2dp(discount)}{" "}
              </span>
            </span>
          </div>
        </div>

        <div className={classes.totalData}>
          <span>Total</span>
          <span>
            {baseCurrency?.toUpperCase() === "NGN" ? "NGN" : "USD"}
            <span>
              {exchangerates.isLoading
                ? "--.--"
                : formatAmountWithCommas2dp(subTotal - discount)}
            </span>
          </span>
        </div>
      </div>

      <div className={classes.buttonSection}>
        <Button
          disabled={
            !(paymentProiders.data as any[])?.find((data) => {
              return data.isActive;
            }) || exchangerates.isLoading
          }
          onClick={() => {
            if (
              (paymentProiders.data as any[])?.find((data) => {
                return data.isActive;
              }) &&
              !exchangerates.isLoading &&
              activePlan?.title.split(" ")[0].toLowerCase() &&
              (paymentProiders?.data as any[])?.length > 0
            ) {
              checkout(
                activePlan?.title.split(" ")[0].toLowerCase() === "full"
                  ? "complete"
                  : "instalment",
                (paymentProiders?.data as any[])?.find((data) => data.isActive)
                  .id,
                courseId as string,
                couponCode ? couponCode : null
              );
            }

            console.log(
              activePlan?.title.split(" ")[0].toLowerCase() === "full"
                ? "complete"
                : "intallment",
              (paymentProiders?.data as any[])?.find((data) => data.isActive)
                .id,
              courseId as string,
              couponCode
            );
          }}
          loading={checkoutObject.isLoading}
        >
          <span className="buttonInner">
            <span>Checkout</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M17.0039 8L21.0039 12M21.0039 12L17.0039 16M21.0039 12L3.00391 12"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </Button>
      </div>
    </div>
  );
};

export default PaymentMethodContainer;
