import classes from "./CourseLessonAssignment.module.css";

const CourseLessonAssignment = () => {
  // Utils
  const taskDescription = [
    "Data Collection (10 points): Each student is required to collect data on a topic of their choice. This could be as simple as surveying their classmates about their favorite hobbies or gathering data on weather conditions in their local area over a week. Ensure the dataset contains at least 20 data points.",
    "Data Cleaning (10 points): After collecting the data, students should clean the dataset by addressing any missing values, outliers, or inconsistencies. Document the steps taken to clean the data.",
    "Data Exploration (20 points): Perform basic data exploration by calculating summary statistics (mean, median, standard deviation), creating appropriate data visualizations (e.g., histograms, scatter plots), and identifying trends or patterns in the data.",
    "Data Interpretation (20 points): Write a brief analysis (around 300-500 words) of the insights gained from the data. What can you infer from the data? Are there any correlations or interesting observations?",
    "Presentation (10 points): Prepare a brief presentation (3-5 minutes) summarizing your data collection, cleaning, exploration, and interpretation. You can use PowerPoint or any presentation tool of your choice.",
  ];

  const submission = [
    "The cleaned dataset (Excel, CSV, or any suitable format).",
    "The data analysis report.",
    "The presentation.",
  ];

  const gradingCriteria =
    "Students will be graded based on the completeness of the assignment, the accuracy of their data analysis, and the quality of their presentation. Additionally, their ability to communicate insights from the data will be evaluated.";

  const note =
    "This assignment is designed to give students a hands-on introduction to data analysis and its real-world applications. The choice of topic and data collection method can be tailored to the students' interests and the resources available.";
  return (
    <section className={classes.container}>
      <h2 className={classes.header}>Assignment</h2>
      <div className={classes.taskDescription}>
        <h4>Task Description:</h4>
        <ol>
          {taskDescription.map((data, i) => {
            return <li key={i}>{data}</li>;
          })}
        </ol>
      </div>

      <div className={classes.submission}>
        <h4>Submission:</h4>
        <ul>
          {submission.map((data, i) => {
            return <li key={i}>{data}</li>;
          })}
        </ul>
      </div>

      <div className={classes.gradingCriteria}>
        <h4>Grading Criteria: :</h4>
        <p>{gradingCriteria}</p>
      </div>

      <div className={classes.note}>
        <h4>Note:</h4>
        <p>{note}</p>
      </div>

      <div className={classes.fileDownload}>
        <span>Download assignment file</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M4 16L4 17C4 18.6569 5.34315 20 7 20L17 20C18.6569 20 20 18.6569 20 17L20 16M16 12L12 16M12 16L8 12M12 16L12 4"
            stroke="#664EFE"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <div className={classes.submitAssignment}>
        <h4>Submit assignment</h4>
        <p>Upload file </p>
        <div className={classes.dropContainer}>
          <span>Drag and drop file to attach it </span>
          <span>or</span>
          <span>browse for a file...</span>
        </div>
      </div>
    </section>
  );
};

export default CourseLessonAssignment;
