import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Button from "../../Components/Button/Button";
import CheckBox from "../../Components/CheckBox/CheckBox";
import Loader from "../../Components/Loader/Loader";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import RadioButton from "../../Components/RadioButton/RadioButton";
import { CourseContext } from "../../Context/CourseContext";
import { countdownMinutesSeconds } from "../../HelperFunctions/countdown";
import classes from "./CourseLessonQuizQuestionContaoner.module.css";
import CourseLessonQuizResultsModal, {
  CourseLessonQuizResultsModalFail,
} from "./CourseLessonQuizResultsModal";

type CourseLessonQuizQuestionContaonerProps = {
  allRecords: any;
  setActiveRecordIndex: Dispatch<SetStateAction<any>>;
};

export const questions = [
  {
    question: "What is the primary goal of exploratory data analysis (EDA)?",
    options: [
      { option: "To make predictions", isSelected: false, isCorrect: true },
      { option: "To summarize data", isSelected: false, isCorrect: false },
      { option: "To remove outliers", isSelected: false, isCorrect: false },
      {
        option: "To validate statistical assumptions",
        isSelected: false,
        isCorrect: false,
      },
    ],
    questionType: "monoChoice",
  },
  {
    question:
      " Which of the following are common methods for data preprocessing in data analysis?",
    options: [
      { option: "Data Visualization", isSelected: false, isCorrect: false },
      { option: "Outlier Detection", isSelected: false, isCorrect: false },
      { option: "Data Cleaning", isSelected: false, isCorrect: true },
      { option: "Data Encryption", isSelected: false, isCorrect: true },
    ],
    questionType: "multiChoice",
  },
];

const CourseLessonQuizQuestionContaoner = ({
  allRecords,
  setActiveRecordIndex,
}: CourseLessonQuizQuestionContaonerProps) => {
  // Context
  const {
    getCourseQuizQuestions,
    getCourseQuiz,
    setSearchParams,
    answerCourseQuiz,
    answerCourseQuizObject,
  } = useContext(CourseContext);

  // Router
  const currentParams = new URLSearchParams(window.location.search);
  const quizQuestion = currentParams.get("quizQuestion");
  const quizId = currentParams.get("quizId");

  // State
  const [displayModal, setDisplayModal] = useState(false);
  const passStatus = true;

  const [selectedOption, setSelectedOption] = useState(null);
  const [activeAnswer, setActiveAnswer] = useState();
  const [quizTimer, setQuizTimer] = useState({
    minutes: 0,
    seconds: 0,
  });

  const handleOptionChange = (optionId: any) => {
    if (selectedOption === optionId) {
      setSelectedOption(null);
    } else {
      setSelectedOption(optionId);
    }
  };

  // Effects
  useEffect(() => {
    if (quizId && quizQuestion) {
      getCourseQuiz(quizId, quizQuestion as string);
    }

    // eslint-disable-next-line
  }, [quizId, quizQuestion]);

  useEffect(() => {
    if (quizQuestion) {
      setSelectedOption(null);
    }

    // eslint-disable-next-line
  }, [quizQuestion]);

  useEffect(() => {
    if (getCourseQuizQuestions?.data && selectedOption) {
      const cutOutOptions = getCourseQuizQuestions?.data?.data[0]?.options;

      const activeOption = cutOutOptions.find((data: any) => {
        return data.id === selectedOption;
      });

      setActiveAnswer(activeOption);
    }

    // eslint-disable-next-line
  }, [getCourseQuizQuestions?.data, selectedOption]);

  useEffect(() => {
    const intervalId = countdownMinutesSeconds(
      30,
      ({ minutes, seconds }) => {
        setQuizTimer({ minutes, seconds });
      },
      () => {
        setQuizTimer({ minutes: 0, seconds: 0 });
      }
    );

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={classes.container}>
      {displayModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayModal(false);
          }}
          body={
            passStatus ? (
              <CourseLessonQuizResultsModal
                onClick={() => {
                  setDisplayModal(false);
                }}
              />
            ) : (
              <CourseLessonQuizResultsModalFail
                onClick={() => {
                  setDisplayModal(false);
                }}
              />
            )
          }
        />
      )}
      <div className={classes.questionHeader}>
        <span>Quiz number/title</span>
        <span>
          Time remaining:{" "}
          <span>
            {quizTimer.minutes}mins : {quizTimer.seconds}secs
          </span>
        </span>
        <span
          onClick={() => {
            setActiveRecordIndex((prevState: any) => {
              return prevState + 1;
            });
          }}
        >
          <span>Close</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6 18L18 6M6 6L18 18"
              stroke="#664EFE"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      {getCourseQuizQuestions.isLoading ? (
        <div className={classes.questionCard}>
          <Loader />
        </div>
      ) : (
        <div className={classes.questionCard}>
          <div className={classes.question}>
            <div className={classes.indexSection}>
              ({getCourseQuizQuestions?.data?.current_page}/
              {getCourseQuizQuestions?.data?.total})
            </div>
            <h4>{getCourseQuizQuestions?.data?.data[0]?.text}</h4>
            <span className={classes.questionSelectMode}>
              {getCourseQuizQuestions?.data?.data[0]?.question_type ===
              "multiple-choice"
                ? "(Choose one answer)"
                : "(Select all that apply)"}
            </span>
          </div>
          <div className={classes.optionSection}>
            {getCourseQuizQuestions?.data?.data[0]?.options?.map(
              (data: any, i: number) => {
                return (
                  <div className={classes.anseer} key={i}>
                    <span>
                      {getCourseQuizQuestions?.data?.data[0]?.question_type ===
                      "multiple-choice" ? (
                        <RadioButton
                          checked={selectedOption === data.id}
                          onChange={() => handleOptionChange(i + 1)}
                        />
                      ) : (
                        <CheckBox
                          checked={selectedOption === data.id}
                          onChange={() => handleOptionChange(i)}
                        />
                      )}
                    </span>
                    <span>{data?.text}</span>
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}

      <div className={classes.buttonSection}>
        <Button
          type="secondary"
          disabled={getCourseQuizQuestions?.data?.prev_page_url ? false : true}
          onClick={() => {
            if (getCourseQuizQuestions?.data?.prev_page_url) {
              currentParams.set(
                "quizQuestion",
                String(Number(quizQuestion) - 1)
              );
              setSearchParams(currentParams.toString());
            }
          }}
        >
          Previous
        </Button>
        <Button
          disabled={(activeAnswer as any)?.text ? false : true}
          loading={answerCourseQuizObject?.isLoading}
          onClick={() => {
            if (getCourseQuizQuestions?.data?.next_page_url) {
              answerCourseQuiz(quizQuestion as string, {
                answer: (activeAnswer as any)?.text,
              });
            } else {
              setActiveRecordIndex((prevState: any) => {
                return prevState + 1;
              });
            }
          }}
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default CourseLessonQuizQuestionContaoner;
