import { useContext } from "react";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./CourseDetailGetToKnow.module.css";

const CourseDetailGetToKnow = () => {
  // Context
  const { iseCourseDetail } = useContext(CourseContext);

  return (
    <div className={classes.container}>
      <SectionsHeader
        header={iseCourseDetail.data?.name}
        paragraph="GET TO KNOW"
        description={iseCourseDetail?.data?.description}
      />
    </div>
  );
};

export default CourseDetailGetToKnow;
