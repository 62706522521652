import { useContext, useEffect } from "react";
import Loader from "../../Components/Loader/Loader";
import { ReferralContext } from "../../Context/ReferralContext";
import ReferralFaqs from "../ReferralFaqs/ReferralFaqs";
import ReferralPendingInvites from "../ReferralPendingInvites/ReferralPendingInvites";
import ReferralSpreadTheWord from "../ReferralSpreadTheWord/ReferralSpreadTheWord";
import ReferralStatus from "../ReferralStatus/ReferralStatus";
import ReferralYourProgress from "../ReferralYourProgress/ReferralYourProgress";
import classes from "./Referral.module.css";

const Referral = () => {
  // Context
  const {
    getStudentReferrals,
    studentReferrals,
    referralLevels,
    getReferralLevels,
  } = useContext(ReferralContext);

  // Effect
  useEffect(() => {
    getStudentReferrals();

    if (!referralLevels?.data) {
      getReferralLevels();
    }

    // eslint-disable-next-line
  }, []);
  return (
    <section className={classes.container}>
      {studentReferrals.isLoading || referralLevels.isLoading ? (
        <Loader />
      ) : (
        <div className={classes.leftSection}>
          <ReferralStatus />
          {studentReferrals?.data?.filter((data: any) => {
            return data.status === "pending";
          }).length > 0 && <ReferralPendingInvites />}
          <ReferralYourProgress />
          <ReferralFaqs />
        </div>
      )}
      <div className={classes.rightSection}>
        <ReferralSpreadTheWord />
      </div>
    </section>
  );
};

export default Referral;
