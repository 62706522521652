import { useState } from "react";
import SectionsNav2 from "../../Components/SectionsNav2/Sectionsnav2";
import CourseGrades from "../CourseGrades/CourseGrades";
import CourseMaterials from "../CourseMaterials/CourseMaterials";
import CourseResources from "../CourseResources/CourseResources";
import classes from "./CourseDetailPageTab.module.css";

const CourseDetailPageTab = () => {
  // States
  const [navItems, setNavitems] = useState<any[]>([
    {
      title: "Course Materials",
      isActive: true,
      activeComponent: <CourseMaterials />,
    },
    {
      title: "Grades",
      isActive: false,
      activeComponent: <CourseGrades />,
    },
    {
      title: "Resourses",
      isActive: false,
      activeComponent: <CourseResources />,
    },
    {
      title: "Discussion Forum",
      isActive: false,
      activeComponent: null,
    },
  ]);

  //   Utils
  const activeComponent = navItems?.find((data) => {
    return data.isActive;
  });
  return (
    <section className={classes.container}>
      <SectionsNav2 navItems={navItems} setNavItems={setNavitems} />
      {activeComponent.activeComponent}
    </section>
  );
};

export default CourseDetailPageTab;
