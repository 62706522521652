import forgotPassword from "../../Assets/Images/forgotPassword.svg";
import OnboardingLayout from "../../Components/OnboardingLayout/OnboardingLayout";
import classes from "../LoginPage/LoginPage.module.css";
import Input from "../../Components/Input/Input";
import Button from "../../Components/Button/Button";
import iseLogo from "../../Assets/Images/iseLogo.svg";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { inputChangeHandler } from "../../HelperFunctions/inputChangeHandler";
import Error from "../../Components/Error/Error";

const ForgotPassword = () => {
  // Context
  const {
    resetPassword,
    resetPasswordRequest,
    userLoginInfo,
    setUserLoginInfo,
  } = useContext(AuthUserContext);

  return (
    <OnboardingLayout image={forgotPassword}>
      <section className={classes.container}>
        <img src={iseLogo} alt="Ise Logo" className={classes.logo} />
        <div className={classes.header}>
          <h4>Forgot password</h4>
          <p>
            Enter the email you used to sign up on iṣẹ́ EdTech and we'll send you
            password reset instructions.
          </p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {resetPasswordRequest.error && (
            <Error type="error">{resetPasswordRequest?.error}</Error>
          )}

          <Input
            label="Email Address"
            isRequired
            placeholder="Enter email address"
            type="email"
            onChange={(e) => {
              inputChangeHandler(e, setUserLoginInfo);
            }}
            value={userLoginInfo.email as string}
            name="email"
          />

          <div className={classes.buttonSection}>
            <Button
              onClick={() => {
                resetPassword();
              }}
              loading={resetPasswordRequest.isLoading}
            >
              Send instructions
            </Button>
          </div>
        </form>

        <div className={classes.alternate}>
          <Link to="/sign-in">Back to Log in</Link>
        </div>
      </section>
    </OnboardingLayout>
  );
};

export default ForgotPassword;
