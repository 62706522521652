import resetPassword from "../../Assets/Images/resetPassword.svg";
import OnboardingLayout from "../../Components/OnboardingLayout/OnboardingLayout";
import classes from "../LoginPage/LoginPage.module.css";
import Input from "../../Components/Input/Input";
import Button from "../../Components/Button/Button";
import iseLogo from "../../Assets/Images/iseLogo.svg";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import { useContext, useEffect } from "react";
import ApplicationSuccessfulBody from "./ApplicationSuccessfulBody";
import { AuthUserContext } from "../../Context/AuthUserContext";
import Error from "../../Components/Error/Error";
import { capitalize } from "../../HelperFunctions/capitalize";

const ResetPasswordPage = () => {
  // COntext
  const {
    resetPasswordObject,
    setResetPasswordObject,
    verifyResetPassword,
    resetPasswordRequest,
    setResetPasswordRequest,
  } = useContext(AuthUserContext);

  // Router
  const currentSearchParams = new URLSearchParams(window.location.search);
  const resetPasswordToken = currentSearchParams.get("passwordResetToken");

  // Utils
  const changeHandler = (e: any) => {
    setResetPasswordObject((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    setResetPasswordRequest((prevState) => {
      return { ...prevState, data: null };
    });

    // eslint-disable-next-line
  }, []);

  return (
    <OnboardingLayout image={resetPassword}>
      {resetPasswordRequest.data && (
        <AcceptedModal
          onClick={() => {
            setResetPasswordObject((prevState) => {
              return { ...prevState, data: null };
            });
          }}
          body={<ApplicationSuccessfulBody />}
        />
      )}
      <section className={classes.container}>
        <img src={iseLogo} alt="Ise Logo" className={classes.logo} />
        <div className={classes.feedback}>
          {resetPasswordRequest.data && (
            <Error type="success">
              {capitalize(resetPasswordRequest?.data as string)}
            </Error>
          )}

          {resetPasswordRequest.error && (
            <Error type="error">
              {capitalize(resetPasswordRequest?.error as string)}
            </Error>
          )}
        </div>

        <div className={classes.header}>
          <h4>Reset password</h4>
          <p>
            Enter a new password you’ll remember. We’ll ask for this password
            whenever you want to log in
          </p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input
            label="Enter new password"
            isRequired
            placeholder="Enter Password"
            tip="Passwords must be at least 8 characters "
            type="password"
            name="newPassword"
            value={resetPasswordObject.newPassword}
            onChange={changeHandler}
          />
          <span>
            <Input
              label="Confirm new password"
              isRequired
              type="password"
              placeholder="Enter Password"
              errorMessage="Passwords do not match"
              condition={
                resetPasswordObject.newPassword ===
                resetPasswordObject.confirmNewPassword
              }
              name="confirmNewPassword"
              value={resetPasswordObject.confirmNewPassword}
              onChange={changeHandler}
            />
          </span>

          <div className={classes.buttonSection}>
            <Button
              onClick={() => {
                if (
                  resetPasswordObject.newPassword ===
                    resetPasswordObject.confirmNewPassword &&
                  resetPasswordToken
                ) {
                  verifyResetPassword(resetPasswordToken as string);
                }
                // setDisplayModal(true);
              }}
              loading={resetPasswordRequest.isLoading}
            >
              Update password
            </Button>
          </div>
        </form>

        <div className={classes.alternate}>
          Need help? Contact our support team at support@iseafrica.com.{" "}
          <a href="mailto:support@iseafrica.com">support@iseafrica.com</a>
        </div>
      </section>
    </OnboardingLayout>
  );
};

export default ResetPasswordPage;
