import classes from "./ReferralPolicy.module.css";

const ReferralPolicy = () => {
  const referralPolicy = [
    {
      title: "Eligibility",
      paragraph:
        "The referral program is open to all active students of iṣẹ́ EdTech. You must be a current student with an active account on the iṣẹ́ platform to participate. To be considered valid, you will make referrals through the official referral channels provided by iṣẹ́ EdTech. Any referrals made outside of these channels will not be eligible for rewards.",
    },
    {
      title: "Referral Criteria",
      paragraph:
        "The invited person must be a new user not previously registered with iṣẹ́ EdTech to qualify as a referral. They should be interested in learning and improving their skills. Additionally, the invited person must successfully enrol in and complete a paid course on the iṣẹ́ EdTech platform for the referral to be considered valid. This means they must go through the entire enrollment process and complete the course they have enrolled in.",
    },
    {
      title: "Reward Levels",
      paragraph:
        "The referral program has three reward levels: Trailblazer, Pioneer, and Legend. These levels are unlocked by the number of successful referrals a participant makes. Each level comes with its own set of benefits and rewards. As you progress through the levels, you'll unlock more exciting rewards to enhance your learning experience and provide you with valuable resources and opportunities.",
    },
    {
      title: "Referral Rewards",
      paragraph:
        "Upon successful completion of the referral, we provide referral rewards. The rewards may include course discounts, access to exclusive content, merchandise, or other incentives. We communicate specific rewards for each level to the referrer on their dashboard. We value your contribution to our community and want to show appreciation by offering meaningful rewards that enhance your learning journey.",
    },
    {
      title: "Limitations",
      paragraph:
        "Referrals must comply with iṣẹ́ EdTech's community guidelines and policies. Referrers cannot refer themselves or create multiple accounts for the purpose of referral rewards. Additionally, referrals made to individuals who are already iṣẹ́ EdTech students or have previously enrolled in an iṣẹ́ EdTech course will not be considered valid. These limitations help maintain the program's integrity and ensure rewards are distributed to deserving participants.",
    },
    {
      title: "Modifications and Termination",
      paragraph:
        "iṣẹ́ EdTech reserves the right to modify or terminate the referral program without prior notice. While we don't anticipate any significant changes, we may need to adjust the program based on various factors. In the event of program termination, any pending referral rewards will no longer be valid. We will inform you of any significant changes or program terminations through official channels.",
    },
    {
      title: "General Terms",
      paragraph:
        "You can't transfer or exchange referral rewards for cash or any other form of compensation. The rewards are intended for personal use and should be used under iṣẹ́ EdTech policies. iṣẹ́ EdTech reserves the right to disqualify any participant or revoke referral rewards if fraudulent or unethical behaviour is suspected. We want to maintain a fair and inclusive environment for all participants. Additionally, while we strive to provide a seamless referral experience, iṣẹ́ EdTech is not responsible for any technical issues or failures that may prevent the referral program from running as intended. We will do our best to address any technical issues promptly.",
    },
  ];

  return (
    <section className={classes.container}>
      <h4>iṣẹ́ EdTech referral terms and conditions</h4>
      <p>
        Thank you for participating in the iṣẹ́ EdTech Referral Program. Please
        read the following terms and conditions carefully:
      </p>

      <ul>
        {referralPolicy.map((data, i) => {
          return (
            <li key={i}>
              <p>{data.title}</p>
              <p>{data.paragraph}</p>
            </li>
          );
        })}
      </ul>
      <p>
        Participating in the iṣẹ́ Referral Program acknowledges that you have
        read, understood, and agreed to these terms and conditions. We
        appreciate your support and look forward to a successful referral
        journey together.
      </p>
    </section>
  );
};

export default ReferralPolicy;
