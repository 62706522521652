import OnboardingLayout from "../../Components/OnboardingLayout/OnboardingLayout";
import classes from "../LoginPage/LoginPage.module.css";
import checkEmail from "../../Assets/Images/checkEmail.svg";
import iseLogo from "../../Assets/Images/iseLogo.svg";
import { Link } from "react-router-dom";

const ResetPasswordCheckEmail = () => {
  return (
    <OnboardingLayout image={checkEmail}>
      <section className={classes.container}>
        <img src={iseLogo} alt="Ise Logo" className={classes.logo} />
        <div className={classes.innerContainer}>
          <div className={classes.header}>
            <h4>Check your email</h4>
            <p>
              We just sent you a password reset mail, check your email inbox and
              click on the password reset link to reset your password
            </p>
          </div>

          <div className={classes.alternate}>
            Remember your password? <Link to="/sign-in">Back to login</Link>
          </div>
        </div>
      </section>
    </OnboardingLayout>
  );
};

export default ResetPasswordCheckEmail;
