import { useContext } from "react";
import { ReferralContext } from "../../Context/ReferralContext";
import classes from "./ReferralStatus.module.css";

const ReferralStatus = () => {
  // Context
  const { studentReferrals } = useContext(ReferralContext);

  return (
    <section className={classes.container}>
      <h4>Your Referral Status</h4>
      <p>
        See how many people you have referred to ise. You can also remind your
        friends.{" "}
      </p>

      <div className={classes.statCOntainer}>
        <div>
          <h4>
            {
              studentReferrals?.data?.filter(
                (data: any) => data?.status === "pending"
              ).length
            }
          </h4>
          <p>Pending invites</p>
        </div>

        <div>
          <h4>
            {
              studentReferrals?.data?.filter(
                (data: any) => data?.status === "completed"
              ).length
            }
          </h4>
          <p>Completed referral</p>
        </div>
      </div>
    </section>
  );
};

export default ReferralStatus;
