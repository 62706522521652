import classes from "../CourseListContainer/CourseListContainer.module.css";
import courseImage from "../../Assets/Images/courseImage.svg";
import { useContext } from "react";
import { CourseContext } from "../../Context/CourseContext";
import CourseCard from "../../Components/CourseCard/CourseCard";

const CoursesBasedOnSearch = () => {
  // Context
  const { coursesState } = useContext(CourseContext);

  return (
    <section className={classes.outerContainer}>
      <h4>Based on your recent searches</h4>

      <div className={classes.container}>
        {coursesState.slice(0, 3).map((data, i) => {
          return (
            <div key={i}>
              <CourseCard
                title={data.title}
                image={courseImage}
                description="Dive into the world of algorithms and predictive models to create intelligent systems that learn and adapt."
                ratings={4}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CoursesBasedOnSearch;
