import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthUserContext, requestType } from "./AuthUserContext";
import requestHandler from "../HelperFunctions/requestHandler";
import { setNotiticationFunction } from "../Utilities/setNotificationsFunction";
import { AppContext } from "./AppContext";
import { backend_url } from "../Utilities/global";

type SupportContextValuesProps = {
  supportForm: supportFormType;
  setSupportForm: Dispatch<SetStateAction<supportFormType>>;
  sendSupport: () => void;
  sendSupportObject: requestType;
  setSendSupportObject: Dispatch<SetStateAction<requestType>>;
};

type SupportContextProviderProps = {
  children: React.ReactNode;
};

type supportFormType = {
  subject: string;
  description: string;
  image: string;
};

export const SupportContext = createContext({} as SupportContextValuesProps);

const SupportContextProvider = ({ children }: SupportContextProviderProps) => {
  // COntext
  const { setNotifications } = useContext(AppContext);
  const { logout } = useContext(AuthUserContext);
  // States
  const [supportForm, setSupportForm] = useState<supportFormType>({
    subject: "",
    description: "",
    image: "",
  });
  const [sendSupportObject, setSendSupportObject] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  //   Formdata
  const supportFormdata = new FormData();

  // Effects
  useEffect(() => {
    supportFormdata.append("subject", supportForm.subject);
    supportFormdata.append("description", supportForm.description);
    supportFormdata.append("image", supportForm.image);

    // eslint-disable-next-line
  }, [supportForm]);

  //   Requests
  const sendSupport = () => {
    setSendSupportObject({
      isLoading: true,
      data: null,
      error: null,
    });

    requestHandler({
      method: "POST",
      url: `${backend_url}/api/ise/v1/issue/students`,
      data: supportFormdata,
      isMultipart: true,
    })
      .then((res) => {
        setSendSupportObject({
          isLoading: false,
          data: (res as any).data,
          error: null,
        });

        setNotiticationFunction(setNotifications, res?.data, "success");

        setSupportForm({
          subject: "",
          description: "",
          image: "",
        });
      })
      .catch((err) => {
        setSendSupportObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err?.request
            ? "There was an issue making this request"
            : err.message
        );

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  return (
    <SupportContext.Provider
      value={{
        supportForm,
        setSupportForm,
        sendSupport,
        setSendSupportObject,
        sendSupportObject,
      }}
    >
      {children}
    </SupportContext.Provider>
  );
};

export default SupportContextProvider;
