import { useContext, useEffect, useState } from "react";
import Loader from "../../Components/Loader/Loader";
import { AuthUserContext } from "../../Context/AuthUserContext";
import classes from "./ProfileBadges.module.css";

const ProfileBadges = () => {
  // Context
  const { userBadgesObject, getUserBadges } = useContext(AuthUserContext);

  // Effects

  useEffect(() => {
    getUserBadges();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userBadgesObject?.data?.length > 0) {
      const smallerArrayBadges = new Set(
        userBadgesObject?.data?.map((data: any) => data.badge)
      );

      const badgesCopy = badges?.map((data) => {
        return {
          ...data,
          earned: smallerArrayBadges?.has(data?.title.toLowerCase()),
        };
      });

      setBadges(badgesCopy as any[]);
    }

    // eslint-disable-next-line
  }, [userBadgesObject?.data]);

  // Utils
  const [badges, setBadges] = useState([
    {
      title: "Verified Profile",
      description: "Your iṣẹ́ EdTech profile is complete",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M9.49963 12.0006L11.4996 14.0006L15.4996 10.0006M8.33437 4.69766C9.05191 4.6404 9.73309 4.35824 10.281 3.89136C11.5594 2.80184 13.4398 2.80184 14.7183 3.89136C15.2662 4.35824 15.9474 4.6404 16.6649 4.69766C18.3393 4.83128 19.669 6.16092 19.8026 7.83535C19.8598 8.55288 20.142 9.23407 20.6089 9.78193C21.6984 11.0604 21.6984 12.9408 20.6089 14.2193C20.142 14.7672 19.8598 15.4483 19.8026 16.1659C19.669 17.8403 18.3393 19.1699 16.6649 19.3036C15.9474 19.3608 15.2662 19.643 14.7183 20.1099C13.4398 21.1994 11.5594 21.1994 10.281 20.1099C9.73309 19.643 9.05191 19.3608 8.33437 19.3036C6.65994 19.1699 5.3303 17.8403 5.19668 16.1659C5.13942 15.4483 4.85727 14.7672 4.39038 14.2193C3.30086 12.9408 3.30086 11.0604 4.39038 9.78193C4.85727 9.23407 5.13942 8.55288 5.19668 7.83535C5.3303 6.16092 6.65994 4.83128 8.33437 4.69766Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      earned: true,
    },
    {
      title: "Newbie",
      description: "You joined iṣẹ́ as a leaner",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <g clipPath="url(#clip0_9134_17525)">
            <path
              d="M11.549 2.92664C11.8483 2.00537 13.1517 2.00538 13.451 2.92664L14.9699 7.60055C15.1038 8.01254 15.4877 8.29148 15.9209 8.29149L20.8354 8.29168C21.8041 8.29172 22.2068 9.53127 21.4232 10.1007L17.4474 12.9895C17.0969 13.2441 16.9503 13.6955 17.0841 14.1075L18.6026 18.7815C18.9019 19.7028 17.8475 20.4689 17.0638 19.8995L13.0878 17.011C12.7373 16.7564 12.2627 16.7564 11.9122 17.011L7.93622 19.8995C7.15252 20.4689 6.0981 19.7028 6.3974 18.7815L7.91589 14.1075C8.04974 13.6955 7.90309 13.2441 7.55263 12.9895L3.57683 10.1007C2.79317 9.53127 3.19592 8.29172 4.16461 8.29168L9.07911 8.29149C9.51231 8.29148 9.89623 8.01254 10.0301 7.60055L11.549 2.92664Z"
              stroke="white"
              strokeWidth="2"
            />
          </g>
          <defs>
            <clipPath id="clip0_9134_17525">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      earned: false,
    },
    {
      title: "Weekend Warrior",
      description:
        "Complete a lesson on Saturday and Sunday to earn this badge. ",
      svg: null,
      earned: false,
    },
    {
      title: "Trailblazer",
      description: "Refer  2 friends successfully to earn this badge. ",
      svg: null,
      earned: false,
    },

    {
      title: "Community Champion",
      description: "Actively support your peers to earn this badge. ",
      svg: null,
      earned: false,
    },
    {
      title: "Completion Master",
      description: "Complete all iṣẹ́ EdTech assessments to earn this badge. ",
      svg: null,
      earned: false,
    },
    {
      title: "Proactive Learner",
      description: "Seek support from your tutor to earn this badge.",
      svg: null,
      earned: false,
    },
    {
      title: "Leaner of the month",
      description: "Score high on your assessments to earn this badge.",
      svg: null,
      earned: false,
    },
    {
      title: "Photogenic",
      description: "Add a profile picture to earn this badge. ",
      svg: null,
      earned: false,
    },
  ]);

  if (userBadgesObject.isLoading) {
    return <Loader />;
  }

  return (
    <section className={classes.container}>
      <div className={classes.section}>
        <h4>Earned Badges</h4>
        <p>
          For every milestone you unlock on iṣẹ́ EdTech, we’ll gift you a badge.{" "}
        </p>
        <div className={classes.badgesSection}>
          {badges
            .filter((data) => data?.earned)
            .map((data, i) => {
              return (
                <div className={classes.badge} key={i}>
                  <div>{data.svg}</div>
                  <p>{data.title}</p>
                  <p>{data.description}</p>
                </div>
              );
            })}
        </div>
      </div>

      <div className={classes.section}>
        <h4>Locked Badges</h4>
        <p>Unlock your potential and earn badges while you learn.</p>
        <div className={classes.badgesSection}>
          {badges
            .filter((data) => !data?.earned)
            .map((data, i) => {
              return (
                <div className={`${classes.badge} ${classes.locked}`} key={i}>
                  <div>
                    {data.svg ? (
                      data.svg
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <g opacity="0.38">
                          <path
                            d="M9.49963 12.0006L11.4996 14.0006L15.4996 10.0006M8.33437 4.69766C9.05191 4.6404 9.73309 4.35824 10.281 3.89136C11.5594 2.80184 13.4398 2.80184 14.7183 3.89136C15.2662 4.35824 15.9474 4.6404 16.6649 4.69766C18.3393 4.83128 19.669 6.16092 19.8026 7.83535C19.8598 8.55288 20.142 9.23407 20.6089 9.78193C21.6984 11.0604 21.6984 12.9408 20.6089 14.2193C20.142 14.7672 19.8598 15.4483 19.8026 16.1659C19.669 17.8403 18.3393 19.1699 16.6649 19.3036C15.9474 19.3608 15.2662 19.643 14.7183 20.1099C13.4398 21.1994 11.5594 21.1994 10.281 20.1099C9.73309 19.643 9.05191 19.3608 8.33437 19.3036C6.65994 19.1699 5.3303 17.8403 5.19668 16.1659C5.13942 15.4483 4.85727 14.7672 4.39038 14.2193C3.30086 12.9408 3.30086 11.0604 4.39038 9.78193C4.85727 9.23407 5.13942 8.55288 5.19668 7.83535C5.3303 6.16092 6.65994 4.83128 8.33437 4.69766Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    )}
                  </div>
                  <p>{data.title}</p>
                  <p>{data.description}</p>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default ProfileBadges;
