import { useContext, useEffect } from "react";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./RecommendedCourses.module.css";
import CourseCard from "../../Components/CourseCard/CourseCard";
import Loader from "../../Components/Loader/Loader";

const RecommendedCourses = () => {
  // context
  const { getRecommendedCourses, getRecommendedCoursesObject } =
    useContext(CourseContext);

  // Effects
  useEffect(() => {
    getRecommendedCourses();

    // eslint-disable-next-line
  }, []);

  if (getRecommendedCoursesObject.isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {getRecommendedCoursesObject?.data &&
        getRecommendedCoursesObject?.data
          ?.sort((a: any, b: any) => {
            return a?.position - b?.position;
          })
          .slice(0, 3)
          .map((data: any, i: number) => {
            return (
              <div key={i}>
                <CourseCard
                  title={data?.name}
                  image={data?.cover_image}
                  description={data?.description}
                  ratings={4}
                  id={data?.id}
                  price={data?.price}
                />
              </div>
            );
          })}
    </div>
  );
};

export default RecommendedCourses;
