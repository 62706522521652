import HelloWithSearch from "../../Components/HelloWithSearch/HelloWithSearch";
import CourseListContainer from "../CourseListContainer/CourseListContainer";
import CoursesBasedOnSearch from "../CoursesBasedOnSearch/CoursesBasedOnSearch";
import CoursesFilter from "../CoursesFilter/CoursesFilter";
import classes from "./NoCourseSelectedCourses.module.css";

type NoCourseSelectedCoursesProps = {
  showSearchSuggestions?: boolean;
};

const NoCourseSelectedCourses = ({
  showSearchSuggestions,
}: NoCourseSelectedCoursesProps) => {
  return (
    <section className={classes.container}>
      <HelloWithSearch />
      <CoursesFilter />
      <CourseListContainer />
      {showSearchSuggestions && <CoursesBasedOnSearch />}
    </section>
  );
};

export default NoCourseSelectedCourses;
