import { CSSProperties } from "react";
import classes from "./SectionsHeader.module.css";

type SectionsHeaderProps = {
  paragraph?: string;
  header: string;
  description?: string;
  style?: CSSProperties;
  headerIsBlack?: boolean;
};

const SectionsHeader = ({
  header,
  paragraph,
  description,
  style,
  headerIsBlack,
}: SectionsHeaderProps) => {
  return (
    <div className={classes.container} style={style}>
      <p style={headerIsBlack ? { color: "#2e2e2e" } : undefined}>
        {paragraph}
      </p>
      <h4>{header}</h4>
      {description && <p>{description}</p>}
    </div>
  );
};

export default SectionsHeader;
