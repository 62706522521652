import { useContext } from "react";
import Button from "../../Components/Button/Button";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import { AppContext } from "../../Context/AppContext";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./CourseDetailPageSpeakWithTutor.module.css";
import ReportAnIssueContainer from "./ReportAnIssueContainer";

const CourseDetailPageSpeakWithTutor = () => {
  // Context
  const { screenWidthState } = useContext(AppContext);
  const { displayReportModal, setDisplayReportModal, getCourseModulesObject } =
    useContext(CourseContext);

  // State
  return (
    <section className={classes.container}>
      <h4>Having any difficulty?</h4>
      <p>
        No matter what you might be passing through, we are here for you. Look
        at some of the features we have to below to help you overcome any
        difficulty you have
      </p>

      <a
        href={`mailto:${
          getCourseModulesObject?.data &&
          getCourseModulesObject?.data[0]?.course?.cohorts[0]?.tutor?.email
        }`}
      >
        <Button
          disabled={
            getCourseModulesObject.data &&
            getCourseModulesObject?.data?.length < 1
          }
        >
          Speak with tutor
        </Button>
      </a>
      <Button
        type="null"
        onClick={() => {
          setDisplayReportModal(true);
        }}
      >
        Report an issue
      </Button>

      {displayReportModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayReportModal(false);
          }}
          style={
            screenWidthState < 767
              ? {
                  minHeight: "100%",
                }
              : { minHeight: "833px" }
          }
          body={
            <ReportAnIssueContainer
              onClick={() => {
                setDisplayReportModal(false);
              }}
            />
          }
        />
      )}
    </section>
  );
};

export default CourseDetailPageSpeakWithTutor;
