import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import CoursesCarousel from "../../Components/CoursesCarousel/CoursesCarousel";
import HelloUser from "../../Components/HelloUser/HelloUser";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./EligibleuserDashboard.module.css";

const EligibleuserDashboard = () => {
  // Context
  const {
    userEnrolledCOursesCount,
    getRecommendedCourses,
    getRecommendedCoursesObject,
  } = useContext(CourseContext);

  // Router
  const navigate = useNavigate();
  return (
    <section className={classes.container}>
      <HelloUser
        payRoute={`/payment/${userEnrolledCOursesCount?.data?.lastEnrolledCourseUnpaid?.course?.id}`}
      />
      <div>
        {/* <CoursesCarousel
          header="Eligible courses"
          description="Select a course you qualify for and make payment when you are ready."
          data={courses}
        /> */}
      </div>

      <div>
        <CoursesCarousel
          header="Recommended courses"
          description="We've matched these courses to your profile."
          data={getRecommendedCoursesObject?.data}
          callFunction={getRecommendedCourses}
          loading={getRecommendedCoursesObject?.isLoading}
        />
      </div>
      <div className={classes.dontMissOut}>
        <div>
          <h4>Don’t miss out </h4>
          <p>
            {`It’s time to go big! Get started with the ${
              userEnrolledCOursesCount?.data?.lastEnrolledCourseUnpaid?.course
                ?.name || ""
            } course.`}
          </p>
        </div>
        <Button
          type="tertiary"
          onClick={() => {
            navigate(
              `/payment/${userEnrolledCOursesCount?.data?.lastEnrolledCourseUnpaid?.course?.id}`
            );
          }}
        >
          Pay now
        </Button>
      </div>
      <div>
        {/* <CoursesCarousel
          header="Based on your recent search"
          description="These courses might interest you."
          data={courses}
        /> */}
      </div>
    </section>
  );
};

export default EligibleuserDashboard;
