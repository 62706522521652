import DropdownWithSearch from "../../Components/DropdownWithSearch/DropdownWithSearch";
import classes from "./PaymentChoice.module.css";
import { useContext, useEffect } from "react";
import Button from "../../Components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { PaymentContext } from "../../Context/PaymentContext";
import {
  formatAmountWithCommas2dp,
  removeCommasFromAmount,
} from "../../HelperFunctions/amountToString";
import { CourseContext } from "../../Context/CourseContext";

const PaymentChoice = () => {
  // Context
  const {
    baseCurrency,
    setBaseCurrency,
    exchangerates,
    paymentPlans,
    getCurrentExchangeRate,
  } = useContext(PaymentContext);
  const { getCoursesById } = useContext(CourseContext);

  // Router
  const navigate = useNavigate();
  const { courseId } = useParams();

  // Effects
  useEffect(() => {
    getCoursesById(courseId as string);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (baseCurrency) {
      getCurrentExchangeRate();
    }

    // eslint-disable-next-line
  }, [baseCurrency]);

  return (
    <section className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.header}>
          <h4>Choose your payment plan</h4>
          <div>
            <p>Pay in</p>
            <span>
              <DropdownWithSearch
                options={exchangerates.error ? ["NGN"] : ["NGN", "USD"]}
                title="NGN"
                selected={baseCurrency}
                setSelected={setBaseCurrency}
              />
            </span>
          </div>
        </div>

        <div className={classes.bodyMain}>
          {paymentPlans.map((data, i) => {
            return (
              <div key={i} className={classes.plan}>
                <div className={classes.upperSection}>
                  <div>
                    <p>{data.title}</p>
                    <h4>
                      <span>
                        {baseCurrency && baseCurrency.toUpperCase() === "USD"
                          ? "$"
                          : "₦"}
                      </span>
                      {exchangerates?.error
                        ? formatAmountWithCommas2dp(data?.price || "")
                        : exchangerates?.data && !exchangerates.isLoading
                        ? formatAmountWithCommas2dp(
                            Number(
                              removeCommasFromAmount(String(data?.price))
                            ) * Number(exchangerates?.data)
                          )
                        : exchangerates.isLoading && !exchangerates.data
                        ? "--.--"
                        : data.price}
                    </h4>
                  </div>
                  <img src={data.image} alt={data.title} />
                </div>
                <div className={classes.advantages}>
                  {data.advantages.map((datum, j) => {
                    return (
                      <div key={j}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <path
                            d="M5.5 13L9.5 17L19.5 7"
                            stroke={i === 0 ? "#fff" : "#2E2E2E"}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span>{datum}</span>
                      </div>
                    );
                  })}
                </div>
                <div className={classes.buttonSection}>
                  <Button
                    type={i === 0 ? "tertiary" : "primary"}
                    onClick={() => {
                      navigate(
                        `/payment/${courseId}/${data.title
                          .replace(" ", "-")
                          .toLowerCase()}`
                      );
                    }}
                    disabled={exchangerates.isLoading}
                  >
                    Select payment plan
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PaymentChoice;
