import { useContext } from "react";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import { CourseContext } from "../../Context/CourseContext";
import { formatAmountWithCommas2dp } from "../../HelperFunctions/amountToString";
import classes from "./CourseDetailWhatYouNeed.module.css";

const CourseDetailWhatYouNeed = () => {
  // Context
  const { iseCourseDetail } = useContext(CourseContext);

  return (
    <section className={classes.container}>
      <div className={classes.bodyMain}>
        <SectionsHeader
          header="Choose your preferred plan"
          paragraph="WE’VE GOT YOU COVERED"
          headerIsBlack={false}
          style={{ textAlign: "center" }}
        />
        <div className={classes.paymentDetailContainer}>
          <div className={classes.paymentCard}>
            <div>
              <p>Full payment</p>
              <p>Pay once and secure course </p>
            </div>
            <div>
              <p>
                <span>&#8358;</span>
                {formatAmountWithCommas2dp(
                  iseCourseDetail?.data?.cohort?.price
                )}
              </p>
              <p>
                Get easy access to course materials, tutors and learning
                resources at once.{" "}
              </p>
            </div>
          </div>

          <div className={classes.paymentCard}>
            <div>
              <p>Instalment payment</p>
              <p>Pay 70% upfront and pay the rest later</p>
            </div>
            <div>
              <p>
                <span>&#8358;</span>
                {formatAmountWithCommas2dp(
                  iseCourseDetail?.data?.cohort?.price * 0.7
                )}
              </p>
              <p>Pay gradually for access to course materials and resources.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseDetailWhatYouNeed;
