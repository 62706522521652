import React, { useContext, useEffect } from "react";
import CourseCarousel2 from "../../Components/CourseCarousel2/CourseCarousel2";
import HelloUser from "../../Components/HelloUser/HelloUser";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import { AppContext } from "../../Context/AppContext";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { CourseContext } from "../../Context/CourseContext";
import { courses } from "../../Utilities/courses";
import CourseProgress from "../CourseProgress/CourseProgress";
import DashboardEarnedBadges from "../DashboardEarnedBadges/DashboardEarnedBadges";
import DashboardOneEnrolled from "../DashboardOneEnrolled/DashboardOneEnrolled";
import GetStartedVideoContainer from "../GetStartedVideoContainer/GetStartedVideoContainer";
import classes from "./DashboardMain.module.css";

const DashboardMain = () => {
  const { showGetStarted } = useContext(AppContext);
  const { getEnrolledCourses, userEnrolledCOursesCount } =
    useContext(CourseContext);
  const { user, getUserBadges } = useContext(AuthUserContext);

  useEffect(() => {
    getEnrolledCourses(true);
    getUserBadges();

    // eslint-disable-next-line
  }, []);

  return (
    <section className={classes.container}>
      {showGetStarted.showModdal && (
        <AcceptedModal
          body={
            <GetStartedVideoContainer
              style={{ padding: "1.5rem" }}
              videoHeight="380px"
            />
          }
        />
      )}
      <HelloUser
        notIncludePay
        notIncludeBg
        text="Let’s pick up where you left off"
        header={`Welcome back, ${
          user?.data?.full_name?.split(" ")[0] || "user"
        }`}
      />
      {userEnrolledCOursesCount?.data?.paidEnrolledCourse?.length === 1 ? (
        userEnrolledCOursesCount?.data?.paidEnrolledCourse?.map(
          (data: any, i: number) => {
            return (
              <React.Fragment key={i}>
                <DashboardOneEnrolled
                  title={data?.course?.name}
                  image={data?.course?.cover_image}
                  cohortStart={data?.cohort?.start_date}
                  id={data?.course?.id}
                />
              </React.Fragment>
            );
          }
        )
      ) : (
        <CourseCarousel2 data={courses} header="Your courses" />
      )}

      {showGetStarted?.dashboard && (
        <GetStartedVideoContainer videoHeight="480px" />
      )}

      <CourseProgress />
      {/* <DashboardModules /> */}
      {/* <DashboardResources /> */}
      <DashboardEarnedBadges />
    </section>
  );
};

export default DashboardMain;
