import VideoPlayer from "../../Components/VideoPlayer/VideoPlayer";
import classes from "./CourseLessonVideo.module.css";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppContext } from "../../Context/AppContext";
import { CourseContext } from "../../Context/CourseContext";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";

type CourseLessonVideoProps = {
  activeModuleWeeks: any;
  allRecords: any;
  activeRecordIndex: any;
  setActiveRecordIndex: Dispatch<SetStateAction<any>>;
};

const CourseLessonVideo = ({
  activeModuleWeeks,
  allRecords,
  activeRecordIndex,
  setActiveRecordIndex,
}: CourseLessonVideoProps) => {
  // Context
  const { screenWidthState } = useContext(AppContext);
  const {
    setEntityProgress,
    setVideoProgressObject,
    setEntityProgressObject,
    getCourseModules,
    setBookmarksHandler,
    setBookmark,
    deleteBookmarks,
  } = useContext(CourseContext);

  const video: HTMLVideoElement | null = document.getElementById(
    "video"
  ) as HTMLVideoElement;

  // States
  const [ranCOmplete, setRanComplete] = useState(
    video?.currentTime === video?.duration || false
  );

  // Router
  const currentParams = new URLSearchParams(window.location.search);
  const activeContentId = currentParams?.get("activeContentId");
  const activeWeekId = currentParams?.get("activeWeek");
  const { courseId } = useParams();
  const activeModuleId = currentParams?.get("activeModule");
  const activeCohort = currentParams?.get("activeCohort");

  const [isInvalid, setIsInvalid] = useState(true);
  const [activeContent, setActiveContent] = useState<any>();

  useEffect(() => {
    if (activeModuleWeeks) {
      setActiveContent(
        activeModuleWeeks
          .find((data: any) => {
            return data?.id === Number(activeWeekId);
          })
          ?.records?.find((data: any) => {
            return data?.title === activeContentId?.toString();
          })
      );

      if (activeContent?.type !== "video") {
        setIsInvalid(true);
      } else {
        setIsInvalid(false);
      }
    }

    // eslint-disable-next-line
  }, [activeModuleWeeks, activeWeekId, activeContentId]);

  const [videoIsPlayed, setVideoIsPlayed] = useState<boolean>(false);

  useEffect(() => {
    if (video && video?.duration > 0 && videoIsPlayed) {
      const handlePlayPause = () => {
        if (ranCOmplete) {
          setVideoProgressObject({
            watching_hours: Math.ceil(video?.duration),
            is_completed: true,
            resume_time: Math.ceil(video.duration),
          });

          setEntityProgress(
            activeContent?.id,
            courseId as string,
            activeModuleId as string,
            activeWeekId as string,
            "video",
            false
          );
        } else {
          setVideoProgressObject((prevState) => {
            return {
              ...prevState,
              watching_hours: Math.ceil(video?.duration),
              is_completed: setEntityProgressObject.data?.is_completed || false,
              resume_time: Math.ceil(video.currentTime),
            };
          });
        }
      };

      video.addEventListener("play", () => {
        handlePlayPause();
      });
      video.addEventListener("pause", () => {
        handlePlayPause();
      });

      const videoProgressInterval = setInterval(() => {
        setEntityProgress(
          activeContent?.id,
          courseId as string,
          activeModuleId as string,
          activeWeekId as string,
          "video",
          false
        );
      }, 5000);

      return () => {
        video.removeEventListener("play", handlePlayPause);
        video.removeEventListener("pause", handlePlayPause);

        clearInterval(videoProgressInterval as NodeJS.Timer);
      };
    }

    // eslint-disable-next-line
  }, [videoIsPlayed, ranCOmplete]);

  useEffect(() => {
    if (ranCOmplete) {
      getCourseModules(courseId as string, activeCohort as string, false, true);
    }

    // eslint-disable-next-line
  }, [ranCOmplete]);

  useEffect(() => {
    if (!setBookmark?.isLoading && setBookmark?.data) {
      getCourseModules(courseId as string, activeCohort as string, false, true);
    }

    // eslint-disable-next-line
  }, [setBookmark]);

  if (isInvalid) {
    <section>Invalid content type</section>;
  }

  return (
    <section className={classes.container}>
      <div className={classes.lessonNav}>
        <div
          style={
            allRecords[activeRecordIndex - 1]
              ? { color: "#2E2E2E", pointerEvents: "auto" }
              : { pointerEvents: "none", color: "#B9B9B9" }
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 19L8 12L15 5"
              stroke={allRecords[activeRecordIndex - 1] ? "#2E2E2E" : "#B9B9B9"}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span
            onClick={() => {
              setActiveRecordIndex((prevState: any) => {
                return prevState - 1;
              });
            }}
          >
            Previous
          </span>
        </div>
        <div
          style={
            allRecords[activeRecordIndex + 1]
              ? { color: "#2E2E2E", pointerEvents: "auto" }
              : { pointerEvents: "none", color: "#B9B9B9" }
          }
        >
          <span
            onClick={() => {
              setActiveRecordIndex((prevState: any) => {
                return prevState + 1;
              });
            }}
          >
            Next
          </span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 5L16 12L9 19"
              stroke={allRecords[activeRecordIndex + 1] ? "#2E2E2E" : "#B9B9B9"}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className={classes.video}>
        <VideoPlayer
          height={screenWidthState < 767 ? "210px" : "511px"}
          url={activeContent?.video_url || "/"}
          thumbnail={activeContent?.thumbnail}
          onPlay={() => {
            setVideoIsPlayed(true);
          }}
          onPause={() => {
            setVideoIsPlayed(false);
          }}
        />
      </div>

      <div className={classes.bookmark}>
        <div
          onClick={() => {
            if (activeContent?.bookmarks) {
              deleteBookmarks(activeContent?.bookmarks?.id);
            } else {
              setBookmarksHandler(
                activeContent?.id,
                "video",
                courseId as string,
                activeModuleId as string,
                activeWeekId as string
              );
            }
          }}
        >
          {setBookmark.isLoading ? (
            <CircularProgress
              size={"1rem"}
              color="inherit"
              style={{ color: "inherit" }}
            />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill={activeContent?.bookmarks ? "#011627" : "none"}
            >
              <path
                d="M5 5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L12 17.5L5 21V5Z"
                stroke="#011627"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
        <div
          className={
            (activeContent as any)?.video_progress?.is_completed
              ? classes.activeCompleted
              : classes.nonCompleted
          }
          onClick={() => {
            setRanComplete(true);

            if (
              (activeContent as any)?.video_progress &&
              !(activeContent as any)?.video_progress?.is_completed
            ) {
              setVideoProgressObject((prevState) => {
                return {
                  ...prevState,
                  watching_hours: Math.ceil(video?.duration || 0),
                  is_completed: true,
                  resume_time: Math.ceil(video.duration || 0),
                };
              });

              setEntityProgress(
                activeContent?.id,
                courseId as string,
                activeModuleId as string,
                activeWeekId as string,
                "video",
                true
              );
            }
          }}
        >
          {setEntityProgressObject.isLoading ? (
            <CircularProgress
              size={"1rem"}
              color="inherit"
              style={{ color: "inherit" }}
            />
          ) : (activeContent as any)?.video_progress?.is_completed ? (
            "Completed"
          ) : (
            "Mark as completed"
          )}
        </div>
      </div>

      <div className={classes.paragraphSection}>
        {/* {paragraphs.map((data, i) => {
          return <p key={i}>{data}</p>;
        })} */}
      </div>
    </section>
  );
};

export default CourseLessonVideo;
