import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import requestHandler from "../HelperFunctions/requestHandler";
import { setNotiticationFunction } from "../Utilities/setNotificationsFunction";
import { AppContext } from "./AppContext";
import { AuthUserContext, requestType } from "./AuthUserContext";
import { backend_url } from "../Utilities/global";

type ReferralContextProviderTypes = {
  children: React.ReactNode;
};

type ReferralContextValueTypes = {
  getReferralLevels: () => void;
  referralLevels: requestType;
  getStudentReferrals: () => void;
  studentReferrals: requestType;
  getStudentReferralLink: () => void;
  studentReferralLink: requestType;
  referralLinkReceiver: string;
  setReferralLinkReceiver: Dispatch<SetStateAction<string>>;
  sendstudentReferralLinkObject: requestType;
  sendStudentReferralLink: () => void;
};

export const ReferralContext = createContext({} as ReferralContextValueTypes);

const ReferralContextProvider = ({
  children,
}: ReferralContextProviderTypes) => {
  // COntext
  const { setNotifications } = useContext(AppContext);
  const { logout } = useContext(AuthUserContext);

  // States
  const [referralLevels, setReferralLevels] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [studentReferrals, setStudentReferrals] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  const [studentReferralLink, setStudentReferralLink] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [referralLinkReceiver, setReferralLinkReceiver] = useState<string>("");

  const [sendstudentReferralLinkObject, setSendstudentReferralLinkObject] =
    useState<requestType>({
      isLoading: false,
      data: null,
      error: null,
    });

  //   Requests
  const getReferralLevels = () => {
    if (referralLevels.isLoading) {
      return;
    }

    setReferralLevels({
      isLoading: true,
      data: null,
      error: null,
    });

    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/referral-levels`,
    })
      .then((res) => {
        setReferralLevels({
          isLoading: false,
          data: (res as any).data,
          error: null,
        });
      })
      .catch((err) => {
        setReferralLevels({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const getStudentReferrals = () => {
    if (studentReferrals.isLoading) {
      return;
    }

    setStudentReferrals({
      isLoading: true,
      data: null,
      error: null,
    });

    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/student-referral`,
    })
      .then((res) => {
        setStudentReferrals({
          isLoading: false,
          data: (res as any).data,
          error: null,
        });
      })
      .catch((err) => {
        setStudentReferrals({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const getStudentReferralLink = () => {
    if (studentReferralLink.isLoading) {
      return;
    }

    setStudentReferralLink({
      isLoading: true,
      data: null,
      error: null,
    });

    requestHandler({
      method: "GET",
      url: `${backend_url}/api/ise/v1/student-referral/referral-link`,
    })
      .then((res) => {
        setStudentReferralLink({
          isLoading: false,
          data: (res as any).data,
          error: null,
        });
      })
      .catch((err) => {
        setStudentReferralLink({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err?.request
            ? "There was an issue making this request"
            : err.message
        );

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const sendStudentReferralLink = () => {
    if (referralLinkReceiver) {
      setSendstudentReferralLinkObject({
        isLoading: true,
        data: null,
        error: null,
      });

      requestHandler({
        method: "POST",
        url: `${backend_url}/api/ise/v1/student-referral/referral-link`,
        data: { email: referralLinkReceiver },
      })
        .then((res) => {
          setSendstudentReferralLinkObject({
            isLoading: false,
            data: "Referral link sent sucessfully!",
            error: null,
          });

          setNotiticationFunction(
            setNotifications,
            "Referral link sent sucessfully!",
            "success"
          );
        })
        .catch((err) => {
          setSendstudentReferralLinkObject({
            isLoading: false,
            data: null,
            error: err.response?.data?.error
              ? err.response?.data?.error?.responseMessage
              : !err.response?.data?.error
              ? err.response?.data?.responseMessage.toString()
              : err.message,
          });

          setNotiticationFunction(
            setNotifications,
            err.response?.data?.error
              ? err.response?.data?.error?.responseMessage
              : !err.response?.data?.error
              ? err.response?.data?.responseMessage.toString()
              : err?.request
              ? "There was an issue making this request"
              : err.message
          );

          if (err?.response?.data?.error?.responseMessage === "Expired Token") {
            logout();
          }
        });
    }
  };

  return (
    <ReferralContext.Provider
      value={{
        getReferralLevels,
        referralLevels,
        getStudentReferrals,
        studentReferrals,
        getStudentReferralLink,
        studentReferralLink,
        referralLinkReceiver,
        setReferralLinkReceiver,
        sendstudentReferralLinkObject,
        sendStudentReferralLink,
      }}
    >
      {children}
    </ReferralContext.Provider>
  );
};

export default ReferralContextProvider;
