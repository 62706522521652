import { useContext, useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import DropdownWithSearch from "../../Components/DropdownWithSearch/DropdownWithSearch";
import Input from "../../Components/Input/Input";
import ProfileSectionContainer from "../../Components/ProfileSectionContainer/ProfileSectionContainer";
import TextArea from "../../Components/TextArea/TextArea";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { changeHandler } from "../../Utilities/inputChangeHandler";
import classes from "./ProfileProfileAdditionalDetails.module.css";

const ProfileProfileAdditionalDetails = () => {
  // Context
  const {
    updateTopSkillsHandlerObject,
    updateSTopkillsHandler,
    topSkillsUpdate,
    setTopSkillsUpdate,
    countriesRequestObject,
    user,
  } = useContext(AuthUserContext);

  // States
  const [countryCode, setCountryCode] = useState("");
  const [countryCodeList, setCOuntryCodeList] = useState([]);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const date = new Date();
  const days = [];
  const months = [];
  const startingYear = 1980;
  let endingYear = Number(date.getFullYear());
  const years = [];

  for (let i = 1; i <= 31; i++) {
    if (i < 10) {
      days.push(`0${i}`);
    } else {
      days.push(String(i));
    }
  }

  for (let i = 1; i <= 15; i++) {
    if (i < 10) {
      months.push(`0${i}`);
    } else {
      months.push(String(i));
    }
  }

  for (let i = 0; i <= endingYear - startingYear; i++) {
    years.push(String(i + startingYear));
  }

  // Effects
  useEffect(() => {
    if (user?.data) {
      setTopSkillsUpdate((prevState) => {
        return {
          ...prevState,
          linkedIn_profile: user?.data?.linkedIn_profile,
          dob: user?.data?.dob,
          bio: user?.data?.bio,
          phone: user?.data?.phone,
        };
      });

      setPhoneNumber(user?.data?.phone?.split(" ")[1]);
      setCountryCode(`${user?.data?.phone?.split(" ")[0]}`);
      setDay(user?.data?.dob?.split("-")[2]);
      setMonth(user?.data?.dob?.split("-")[1]);
      setYear(user?.data?.dob?.split("-")[0]);
    }

    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (countriesRequestObject?.data) {
      const countruyCodesCopy = countriesRequestObject?.data?.map(
        (data: any) => {
          return `${data?.flag}${data?.idd?.root}${data?.idd?.suffixes[0]}`;
        }
      );

      setCOuntryCodeList(countruyCodesCopy);
    }
  }, [countriesRequestObject.data]);

  useEffect(() => {
    if (countryCode || phoneNumber) {
      setTopSkillsUpdate((prevState) => {
        return {
          ...prevState,
          phone: `${countryCode} ${phoneNumber}`,
        };
      });
    }

    // eslint-disable-next-line
  }, [countryCode, phoneNumber]);

  useEffect(() => {
    if (day || month || year) {
      setTopSkillsUpdate((prevState) => {
        return {
          ...prevState,
          dob: `${month}-${day}-${year}`,
        };
      });
    }

    // eslint-disable-next-line
  }, [day, month, year]);

  useEffect(() => {
    if (user?.data) {
      if (topSkillsUpdate?.linkedIn_profile !== user?.data?.linkedIn_profile) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }
    }

    // eslint-disable-next-line
  }, [topSkillsUpdate?.linkedIn_profile]);

  useEffect(() => {
    if (user?.data) {
      if (user?.data?.bio !== topSkillsUpdate?.bio) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }
    }

    // eslint-disable-next-line
  }, [topSkillsUpdate?.bio]);

  useEffect(() => {
    if (user?.data) {
      if (user?.data?.phone !== topSkillsUpdate?.phone) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }
    }

    // eslint-disable-next-line
  }, [phoneNumber, countryCode, topSkillsUpdate?.phone]);

  useEffect(() => {
    if (user?.data) {
      if (user?.data?.dob?.split("-")[2] !== day) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }

      if (user?.data?.dob?.split("-")[1] !== month) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }

      if (user?.data?.dob?.split("-")[0] !== year) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }
    }

    // eslint-disable-next-line
  }, [day, month, year]);

  return (
    <ProfileSectionContainer
      header="Top skills"
      paragraph=" Let’s be friends. Share more information with us if you’d like."
    >
      <div className={classes.container}>
        <Input
          label="Linkedin profile"
          placeholder="Enter profile link"
          isRequired
          name="linkedIn_profile"
          onChange={(e) => {
            changeHandler(e, setTopSkillsUpdate);
          }}
          value={topSkillsUpdate?.linkedIn_profile}
        />
        <TextArea
          label="Bio"
          placeholder="Tell us about you"
          isRequired
          name="bio"
          onChange={(e) => {
            changeHandler(e, setTopSkillsUpdate);
          }}
          value={topSkillsUpdate?.bio}
        />

        <div className={classes.flex}>
          <DropdownWithSearch
            label="Phone number"
            title="NGA"
            options={countryCodeList}
            selected={countryCode}
            setSelected={setCountryCode}
          />
          <Input
            name="phone"
            onChange={(e) => {
              // setTopSkillsUpdate((prevState) => {
              //   return {
              //     ...prevState,
              //     phone: `${countryCode} ${e.target.value}`,
              //   };
              // });

              setPhoneNumber(e.target.value);
            }}
            value={phoneNumber}
          />
        </div>

        <div className={classes.flex2}>
          <DropdownWithSearch
            label="Birthday"
            title="Birthday"
            options={days}
            selected={day}
            setSelected={setDay}
          />
          <DropdownWithSearch
            label="Month"
            title="Month"
            options={months}
            selected={month}
            setSelected={setMonth}
          />
          <DropdownWithSearch
            label="Year"
            title="Year"
            options={years}
            selected={year}
            setSelected={setYear}
          />
        </div>
        <p className={classes.birthdayTip}>
          {" "}
          We’d like to celebrate your birthday with you
        </p>
      </div>

      {isEditing && (
        <div className={classes.buttonSection}>
          <Button
            loading={updateTopSkillsHandlerObject?.isLoading}
            onClick={updateSTopkillsHandler}
          >
            Update
          </Button>
        </div>
      )}
    </ProfileSectionContainer>
  );
};

export default ProfileProfileAdditionalDetails;
