import { useContext, useEffect, useState } from "react";
import HelloWithSearch from "../../Components/HelloWithSearch/HelloWithSearch";
import { CourseContext } from "../../Context/CourseContext";
import ActiveUserCoursesRecommendedAndSavedContainer from "../ActiveUserCoursesRecommendedAndSavedContainer/ActiveUserCoursesRecommendedAndSavedContainer";
import CourseListContainer from "../CourseListContainer/CourseListContainer";
import CourseProgressContainer from "../CourseProgressContainer/CourseProgressContainer";
import CoursesFilter from "../CoursesFilter/CoursesFilter";
import classes from "./ActiveUserCourses.module.css";

const ActiveUserCourses = () => {
  // Context
  const {
    getEnrolledCourses,
    getEnrolledCoursesObject,
    getSavedCoursesObject,
    getSavedCourses,
  } = useContext(CourseContext);

  // States
  const [displayCourseList, setDisplayCourseList] = useState(false);

  // Effects
  useEffect(() => {
    if (!getEnrolledCoursesObject?.data) {
      getEnrolledCourses(true);
    } else {
      getEnrolledCourses(false);
    }

    if (!getSavedCoursesObject?.data) {
      getSavedCourses(true);
    } else {
      getSavedCourses(false);
    }

    // eslint-disable-next-line
  }, []);
  return (
    <section className={classes.container}>
      <HelloWithSearch setDisplayCourseList={setDisplayCourseList} />
      {!displayCourseList ? (
        <>
          <CourseProgressContainer />
          <ActiveUserCoursesRecommendedAndSavedContainer />
        </>
      ) : (
        <>
          <CoursesFilter />
          <CourseListContainer />
        </>
      )}
    </section>
  );
};

export default ActiveUserCourses;
