import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./ReferAFriendRightCta.module.css";

const ReferAFriendRightCta = () => {
  // Navigate
  const navigate = useNavigate();

  // Context
  const { userEnrolledCOursesCount } = useContext(CourseContext);

  return (
    <section className={classes.container}>
      <h4>Refer a Friend to join ise School</h4>
      <p>
        Let's empower more individuals to embrace innovation and learning. Refer
        now and make a positive impact.
      </p>
      <Button
        onClick={() => {
          navigate("/referral");
        }}
        disabled={
          userEnrolledCOursesCount?.data?.paidEnrolledCourseCount <= 0 || false
        }
      >
        Refer a Friend
      </Button>
    </section>
  );
};

export default ReferAFriendRightCta;
