import classes from "./CourseInteractionLayoutMobileHeader.module.css";
import CourseInteractionLayoutProgressContainer from "../CourseInteractionLayoutProgressContainer/CourseInteractionLayoutProgressContainer";
import CourseInteractionWeekLessons from "../CourseInteractionWeekLessons/CourseInteractionWeekLessons";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CourseInteractionLayoutMobileHeader = () => {
  // Ref
  const sideNav = useRef<HTMLDivElement>(null);

  // Utils
  const openSideNav = () => {
    if (sideNav.current) sideNav.current.style.width = "100%";
  };

  const closeSideNav = () => {
    if (sideNav.current) sideNav.current.style.width = "0%";
  };

  const navigate = useNavigate();
  const { courseId } = useParams();

  return (
    <div className={classes.container}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        onClick={openSideNav}
      >
        <path
          d="M4 6H20M4 12H20M4 18H20"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <div className={classes.sideNav} ref={sideNav}>
        <div className={classes.sideNavHeader}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            onClick={() => {
              navigate(`/courses/${courseId}`);
            }}
          >
            <path
              d="M12.5 15.8346L6.66667 10.0013L12.5 4.16797"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span
            onClick={() => {
              navigate(`/courses/${courseId}`);
            }}
          >
            COURSE DASHBOARD
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            onClick={closeSideNav}
          >
            <path
              d="M5 15L15 5M5 5L15 15"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <CourseInteractionLayoutProgressContainer />
        <CourseInteractionWeekLessons />
      </div>
    </div>
  );
};

export default CourseInteractionLayoutMobileHeader;
