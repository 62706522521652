import Button from "../../Components/Button/Button";
import classes from "../LoginPage/LoginPage.module.css";
import success from "../../Assets/Gifs/success.gif";
import { useNavigate } from "react-router-dom";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";

const ApplicationSuccessfulBody = () => {
  // Navigate
  const navigate = useNavigate();
  return (
    <section className={classes.modalContainer}>
      <img src={success} alt="Application successful" />
      <h4>Password Updated!</h4>
      <p>You can now log in to your account.</p>
      <div className={classes.modalButtonSection}>
        <Button
          onClick={() => {
            scrollToTheTop();
            navigate("/sign-in");
          }}
        >
          Login
        </Button>
      </div>
    </section>
  );
};

export default ApplicationSuccessfulBody;
