import Layout from "../Components/Layout/Layout";
import PaymentChoice from "../Containers/PaymentChoice/PaymentChoice";

const PaymentChoicePage = () => {
  return (
    <Layout closeSideNav>
      <PaymentChoice />
    </Layout>
  );
};

export default PaymentChoicePage;
