import Error from "../../Components/Error/Error";
import Input from "../../Components/Input/Input";
import TextArea from "../../Components/TextArea/TextArea";
import classes from "./ContactSupportForm.module.css";
import contactSupport from "../../Assets/Images/contactSupport.svg";
import DragAndDropInput from "../../Components/DragAndDropInput/DragAndDropInput";
import Button from "../../Components/Button/Button";
import { useContext, useEffect, useState } from "react";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import RequestSentModalBody from "./RequestSentModalBody";
import { SupportContext } from "../../Context/SupportContext";
import { changeHandler } from "../../Utilities/inputChangeHandler";

const ContactSupportForm = () => {
  // States
  const [file, setFile] = useState<any>();

  // Context
  const {
    supportForm,
    setSupportForm,
    sendSupport,
    sendSupportObject,
    setSendSupportObject,
  } = useContext(SupportContext);

  useEffect(() => {
    setSupportForm((prevState) => {
      return { ...prevState, image: file?.file };
    });

    // eslint-disable-next-line
  }, [file]);

  return (
    <div className={classes.container}>
      {sendSupportObject?.data && (
        <AcceptedModal
          onClick={() => {
            setSendSupportObject((prevState) => {
              return { ...prevState, data: null };
            });
          }}
          body={
            <RequestSentModalBody
              onClick={() => {
                setSendSupportObject((prevState) => {
                  return { ...prevState, data: null };
                });
              }}
            />
          }
        />
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <h4>Still need help? We’re here for you</h4>
        <p>
          Share the important details with us. After you open a request, you can
          view and add updates via your email.
        </p>

        <Error type="success">
          Do not share sensitive information (attachments or text). Ex. Your
          credit card details or personal ID numbers.
        </Error>

        <Input
          label="Subject"
          name="subject"
          isRequired
          placeholder="What is the subject of your support?"
          value={supportForm.subject}
          onChange={(e) => {
            changeHandler(e, setSupportForm);
          }}
        />

        <TextArea
          label="Description"
          isRequired
          name="description"
          placeholder="By providing the essential details, we can quickly resolve your request. "
          value={supportForm.description}
          onChange={(e) => {
            changeHandler(e, setSupportForm);
          }}
        />

        <DragAndDropInput state={file} setState={setFile} accept=".png, .jpg" />

        <div className={classes.buttonSection}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              // setRequestSent(true);
              sendSupport();
            }}
            disabled={!supportForm.description || !supportForm.subject}
            loading={sendSupportObject.isLoading}
          >
            Submit request
          </Button>
        </div>
      </form>
      <img src={contactSupport} alt="COntact support" />
    </div>
  );
};

export default ContactSupportForm;
