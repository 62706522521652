import classes from "./CourseResourcesByModule.module.css";

const CourseResourcesByModule = () => {
  // Utils
  const resources = [
    {
      title: "Week 1",
      resources: [
        {
          title: "Title of resource",
          downloadables: ["Resource 1.pdf", "Resource 2.pdf", "Resource 3.pdf"],
        },
        {
          title: "Title of resource",
          downloadables: ["Resource 1.pdf", "Resource 2.pdf", "Resource 3.pdf"],
        },
        {
          title: "Title of resource",
          downloadables: ["Resource 1.pdf", "Resource 2.pdf", "Resource 3.pdf"],
        },
      ],
    },
    {
      title: "Week 2",
      resources: [
        {
          title: "Title of resource",
          downloadables: ["Resource 1.pdf", "Resource 2.pdf", "Resource 3.pdf"],
        },
        {
          title: "Title of resource",
          downloadables: ["Resource 1.pdf", "Resource 2.pdf", "Resource 3.pdf"],
        },
      ],
    },
  ];
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <span>Week</span>
        <span>Resources</span>
      </div>

      <div className={classes.tableBodyContainer}>
        {resources.map((data, i) => {
          return (
            <div className={classes.tableBody} key={i}>
              <div>{data.title}</div>
              <div>
                {data.resources.map((datum, j) => {
                  return (
                    <div className={classes.resourceBit} key={j}>
                      <h4>{datum.title}</h4>
                      <div>
                        {datum.downloadables.map((downloadables, k) => {
                          return (
                            <a href="#0" key={k}>
                              {downloadables}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CourseResourcesByModule;
