import Layout from "../Components/Layout/Layout";
import CollaborationHub from "../Containers/CollaborationHub/CollaborationHub";

const CollaborationHubPage = () => {
  return (
    <Layout>
      <CollaborationHub />
    </Layout>
  );
};

export default CollaborationHubPage;
