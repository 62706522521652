import Layout from "../../Components/Layout/Layout";
import NoCourseSelectedCourses from "../NoCourseSelectedCourses/NoCourseSelectedCourses";

const CourseSearchContainer = () => {
  return (
    <Layout>
      <NoCourseSelectedCourses />
    </Layout>
  );
};

export default CourseSearchContainer;
