import classes from "../RecommendedCourses/RecommendedCourses.module.css";
import { useContext, useEffect } from "react";
import { CourseContext } from "../../Context/CourseContext";
import CourseCard from "../../Components/CourseCard/CourseCard";
import Loader from "../../Components/Loader/Loader";

const SavedCourses = () => {
  // context
  const { getSavedCoursesObject, getSavedCourses } = useContext(CourseContext);

  useEffect(() => {
    getSavedCourses(true);

    // eslint-disable-next-line
  }, []);

  if (getSavedCoursesObject.isLoading) {
    return <Loader />;
  }

  if (getSavedCoursesObject?.data?.length < 1) {
    return (
      <div className={classes.noCourses}>There were no saved courses found</div>
    );
  }

  return (
    <div className={classes.container}>
      {getSavedCoursesObject?.data?.map((data: any, i: number) => {
        return (
          <div key={i}>
            <CourseCard
              title={data?.course?.name}
              image={data?.course?.cover_image}
              description={data?.course?.description}
              ratings={4}
              price={data?.course?.cohorts[0]?.price}
              id={data?.course?.id}
              saved={data?.saved_courses}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SavedCourses;
