import classes from "./CourseDetailHeroSection.module.css";
import Button from "../../Components/Button/Button";
import { useContext } from "react";
import { CourseContext } from "../../Context/CourseContext";
import { useNavigate } from "react-router-dom";

const CourseDetailHeroSection = () => {
  // Context
  const { iseCourseDetail } = useContext(CourseContext);

  // Router
  const navigate = useNavigate();

  return (
    <section className={classes.container}>
      <div className={classes.bodyMain}>
        <div className={classes.leftSection}>
          <div className={classes.levelIndicator}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M3.01314 4.14452C2.89068 4.25067 2.7926 4.38203 2.72561 4.52959C2.65862 4.67716 2.6243 4.83746 2.62501 4.99952V20.7495C2.62501 21.0479 2.74354 21.334 2.95452 21.545C3.16549 21.756 3.45164 21.8745 3.75001 21.8745C4.04838 21.8745 4.33453 21.756 4.54551 21.545C4.75648 21.334 4.87501 21.0479 4.87501 20.7495V16.7858C7.01626 15.1808 8.82189 15.9308 11.5013 17.2545C13.0228 18.0045 14.7581 18.867 16.6388 18.867C18.0188 18.867 19.4775 18.4039 20.9869 17.097C21.1084 16.9917 21.2059 16.8616 21.2729 16.7154C21.3398 16.5692 21.3746 16.4103 21.375 16.2495V4.99952C21.3751 4.78382 21.3131 4.57265 21.1965 4.39117C21.0799 4.2097 20.9136 4.06557 20.7174 3.97596C20.5212 3.88635 20.3033 3.85504 20.0898 3.88576C19.8763 3.91647 19.6761 4.00792 19.5131 4.14921C17.1966 6.1564 15.3384 5.39702 12.4988 3.99171C9.86157 2.68109 6.57845 1.0564 3.01314 4.14452ZM19.125 15.7114C16.9838 17.3173 15.1781 16.5655 12.4988 15.2426C10.3275 14.1645 7.71845 12.8745 4.87501 14.1748V5.53296C7.01626 3.92796 8.82189 4.67796 11.5013 6.00171C13.0228 6.75171 14.7581 7.61421 16.6388 7.61421C17.4972 7.6155 18.3457 7.42962 19.125 7.06952V15.7114Z"
                fill="#2E2E2E"
              />
            </svg>
            <span>BEGINNER LEVEL COURSE</span>
          </div>
          <h4>{iseCourseDetail?.data?.name}</h4>
          {iseCourseDetail?.data?.course_objective?.length > 0 &&
            iseCourseDetail?.data?.course_objective?.map(
              (data: any, i: number) => {
                return (
                  <div className={classes.highlights} key={i}>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M5 13L9 17L19 7"
                          stroke="#664EFE"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span>{data.value}</span>
                  </div>
                );
              }
            )}
          <Button
            onClick={() => {
              navigate(`/eligibility/${iseCourseDetail?.data?.id}`);
            }}
          >
            Apply now
          </Button>
        </div>

        <div className={classes.rightSection}>
          <img
            src={iseCourseDetail?.data?.cover_image}
            alt={iseCourseDetail?.data?.name}
          />
        </div>
      </div>
    </section>
  );
};

export default CourseDetailHeroSection;
