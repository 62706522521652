// import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import classes from "./CourseProgress.module.css";

const CourseProgress = () => {
  // Navigate
  // const navigate = useNavigate()
  return (
    <section className={classes.container}>
      <h4>Course Progress</h4>
      <div className={classes.progressBarContainer}>
        <ProgressBar percentage={0} />
      </div>
      <div className={classes.progressReport}>
        <p>
          You are <span>-23.7%</span> behind other learners in your cohort.
        </p>
        <div>
          <Button>Start learning</Button>
        </div>
      </div>
    </section>
  );
};

export default CourseProgress;
