import { useContext, useEffect, useState } from "react";
import ProfileSectionContainer from "../../Components/ProfileSectionContainer/ProfileSectionContainer";
import ToggleSwitch from "../../Components/ToggleSwitch/ToggleSwitch";
import { AppContext } from "../../Context/AppContext";
import { activeTogglerRestAll } from "../../HelperFunctions/activeTogglers";
import { changeHandlerCheck } from "../../Utilities/inputChangeHandler";
import classes from "./ProfileAccountNotifications.module.css";

const ProfileAccountNotifications = () => {
  // COntext
  const {
    setNotificationSettingRequest,
    notificationSettings,
    setNotificationSettings,
    getNotificationSettingRequest,
    getNotificationSettingsRequestObject,
  } = useContext(AppContext);

  // States
  const [notificationSettingsState, setNotificationSettingsState] = useState([
    {
      title: "Course updates",
      paragraph:
        "Get notified when there are updates or changes to your enrolled courses.",
      isActive: notificationSettings.course_updates,
      name: "course_updates",
    },
    {
      title: "iṣẹ́ EdTech newsletters",
      paragraph:
        "Receive newsletters featuring curated content, success stories, and industry insights.",
      isActive: notificationSettings.newsletter,
      name: "newsletter",
    },
    {
      title: "Reminders",
      paragraph:
        "Set reminders to help you stay on track with assignment deadlines or live sessions.",
      isActive: notificationSettings.reminders,
      name: "reminders",
    },
    {
      title: "Announcements",
      paragraph:
        "Receive notifications about new courses, promotions, or platform updates.",
      isActive: notificationSettings.announcements,
      name: "announcements",
    },
    {
      title: "Feedback and grades",
      paragraph:
        "Get notified about graded assignments and receive feedback from instructors.",
      isActive: notificationSettings.feedback,
      name: "feedback",
    },
  ]);

  const [okayToRequestChange, setOkayToRequestChange] = useState(false);

  let demo: { [key: string]: boolean } = {};

  notificationSettingsState.forEach((data: any) => {
    demo[data.name] = data.isActive;
  });

  useEffect(() => {
    if (notificationSettings) {
      setNotificationSettingsState([
        {
          title: "Course updates",
          paragraph:
            "Get notified when there are updates or changes to your enrolled courses.",
          isActive: notificationSettings.course_updates,
          name: "course_updates",
        },
        {
          title: "iṣẹ́ EdTech newsletters",
          paragraph:
            "Receive newsletters featuring curated content, success stories, and industry insights.",
          isActive: notificationSettings.newsletter,
          name: "newsletter",
        },
        {
          title: "Reminders",
          paragraph:
            "Set reminders to help you stay on track with assignment deadlines or live sessions.",
          isActive: notificationSettings.reminders,
          name: "reminders",
        },
        {
          title: "Announcements",
          paragraph:
            "Receive notifications about new courses, promotions, or platform updates.",
          isActive: notificationSettings.announcements,
          name: "announcements",
        },
        {
          title: "Feedback and grades",
          paragraph:
            "Get notified about graded assignments and receive feedback from instructors.",
          isActive: notificationSettings.feedback,
          name: "feedback",
        },
      ]);
    }
  }, [notificationSettings]);

  useEffect(() => {
    getNotificationSettingRequest();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      !getNotificationSettingsRequestObject?.isLoading &&
      getNotificationSettingsRequestObject?.data
      // getNotificationSettingsRequestObject?.data
    ) {
      setOkayToRequestChange(true);
    }

    // eslint-disable-next-line
  }, [getNotificationSettingsRequestObject.data]);

  useEffect(() => {
    if (
      okayToRequestChange &&
      // (getNotificationSettingsRequestObject?.data?.feedback !==
      //   notificationSettingsState[4].isActive ||
      //   getNotificationSettingsRequestObject?.data?.announcements !==
      //     notificationSettingsState[3].isActive ||
      //   getNotificationSettingsRequestObject?.data?.reminders !==
      //     notificationSettingsState[2].isActive ||
      //   getNotificationSettingsRequestObject?.data?.newsletter !==
      //     notificationSettingsState[1].isActive ||
      //   getNotificationSettingsRequestObject?.data?.course_update !==
      //     notificationSettingsState[0].isActive)

      true
    ) {
      setNotificationSettingRequest();
    }

    // eslint-disable-next-line
  }, [notificationSettingsState]);

  return (
    <ProfileSectionContainer
      header="Notifications"
      paragraph="Customize your email notifications. Easily manage your preferences."
    >
      <div className={classes.container}>
        {notificationSettingsState.map((data, i) => {
          return (
            <div className={classes.notifications} key={i}>
              <div>
                <h5>{data.title}</h5>
                <p>{data.paragraph}</p>
              </div>
              <div>
                <ToggleSwitch
                  checked={data.isActive}
                  name={data.name}
                  onChange={(e) => {
                    activeTogglerRestAll(
                      i,
                      notificationSettingsState,
                      setNotificationSettingsState
                    );

                    changeHandlerCheck(e, setNotificationSettings);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </ProfileSectionContainer>
  );
};

export default ProfileAccountNotifications;
