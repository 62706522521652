import { useContext } from "react";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./CourseInteractionLayoutProgressContainer.module.css";

const CourseInteractionLayoutProgressContainer = () => {
  // Context
  const { iseCourseDetail } = useContext(CourseContext);

  return (
    <div className={classes.container}>
      <h4>{iseCourseDetail?.data?.name || "Course name"}</h4>

      <ProgressBar percentage={0} color="#fff" />
    </div>
  );
};

export default CourseInteractionLayoutProgressContainer;
