import { useContext, useEffect } from "react";
import Layout from "../Components/Layout/Layout";
import Loader from "../Components/Loader/Loader";
import DashboardMain from "../Containers/DashboardMain/DashboardMain";
import EligibleuserDashboard from "../Containers/EligibleuserDashboard/EligibleuserDashboard";
import NullStateDashboard from "../Containers/NullStateDashboard/NullStateDashboard";
import { AuthUserContext } from "../Context/AuthUserContext";
import { CourseContext } from "../Context/CourseContext";
// import NullStateDashboard from "../Containers/NullStateDashboard/NullStateDashboard";

const Dashboard = () => {
  // Context
  const { getUserEnrolledCoursesCount, userEnrolledCOursesCount } =
    useContext(CourseContext);

  const { getUser, user } = useContext(AuthUserContext);

  // Effects
  useEffect(() => {
    getUserEnrolledCoursesCount();

    if (user.data && !user.isLoading) {
      getUser(false);
    } else {
      getUser(true);
    }
    // eslint-disable-next-line
  }, []);

  if (userEnrolledCOursesCount.isLoading) {
    return (
      <Layout displayRightCta>
        <Loader />
      </Layout>
    );
  }

  return (
    <Layout displayRightCta>
      {userEnrolledCOursesCount.data?.paidEnrolledCourseCount > 0 ? (
        <DashboardMain />
      ) : userEnrolledCOursesCount?.data?.courseCount === 0 ? (
        <NullStateDashboard />
      ) : userEnrolledCOursesCount?.data?.freeEnrolledCourseCount === 0 ? (
        <EligibleuserDashboard />
      ) : (
        <NullStateDashboard />
      )}
    </Layout>
  );
};

export default Dashboard;
