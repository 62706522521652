import Button from "../../Components/Button/Button";
import classes from "./NoCourse.module.css";
import noPurchase from "../../Assets/Images/noPurchase.svg";
import { useNavigate } from "react-router-dom";

type NoCoursetype = {
  title: string;
  description: string;
  image: string;
};

const NoCourse = ({ title, description, image }: NoCoursetype) => {
  // Navigate
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <img src={image || noPurchase} alt="No purchase" />
      <h4>{title || "You haven’t purchased any course ."}</h4>
      <p>{description || "Pick a course you like and make payment."}</p>
      <Button
        onClick={() => {
          navigate("/explore-courses");
        }}
      >
        Explore courses
      </Button>
    </div>
  );
};

export default NoCourse;
