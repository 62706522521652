import { useState, useContext } from "react";
import SectionsNav from "../../Components/SectionsNav/SectionsNav";
import BookmarkedCourses from "../BookmarkedCourses/BookmarkedCourses";
import CompletedCourses from "../CompletedCourses/CompletedCourses";
import EnrolledCourses from "../EnrolledCourses/EnrolledCourses";
import classes from "./CourseProgressContainer.module.css";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import RateModalBody from "../EnrolledCourses/RateModalBody";
import { AppContext } from "../../Context/AppContext";
import ShareModalBody from "./ShareModalBody";

const CourseProgressContainer = () => {
  // Context
  const {
    displayRatemodal,
    setDisplayRateModal,
    displaySharemodal,
    setDisplayShareModal,
  } = useContext(AppContext);

  // States
  const [navItems, setNavItems] = useState<any[]>([
    {
      title: "Enrolled courses",
      isActive: true,
      activeComponent: <EnrolledCourses />,
    },
    {
      title: "Completed courses",
      isActive: false,
      activeComponent: <CompletedCourses />,
    },
    {
      title: "Bookmarks",
      isActive: false,
      activeComponent: <BookmarkedCourses />,
    },
  ]);

  const activeComponent = navItems.find((data) => {
    return data.isActive;
  });
  return (
    <section className={classes.container}>
      {displayRatemodal && (
        <AcceptedModal
          onClick={() => {
            setDisplayRateModal(false);
          }}
          body={
            <RateModalBody
              onClick={() => {
                setDisplayRateModal(false);
              }}
            />
          }
        />
      )}

      {displaySharemodal && (
        <AcceptedModal
          onClick={() => {
            setDisplayShareModal(false);
          }}
          body={
            <ShareModalBody
              onClick={() => {
                setDisplayShareModal(false);
              }}
            />
          }
          style={{ minHeight: "700px" }}
        />
      )}

      <SectionsNav navItems={navItems} setNavItems={setNavItems} />
      {activeComponent.activeComponent}
    </section>
  );
};

export default CourseProgressContainer;
