import OnboardingLayout from "../../Components/OnboardingLayout/OnboardingLayout";
import classes from "../LoginPage/LoginPage.module.css";
import verifyEmailImage from "../../Assets/Images/verifyEmail.svg";
import emailConfifmed from "../../Assets/Images/emailConfifmed.svg";
import iseLogo from "../../Assets/Images/iseLogo.svg";
import Button from "../../Components/Button/Button";
import subClasses from "./VerifyEmail.module.css";
import { useContext, useEffect } from "react";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { useNavigate } from "react-router-dom";
import Error from "../../Components/Error/Error";
import { capitalize } from "../../HelperFunctions/capitalize";

const VerifyEmail = () => {
  // Context
  const { searchParams, verifyEmail, verifyEmailRequest, resendVerifyEmail } =
    useContext(AuthUserContext);

  const localUserEmail = localStorage.getItem("ise-user-email");

  // Router
  const signUpToken = searchParams.get("verificationToken");
  const isVerified = searchParams.get("isVerified");

  const navigate = useNavigate();

  useEffect(() => {
    if (signUpToken) verifyEmail(signUpToken as string);

    // eslint-disable-next-line
  }, []);

  return (
    <OnboardingLayout
      image={isVerified === "false" ? verifyEmailImage : emailConfifmed}
    >
      <section className={classes.container}>
        <img src={iseLogo} alt="Ise Logo" className={classes.logo} />
        {isVerified === "false" || !isVerified ? (
          <div className={classes.innerContainer}>
            <div className={subClasses.feedback}>
              {verifyEmailRequest.data && (
                <Error type="success">
                  {capitalize(verifyEmailRequest?.data as string)}
                </Error>
              )}

              {verifyEmailRequest.error && (
                <Error type="error">
                  {capitalize(verifyEmailRequest?.error as string)}
                </Error>
              )}
            </div>
            <div className={classes.header}>
              <h4>Verify your email address</h4>
              <p>
                We sent a verification email to {localUserEmail} with a link to
                verify your account. If you don’t see an email from us, check
                your spam folder.{" "}
              </p>
            </div>
            <p>Still can’t find the email?</p>
            <div className={classes.buttonSection}>
              <Button
                type="secondary"
                loading={verifyEmailRequest.isLoading}
                onClick={resendVerifyEmail}
              >
                Resend email
              </Button>
            </div>

            <div className={`${subClasses.needHelp} ${classes.alternate}`}>
              <span>Need help?</span>
              {"   "}
              <a href="mailto:support@iseafrica.com">Contact support</a>
            </div>
          </div>
        ) : (
          <div className={classes.innerContainer}>
            <div className={classes.header}>
              <h4>Email verification successful</h4>
              <p>
                Congratulations on verifying your email! Start your learning
                journey now and unlock your potential with  iṣẹ́ EdTech.
              </p>
            </div>
            <div className={classes.buttonSection}>
              <Button
                onClick={() => {
                  navigate("/onboarding?step=1");
                }}
                loading={verifyEmailRequest.isLoading}
              >
                Start my journey
              </Button>
            </div>
          </div>
        )}
      </section>
    </OnboardingLayout>
  );
};

export default VerifyEmail;
