import classes from "./CourseDetailPageContainer.module.css";
import courseDetailImage from "../../Assets/Images/courseDetailImage.svg";
import CourseDetailPageNameAndDescription from "../CourseDetailPageNameAndDescription/CourseDetailPageNameAndDescription";
import CourseDetailPageSpeakWithTutor from "../CourseDetailPageSpeakWithTutor/CourseDetailPageSpeakWithTutor";
import CourseDetailPageCourseProgress from "../CourseDetailPageCourseProgress/CourseDetailPageCourseProgress";
import CourseDetailPageTab from "../CourseDetailPageTab/CourseDetailPageTab";

const CourseDetailPageContainer = () => {
  return (
    <div className={classes.container}>
      <div className={classes.courseImageSection}>
        <img src={courseDetailImage} alt="Course" />
      </div>
      <div className={classes.courseInformationAndProgress}>
        <div>
          <CourseDetailPageNameAndDescription />
          <CourseDetailPageCourseProgress />
        </div>
        <div>
          <CourseDetailPageSpeakWithTutor />
        </div>
      </div>

      <div className={classes.courseMain}>
        <CourseDetailPageTab />
      </div>
    </div>
  );
};

export default CourseDetailPageContainer;
