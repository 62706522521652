import classes from "./CourseFeedbackContainer.module.css";
import surveyModal from "../../Assets/Images/surveyModal.svg";
import Button from "../../Components/Button/Button";

type CourseFeedbackModalbodyProps = {
  onClick: () => void;
};

const CourseFeedbackModalbody = ({ onClick }: CourseFeedbackModalbodyProps) => {
  return (
    <div className={classes.modalContainer}>
      <img src={surveyModal} alt="Survey modal" />
      <h4>Thank you for completing the survey</h4>
      <p>Your feedback is valuable to us and would help us serve you better</p>

      <Button onClick={onClick}>Done</Button>
    </div>
  );
};

export default CourseFeedbackModalbody;
