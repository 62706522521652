import { useContext, useEffect } from "react";
import { AuthUserContext } from "../../Context/AuthUserContext";
import ProfileProfileAbout from "../ProfileProfileAbout/ProfileProfileAbout";
import ProfileProfileAdditionalDetails from "../ProfileProfileAdditionalDetails/ProfileProfileAdditionalDetails";
import ProfileProfileSkillsAndExperience from "../ProfileProfileSkillsAndExperience/ProfileProfileSkillsAndExperience";
import classes from "./ProfileProfile.module.css";

const ProfileProfile = () => {
  // Context
  const { getUser } = useContext(AuthUserContext);

  // Effects
  useEffect(() => {
    getUser(false);

    // eslint-disable-next-line
  }, []);

  return (
    <section className={classes.container}>
      <ProfileProfileAbout />
      <ProfileProfileSkillsAndExperience />
      <ProfileProfileAdditionalDetails />
    </section>
  );
};

export default ProfileProfile;
