import classes from "./NullStateDashboard.module.css";
import nullStateDashboard from "../../Assets/Images/nullStateDashboard.svg";
import Button from "../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthUserContext } from "../../Context/AuthUserContext";

const NullStateDashboard = () => {
  // Navigate
  const navigate = useNavigate();

  const { user } = useContext(AuthUserContext);

  return (
    <section className={classes.container}>
      <img src={nullStateDashboard} alt="No user courses" />
      <h4>
        Welcome to your dashboard
        {`, ${user?.data?.full_name?.split(" ")[0]}` || "user"}
      </h4>
      <p>
        You don’t have any active course. Take a look around and pick a course.
      </p>

      <Button
        onClick={() => {
          navigate("/courses");
        }}
      >
        <span>Explore courses</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M17.5 8L21.5 12M21.5 12L17.5 16M21.5 12L3.5 12"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Button>
    </section>
  );
};

export default NullStateDashboard;
