import moment from "moment";
import { capitalize } from "./capitalize";

export const calculateReadingTime = (text: string) => {
  // Define average reading speed in words per minute (wpm)
  const wordsPerMinute = 200;

  // Calculate number of words in the text
  const wordCount = text.split(/\s+/).length;

  // Calculate estimated reading time in minutes
  const readingTimeMinutes = wordCount / wordsPerMinute;

  // Convert reading time to seconds
  const readingTimeSeconds = Math.round(readingTimeMinutes * 60);

  //   return readingTimeSeconds;

  const readingTimeInSeconds = readingTimeSeconds;

  const duration = moment.duration(readingTimeInSeconds, "seconds");

  const minutes = duration.humanize();

  return capitalize(minutes);
};
