import { useContext, useEffect, useState } from "react";
import Input from "../../Components/Input/Input";
import ProfileSectionContainer from "../../Components/ProfileSectionContainer/ProfileSectionContainer";
import classes from "./ProfileProfileAbout.module.css";
import noProfileImage from "../../Assets/Images/noProfileImage.svg";
import DropdownWithSearch from "../../Components/DropdownWithSearch/DropdownWithSearch";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { changeHandler } from "../../Utilities/inputChangeHandler";
import Button from "../../Components/Button/Button";
import { languages } from "../../Utilities/languages";

const ProfileProfileAbout = () => {
  // Context
  const {
    updateAboutHandler,
    updateAboutHandlerObject,
    aboutInfoUpdate,
    setAboutInfoUpdate,
    countriesRequestObject,
    fetchCountries,
    user,
    aboutInfoFormData,
  } = useContext(AuthUserContext);

  // States
  const [userImage, setUserImage] = useState<{
    frontendFile: string;
    file: string | File;
  }>({
    file: "",
    frontendFile: "",
  });
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  // Utils
  const imageHandler = (e: any) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setUserImage((prevState: any) => {
          return { ...prevState, frontendFile: reader.result };
        });
      }
    };
    reader.readAsDataURL(e.target.files[0]);

    setAboutInfoUpdate((prevState) => {
      return { ...prevState, profile_image: e.target.files[0] };
    });
  };

  // Effects
  useEffect(() => {
    fetchCountries();

    if (user?.data) {
      setAboutInfoUpdate((prevState) => {
        return { ...prevState, full_name: user?.data?.full_name };
      });

      setUserImage((prevState) => {
        return { ...prevState, frontendFile: user?.data?.profile_image };
      });

      setGender(user?.data?.gender);
      setCountry(user?.data?.country);
      setPreferredLanguage(user?.data?.preferred_language);
    }

    // eslint-disable-next-line
  }, [user?.data]);

  useEffect(() => {
    if (gender) {
      setAboutInfoUpdate((prevState) => {
        return { ...prevState, gender };
      });
    }

    if (country) {
      setAboutInfoUpdate((prevState) => {
        return { ...prevState, country };
      });
    }

    if (preferredLanguage) {
      setAboutInfoUpdate((prevState) => {
        return { ...prevState, preferred_language: preferredLanguage };
      });
    }

    // eslint-disable-next-line
  }, [gender, country, preferredLanguage]);

  useEffect(() => {
    if (user?.data) {
      if (user?.data?.full_name !== aboutInfoUpdate?.full_name) {
        setIsEditing(true);
      }
    }

    // eslint-disable-next-line
  }, [aboutInfoUpdate?.full_name]);

  useEffect(() => {
    if (user?.data) {
      if (user?.data?.profile_image !== userImage?.frontendFile) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }
    }

    // eslint-disable-next-line
  }, [userImage]);

  useEffect(() => {
    if (user?.data) {
      if (user?.data?.gender !== gender) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }
    }

    // eslint-disable-next-line
  }, [gender]);

  useEffect(() => {
    if (user?.data) {
      if (user?.data?.country !== country) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }
    }

    // eslint-disable-next-line
  }, [country]);

  useEffect(() => {
    if (user?.data) {
      if (user?.data?.preferred_language !== preferredLanguage) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }
    }

    // eslint-disable-next-line
  }, [preferredLanguage]);

  useEffect(() => {
    aboutInfoFormData.append("full_name", aboutInfoUpdate?.full_name);
    aboutInfoFormData.append("gender", aboutInfoUpdate?.gender);
    aboutInfoFormData.append("country", aboutInfoUpdate?.country);
    aboutInfoFormData.append(
      "preferred_language",
      aboutInfoUpdate?.preferred_language
    );
    aboutInfoFormData.append("profile_image", aboutInfoUpdate?.profile_image);

    // eslint-disable-next-line
  }, [aboutInfoUpdate]);

  return (
    <div className={classes.container}>
      <ProfileSectionContainer
        header="About"
        paragraph="Tell us about yourself so we can know you better"
      >
        <div className={classes.userDetails}>
          <Input
            isRequired
            label="Full name"
            placeholder="Full name"
            name="full_name"
            onChange={(e) => {
              changeHandler(e, setAboutInfoUpdate);
            }}
            value={aboutInfoUpdate?.full_name}
          />

          <div className={classes.profilePhoto}>
            <div>
              <img
                src={
                  userImage.frontendFile ||
                  user?.data?.profile ||
                  noProfileImage
                }
                alt="Profile"
              />
              <div className={classes.buttonUpper}>
                <input
                  type="file"
                  id="profilePhoto"
                  onChange={imageHandler}
                  accept=" .jpg,.jpeg,.png./*"
                />
                <label htmlFor="profilePhoto">Upload photo</label>
              </div>
            </div>
          </div>

          <p className={classes.profilePhotoTip}>
            Maximum size: 1MB. Supported formats: JPG, GIF, or PNG.
          </p>

          <DropdownWithSearch
            label="Gender"
            options={["Male", "Female"]}
            isRequired
            title={"Select"}
            selected={gender}
            setSelected={setGender}
          />

          <DropdownWithSearch
            label="Country"
            options={countriesRequestObject?.data
              ?.map((data: any) => {
                return data?.name?.common;
              })
              .sort()}
            isRequired
            title={"Select"}
            selected={country}
            setSelected={setCountry}
          />

          <DropdownWithSearch
            label="Preferred language"
            options={languages.map((data) => {
              return data?.name;
            })}
            title={"Select"}
            selected={preferredLanguage}
            setSelected={setPreferredLanguage}
          />
        </div>

        {isEditing && (
          <div className={classes.buttonSection}>
            <Button
              loading={updateAboutHandlerObject.isLoading}
              onClick={updateAboutHandler}
            >
              Update
            </Button>
          </div>
        )}
      </ProfileSectionContainer>
    </div>
  );
};

export default ProfileProfileAbout;
