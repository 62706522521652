import moment from "moment";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { CourseContext } from "../../Context/CourseContext";
import { capitalize } from "../../HelperFunctions/capitalize";
import classes from "./BookmarkedCourses.module.css";

const BookmarkedCourses = () => {
  // Context
  const { getBookmarkObjects, getBookmarks, deleteBookmarks, setBookmark } =
    useContext(CourseContext);

  // Router
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    getBookmarks(true);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (setBookmark?.data && getBookmarkObjects?.data) {
      if (
        setBookmark?.data?.split(" ")[0].toLowerCase() === "deleted" &&
        !setBookmark?.isLoading
      ) {
        getBookmarks(false);
      }
    }

    // eslint-disable-next-line
  }, [setBookmark]);

  if (getBookmarkObjects?.isLoading) {
    return (
      <section className={classes.container}>
        <Loader />
      </section>
    );
  }

  return (
    <section className={classes.container}>
      <div className={classes.tableHeader}>
        <span>Course Title</span>
        <span>File type</span>
        <span>Date added</span>
        <span>Action</span>
      </div>

      <div className={classes.body}>
        {getBookmarkObjects?.data?.length > 0 ? (
          getBookmarkObjects?.data.map((data: any, i: number) => {
            return (
              <div key={data?.id} className={classes.tableBody}>
                <div>
                  <span>{i + 1}</span>
                  <span
                    onClick={() => {
                      navigate(
                        `/courses/${data?.course?.id}/view?activeModule=${
                          data?.course_modules?.id
                        }&activeWeek=${data?.course_weeks?.id}&type=${
                          !data?.video ? "reading" : "video"
                        }&activeContentId=${
                          !data?.video
                            ? data?.reading?.title
                            : data?.video?.title
                        }`
                      );
                    }}
                  >
                    {data?.video ? data?.video?.title : data?.reading?.title}
                  </span>
                </div>
                <span>{data?.video ? "Video" : "Reading"}</span>
                <span>{capitalize(moment().fromNow(data?.created_at))}</span>
                <span
                  onClick={() => {
                    deleteBookmarks(data?.id);
                  }}
                >
                  Remove
                </span>
              </div>
            );
          })
        ) : (
          <div className={classes.noBookmarks}>No bookmarks yet</div>
        )}
      </div>
    </section>
  );
};

export default BookmarkedCourses;
