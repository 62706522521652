import Layout from "../Components/Layout/Layout";
import Profile from "../Containers/Profile/Profile";

const ProfilePage = () => {
  return (
    <Layout>
      <Profile />
    </Layout>
  );
};

export default ProfilePage;
