import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./CourseInteractionCourseActionsContainer.module.css";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import ReportAnIssueContainer from "../CourseDetailPageSpeakWithTutor/ReportAnIssueContainer";

const CourseInteractionCourseActionsContainer = () => {
  // Context
  const { setDisplayReportModal, displayReportModal } =
    useContext(CourseContext);
  const { screenWidthState } = useContext(AppContext);

  return (
    <div className={classes.container}>
      {displayReportModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayReportModal(false);
          }}
          style={
            screenWidthState < 767
              ? {
                  minHeight: "100%",
                }
              : { minHeight: "833px" }
          }
          body={
            <ReportAnIssueContainer
              onClick={() => {
                setDisplayReportModal(false);
              }}
            />
          }
        />
      )}

      <div
        onClick={() => {
          setDisplayReportModal(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M13 16.5H12V12.5H11M12 8.5H12.01M21 12.5C21 17.4706 16.9706 21.5 12 21.5C7.02944 21.5 3 17.4706 3 12.5C3 7.52944 7.02944 3.5 12 3.5C16.9706 3.5 21 7.52944 21 12.5Z"
            stroke="#664EFE"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>Report an issue</span>
      </div>

      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M16 3.5H21M21 3.5V8.5M21 3.5L15 9.5M5 3.5C3.89543 3.5 3 4.39543 3 5.5V6.5C3 14.7843 9.71573 21.5 18 21.5H19C20.1046 21.5 21 20.6046 21 19.5V16.2208C21 15.7903 20.7246 15.4082 20.3162 15.2721L15.8228 13.7743C15.3507 13.6169 14.8347 13.8306 14.6121 14.2757L13.4835 16.533C11.0388 15.4308 9.06925 13.4612 7.96701 11.0165L10.2243 9.88787C10.6694 9.66531 10.8831 9.14932 10.7257 8.67721L9.22792 4.18377C9.09181 3.77543 8.70967 3.5 8.27924 3.5H5Z"
            stroke="#664EFE"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>Speak with a tutor</span>
      </div>

      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            d="M17 20.5H22V18.5C22 16.8431 20.6569 15.5 19 15.5C18.0444 15.5 17.1931 15.9468 16.6438 16.6429M17 20.5H7M17 20.5V18.5C17 17.8438 16.8736 17.217 16.6438 16.6429M7 20.5H2V18.5C2 16.8431 3.34315 15.5 5 15.5C5.95561 15.5 6.80686 15.9468 7.35625 16.6429M7 20.5V18.5C7 17.8438 7.12642 17.217 7.35625 16.6429M7.35625 16.6429C8.0935 14.801 9.89482 13.5 12 13.5C14.1052 13.5 15.9065 14.801 16.6438 16.6429M15 7.5C15 9.15685 13.6569 10.5 12 10.5C10.3431 10.5 9 9.15685 9 7.5C9 5.84315 10.3431 4.5 12 4.5C13.6569 4.5 15 5.84315 15 7.5ZM21 10.5C21 11.6046 20.1046 12.5 19 12.5C17.8954 12.5 17 11.6046 17 10.5C17 9.39543 17.8954 8.5 19 8.5C20.1046 8.5 21 9.39543 21 10.5ZM7 10.5C7 11.6046 6.10457 12.5 5 12.5C3.89543 12.5 3 11.6046 3 10.5C3 9.39543 3.89543 8.5 5 8.5C6.10457 8.5 7 9.39543 7 10.5Z"
            stroke="#664EFE"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>Discussion forum</span>
      </div>
    </div>
  );
};

export default CourseInteractionCourseActionsContainer;
