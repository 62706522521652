import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CourseInteractionCourseActionsContainer from "../../Containers/CourseInteractionCourseActionsContainer/CourseInteractionCourseActionsContainer";
import CourseInteractionLayoutMobileHeader from "../../Containers/CourseInteractionLayoutMobileHeader/CourseInteractionLayoutMobileHeader";
import CourseInteractionLayoutProgressContainer from "../../Containers/CourseInteractionLayoutProgressContainer/CourseInteractionLayoutProgressContainer";
import CourseInteractionWeekLessons from "../../Containers/CourseInteractionWeekLessons/CourseInteractionWeekLessons";
import { AppContext } from "../../Context/AppContext";
import { CourseContext } from "../../Context/CourseContext";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Layout from "../Layout/Layout";
import Loader from "../Loader/Loader";
import classes from "./CourseInteractionLayout.module.css";

type CourseInteractionLayoutProps = {
  children: React.ReactNode;
  notShowSideNavItems?: boolean;
};

const CourseInteractionLayout = ({
  children,
  notShowSideNavItems,
}: CourseInteractionLayoutProps) => {
  // Context
  const { screenWidthState } = useContext(AppContext);
  const { getCourseModulesObject, modules, activeWeek } =
    useContext(CourseContext);

  // Router
  const { courseId } = useParams();
  const currentParams = new URLSearchParams(window.location.search);
  const activeModuleId = currentParams.get("activeModule");
  const activeWeekId = currentParams?.get("activeWeek");
  const activeContentId = currentParams?.get("activeContentId");
  const activeCohort = currentParams.get("activeCohort");

  // Utils
  const [breadCrumbs, setBreadCrumb] = useState({
    image: undefined,
    array: [
      {
        title: "Module",
        route: `/courses/${courseId}?activeCohort=${activeCohort}`,
      },
      {
        title: "Week",
        route: `/courses/${courseId}/overview?activeCohort=${activeCohort}`,
      },
      {
        title: "Topic",
        route: `/courses/${courseId}/view?activeCohort=${activeCohort}`,
      },
    ],
  });
  const [allRecords, setAllRecords] = useState<any[]>([]);

  // Effects
  useEffect(() => {
    if (modules) {
      const testRecords: any[] = [];
      modules.forEach((module: any) => {
        module.course_weeks.forEach((week: any) => {
          week.records.forEach((record: any) => {
            const modifiedRecord = {
              ...record,
              moduleId: module?.id,
              weekId: week?.id,
            };
            testRecords.push(modifiedRecord);
          });
        });
      });

      setAllRecords(testRecords);
    }

    // eslint-disable-next-line
  }, [activeWeekId, modules]);

  useEffect(() => {
    if (modules && allRecords) {
      const activeModuleObject = modules.find((data: any) => {
        return data?.id === Number(activeModuleId);
      });

      const activeRecord = allRecords.find((data: any) => {
        return data?.title === activeContentId?.toString();
      });

      setBreadCrumb((prevState) => {
        return {
          ...prevState,
          array: prevState.array.map((data: any, i: number) => {
            if (i === 0) {
              return {
                ...data,
                title: activeModuleObject?.title,
                route: `/courses/${courseId}?activeCohort=${activeCohort}`,
              };
            } else if (i === 1 && activeWeek) {
              return {
                ...data,
                title: (activeWeek as any)?.title,
                route: `/courses/${courseId}/overview?activeCohort=${activeCohort}&activeModule=${activeModuleId}&activeWeek=${
                  (activeWeek as any)?.id
                }`,
              };
            } else if (i === 2 && activeWeek && allRecords) {
              return {
                ...data,
                title: (activeRecord as any)?.title,
                route: `/courses/${courseId}/view?activeCohort=${activeCohort}&activeModule=${activeModuleId}&activeWeek=${
                  (activeWeek as any)?.id
                }&type=${activeRecord?.type}&activeContentId=${
                  activeRecord?.title
                }`,
              };
            } else {
              return { ...data };
            }
          }),
        };
      });
    }

    // eslint-disable-next-line
  }, [modules, activeModuleId, activeWeek, allRecords, activeContentId]);
  return (
    <Layout notDisplayHeader={screenWidthState < 1023 ? true : false}>
      <section className={classes.container}>
        <div className={classes.mobileHeader}>
          <CourseInteractionLayoutMobileHeader />
        </div>
        <div className={classes.breadCrumb}>
          <Breadcrumbs {...breadCrumbs} />
        </div>
        <div className={classes.bodyMain}>
          {!notShowSideNavItems && getCourseModulesObject.isLoading ? (
            <div className={classes.coursesList}>
              <Loader />
            </div>
          ) : (
            <div className={classes.coursesList}>
              <CourseInteractionLayoutProgressContainer />
              <CourseInteractionWeekLessons />
            </div>
          )}

          <div className={classes.coursesActions}>
            <div className={classes.topSection}>{children}</div>
            <div className={classes.bottomSection}>
              <CourseInteractionCourseActionsContainer />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CourseInteractionLayout;
