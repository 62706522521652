import axios from "axios";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import requestHandler from "../HelperFunctions/requestHandler";
import { setNotiticationFunction } from "../Utilities/setNotificationsFunction";
import { AppContext } from "./AppContext";
import { AuthUserContext, requestType } from "./AuthUserContext";
import fullPayment from "../Assets/Images/fullPayment.svg";
import installmentalPayment from "../Assets/Images/installmentalPayment.png";
import { usePaystackPayment } from "react-paystack";
import { useFlutterwave } from "flutterwave-react-v3";
import { FlutterwaveConfig } from "flutterwave-react-v3/dist/types";
import iseLogo from "../Assets/Images/iseLogo.svg";
import { backend_url } from "../Utilities/global";

type PaymentContextProiderTypes = {
  children: React.ReactNode;
};

type PaymentContextValueTypes = {
  paymentProiders: requestType;
  setPaymentProviders: Dispatch<SetStateAction<requestType>>;
  getPaymentProviders: () => void;
  baseCurrency: string | undefined;
  setBaseCurrency: Dispatch<SetStateAction<string | undefined>>;
  getCurrentExchangeRate: () => void;
  exchangerates: requestType;
  setExchangerates: Dispatch<SetStateAction<requestType>>;
  checkout: (
    paymentType: string,
    paymentMedium: number,
    courseId: string,
    couponCode?: string | null
  ) => void;
  paymentPlans: {
    title: string;
    price: string;
    image: string;
    advantages: string[];
  }[];
  setPaymentPlans: Dispatch<
    SetStateAction<
      {
        title: string;
        price: string;
        image: string;
        advantages: string[];
      }[]
    >
  >;
  checkoutObject: requestType;
  setCheckoutObject: Dispatch<SetStateAction<requestType>>;
  setParticularCourse: Dispatch<SetStateAction<requestType>>;
  getCouponObject: requestType;
  setGetCouponObject: Dispatch<SetStateAction<requestType>>;
  getCoupon: (code: string) => void;
};

export const PaymentContext = createContext({} as PaymentContextValueTypes);

const PaymentContextProvider = ({ children }: PaymentContextProiderTypes) => {
  // Context
  const { setNotifications } = useContext(AppContext);
  const { logout } = useContext(AuthUserContext);

  // States
  const [paymentProiders, setPaymentProviders] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [exchangerates, setExchangerates] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [baseCurrency, setBaseCurrency] = useState<string | undefined>("NGN");
  const [paymentPlans, setPaymentPlans] = useState([
    {
      title: "Full payment",
      price: "0",
      image: fullPayment,
      advantages: [
        "Pay immediately and focus on learning.",
        "Early access to resources and support.",
        "Globally recognized certificate",
      ],
    },

    {
      title: "Instalment payment",
      price: "0",
      image: installmentalPayment,
      advantages: [
        "Pay course fee in two installments.",
        "30% payment after 2 months",
        "Full access to resources after payment.",
        "Globally recognized certificate",
      ],
    },
  ]);
  const [particularCourse, setParticularCourse] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  //   Router
  const navigate = useNavigate();

  //   Requests
  const getPaymentProviders = () => {
    if (paymentProiders.isLoading) {
      return;
    }
    setPaymentProviders({ isLoading: true, data: null, error: null });
    axios
      .get(`${backend_url}/api/ise/v1/paymentprocessors`)
      .then((res) => {
        setPaymentProviders({
          isLoading: false,
          data: res?.data.map((data: any) => {
            return { ...data, isActive: false };
          }),
          error: null,
        });
      })
      .catch((err) => {
        setPaymentProviders({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );
      });
  };

  const getCurrentExchangeRate = () => {
    setExchangerates({ isLoading: true, data: null, error: null });
    axios
      .get(
        `${process.env.REACT_APP_CURRENCY_CONVERTER_URL}/v6/${process.env.REACT_APP_CURRENCY_CONVERTER_API_KEY}/latest/NGN`
      )
      .then((res) => {
        setExchangerates({
          isLoading: false,
          data: res?.data.conversion_rates[(baseCurrency as string) || "NGN"],
          error: null,
        });
      })
      .catch((err) => {
        setExchangerates({
          isLoading: false,
          data: null,
          error: err?.response?.data?.result,
        });
        setNotiticationFunction(
          setNotifications,
          "There was an issue with currency conversion, please use the Naira payment option"
        );
      });
  };

  const [checkoutObject, setCheckoutObject] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [flutterConfig, setFlutterCOnfig] = useState<null | FlutterwaveConfig>(
    null
  );
  const flutterConfigData = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC as string,
    tx_ref: flutterConfig?.tx_ref as string,
    amount: flutterConfig?.amount as number,
    currency: baseCurrency,
    payment_options: flutterConfig?.payment_options as string,
    customer: {
      phone_number: "000",
      ...(flutterConfig?.customer as {
        email: string;
        name: string;
      }),
    },
    customizations: {
      title: particularCourse?.data?.name,
      description: `Payment for your Ise ${particularCourse?.data?.name}`,
      logo: iseLogo,
    },
  };

  const initPaystack = usePaystackPayment({
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC as string,
  });

  const initFlutterwave = useFlutterwave(flutterConfigData);

  useEffect(() => {
    if (flutterConfigData) {
      initFlutterwave({
        onClose: () => {
          setNotiticationFunction(
            setNotifications,
            "Your payment process was terminated!"
          );
        },
        callback: (res) => {
          requestHandler({
            method: "GET",
            url: `${backend_url}/api/ise/v1/transactions/verify/paystack-transaction?reference=${
              (res as any)?.tx_ref
            }&course_id=${particularCourse?.data?.id as string}`,
          })
            .then((response) => {
              setNotiticationFunction(
                setNotifications,
                (res as any)?.data,
                "success"
              );
              navigate("/courses");
            })
            .catch((err) => {
              setNotiticationFunction(
                setNotifications,
                err.response?.data?.error
                  ? err.response?.data?.error?.responseMessage
                  : !err.response?.data?.error
                  ? err.response?.data?.responseMessage.toString()
                  : err.message
              );
            });
        },
      });
    }

    // eslint-disable-next-line
  }, [flutterConfig]);

  const checkout = (
    paymentType: string,
    paymentMedium: number,
    courseId: string,
    couponCode?: string | null
  ) => {
    setCheckoutObject({
      isLoading: true,
      data: null,
      error: null,
    });
    requestHandler({
      method: "POST",
      url: `${backend_url}/api/ise/v1/paymentprocessors/pay/${String(
        paymentMedium
      )}`,
      data: {
        currency: baseCurrency as string,
        exchange_rate: exchangerates.error ? 0 : (exchangerates.data as string),
        course_id: Number(courseId as string),
        payment_type: paymentType,
        coupon_code: couponCode,
      },
    })
      .then((res) => {
        setCheckoutObject({
          isLoading: false,
          data: (res as any).data,
          error: null,
        });

        if (paymentMedium === 1) {
          const onSuccess: (reference?: any) => void = (reference: any) => {
            requestHandler({
              method: "GET",
              url: `${backend_url}/api/ise/v1/transactions/verify/paystack-transaction?reference=${
                reference?.reference
              }&course_id=${courseId as string}`,
            })
              .then((res) => {
                setNotiticationFunction(
                  setNotifications,
                  (res as any)?.data,
                  "success"
                );
                navigate("/courses");
              })
              .catch((err) => {
                setNotiticationFunction(
                  setNotifications,
                  err.response?.data?.error
                    ? err.response?.data?.error?.responseMessage
                    : !err.response?.data?.error
                    ? err.response?.data?.responseMessage.toString()
                    : err.message
                );
              });
          };

          const onClose = () => {
            setNotiticationFunction(
              setNotifications,
              "Your payment process was terminated!"
            );
          };

          initPaystack({
            onClose,
            onSuccess,
            config: {
              reference: (res as any)?.data?.reference,
              email: (res as any)?.data?.customer?.email,
              amount: (res as any)?.data?.amount + (res as any)?.data?.charge,
            },
          });
        }
        if (paymentMedium === 2) {
          const flutterwaveConfigObject = {
            amount: (res as any)?.data?.amount + (res as any)?.data?.charge,
            tx_ref: (res as any)?.data?.reference,
            customer: { ...(res as any)?.data.customer },
            public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC as string,
            payment_options: (res as any).data?.payment_options.toString(),
            customizations: {
              title: "Test",
              description: "Test",
              logo: "Test",
            },
          };
          setFlutterCOnfig(flutterwaveConfigObject);
        }
      })
      .catch((err) => {
        setCheckoutObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );

        if (err?.response?.data?.error?.responseMessage === "Expired Token") {
          logout();
        }
      });
  };

  const [getCouponObject, setGetCouponObject] = useState<requestType>({
    isLoading: false,
    error: null,
    data: null,
  });

  const getCoupon = (code: string) => {
    setGetCouponObject({ isLoading: true, data: null, error: null });
    axios
      .get(`${backend_url}/api/ise/v1/coupon/verify/${code}`)
      .then((res) => {
        setGetCouponObject({
          isLoading: false,
          data: res?.data,
          error: null,
        });

        setNotiticationFunction(
          setNotifications,
          `${res?.data?.discount_percentage}% Discount applied`,
          "success"
        );
      })
      .catch((err) => {
        setGetCouponObject({
          isLoading: false,
          data: null,
          error: err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message,
        });

        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err.message
        );
      });
  };

  return (
    <PaymentContext.Provider
      value={{
        paymentProiders,
        getPaymentProviders,
        setPaymentProviders,
        baseCurrency,
        setBaseCurrency,
        getCurrentExchangeRate,
        exchangerates,
        setExchangerates,
        checkout,
        paymentPlans,
        setPaymentPlans,
        checkoutObject,
        setCheckoutObject,
        setParticularCourse,
        getCouponObject,
        setGetCouponObject,
        getCoupon,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentContextProvider;
