import { useContext, useState } from "react";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./CourseDetailPageNameAndDescription.module.css";

const CourseDetailPageNameAndDescription = () => {
  // COntext
  const { iseCourseDetail } = useContext(CourseContext);

  // State
  const [showMore, setShowMore] = useState(false);

  return (
    <div className={classes.container}>
      <h4>{iseCourseDetail?.data?.name || "No course found"}</h4>
      <div>
        <p className={!showMore ? classes.sliced : undefined}>
          {iseCourseDetail?.data?.description}
        </p>

        {iseCourseDetail?.data?.description.length >= 200 && (
          <p
            onClick={() => {
              setShowMore((prevState) => {
                return !prevState;
              });
            }}
          >
            {showMore ? "Hide" : "Show More"}
          </p>
        )}
      </div>
    </div>
  );
};

export default CourseDetailPageNameAndDescription;
