import classes from "./CourseInteractionWeekLessons.module.css";
import { iconHandler } from "../CourseLessonWeek/CourseLessonWeek";
import { useContext, useEffect, useState } from "react";
import { CourseContext } from "../../Context/CourseContext";
import { capitalize } from "../../HelperFunctions/capitalize";
import { calculateReadingTime } from "../../HelperFunctions/calclateReadingTime";

const CourseInteractionWeekLessons = () => {
  const currentParams = new URLSearchParams(window.location.search);
  const activeModuleId = currentParams?.get("activeModule");
  const activeWeekId = currentParams?.get("activeWeek");
  const activeContentId = currentParams?.get("activeContentId");

  const { modules, setSearchParams } = useContext(CourseContext);

  const [activeModuleWeeks, setActiveModuleWeeks] = useState(
    modules &&
      modules
        ?.find((data: any) => {
          return data.id === Number(activeModuleId);
        })
        ?.course_weeks?.map((datum: any, i: number) => {
          if (String(datum?.id) === activeWeekId) {
            return { ...datum, isActive: true };
          } else {
            return { ...datum, isActive: false };
          }
        })
  );

  useEffect(() => {
    if (activeWeekId) {
      setActiveModuleWeeks(
        modules
          ?.find((data: any) => {
            return data.id === Number(activeModuleId);
          })
          ?.course_weeks?.map((datum: any, i: number) => {
            if (String(datum?.id) === activeWeekId) {
              return { ...datum, isActive: true };
            } else {
              return { ...datum, isActive: false };
            }
          })
      );
    }

    // eslint-disable-next-line
  }, [activeWeekId, modules]);

  return (
    <div className={classes.container}>
      {activeModuleWeeks?.map((data: any, i: number) => {
        return (
          <div className={classes.lesson} key={i}>
            <div
              className={classes.lessonHeader}
              onClick={() => {
                currentParams.set("activeWeek", String(data?.id));
                setSearchParams(currentParams.toString());
              }}
            >
              <span>{data.title || "Title"}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                style={
                  data?.isActive
                    ? { transform: "rotate(-90deg)" }
                    : { transform: "rotate(0deg)" }
                }
              >
                <path
                  d="M19 9L12 16L5 9"
                  stroke="#2E2E2E"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div
              className={classes.lessonContent}
              style={
                data?.isActive ? { maxHeight: "500px" } : { maxHeight: "0px" }
              }
            >
              {data.records?.length > 0 ? (
                data.records?.map((data: any, i: number) => {
                  return (
                    <div
                      className={`${classes.video} ${
                        data?.title === activeContentId?.toString() &&
                        classes.active
                      }`}
                      key={i}
                      onClick={() => {
                        currentParams.set(
                          "activeContentId",
                          String(data?.title)
                        );
                        currentParams.set("type", String(data?.type));
                        setSearchParams(currentParams.toString());

                        if (data?.type === "quiz") {
                          currentParams.set("quizId", String(data?.id));
                          setSearchParams(currentParams.toString());
                        } else {
                          currentParams.delete("quizId");
                          setSearchParams(currentParams.toString());
                        }
                      }}
                    >
                      {iconHandler(data.type)}
                      <div>
                        <span>{data?.title}</span>
                        <span>
                          {capitalize(data?.type)} .{" "}
                          {data?.type === "reading"
                            ? calculateReadingTime(data?.reading_contents)
                            : "No duration"}
                        </span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={classes.noContent}>
                  <div>No content available for this week</div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CourseInteractionWeekLessons;
