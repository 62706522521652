import classes from "./EnrolledCourses.module.css";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Button from "../../Components/Button/Button";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";
import { CourseContext } from "../../Context/CourseContext";
import Loader from "../../Components/Loader/Loader";
import NoCourse from "../../Components/NoCourse/NoCourse";
import noCourse from "../../Assets/Images/noCourse.svg";

const EnrolledCourses = () => {
  // Context
  const { setDisplayRateModal } = useContext(AppContext);
  const { userEnrolledCOursesCount } = useContext(CourseContext);

  // Router
  const navigate = useNavigate();

  if (userEnrolledCOursesCount.isLoading) {
    <Loader />;
  }

  return (
    <section className={classes.container}>
      {userEnrolledCOursesCount?.data?.paidEnrolledCourse?.length < 1 ? (
        <NoCourse
          image={noCourse}
          title="No enrolled courses"
          description="Explore, pick a course and start learning."
        />
      ) : (
        userEnrolledCOursesCount?.data?.paidEnrolledCourse?.map(
          (data: any, i: number) => {
            return (
              <div className={classes.course} key={i}>
                <img src={data?.course?.cover_image} alt={data.course?.name} />
                <div>
                  <div>
                    <span>{data.course?.name}</span>
                    <span>
                      {Number(data?.course?.price) <= 0 ? "Free" : "Paid"}
                    </span>
                  </div>
                  <p>{data?.course?.description}</p>
                  <ProgressBar percentage={data?.rating} />
                </div>
                <div>
                  <Button
                    onClick={() => {
                      navigate(
                        `/courses/${data?.course?.id}?activeCohort=${data?.cohort?.id}`
                      );
                    }}
                  >
                    <span>Continue learning</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        d="M17.625 8.5L21.625 12.5M21.625 12.5L17.625 16.5M21.625 12.5L3.625 12.5"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                  <Button
                    type="secondary"
                    onClick={() => {
                      setDisplayRateModal(true);
                    }}
                  >
                    <span>Rate course</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_8518_5860)">
                        <path
                          d="M11.674 3.42664C11.9733 2.50537 13.2767 2.50538 13.576 3.42664L15.0949 8.10055C15.2288 8.51254 15.6127 8.79148 16.0459 8.79149L20.9604 8.79168C21.9291 8.79172 22.3318 10.0313 21.5482 10.6007L17.5724 13.4895C17.2219 13.7441 17.0753 14.1955 17.2091 14.6075L18.7276 19.2815C19.0269 20.2028 17.9725 20.9689 17.1888 20.3995L13.2128 17.511C12.8623 17.2564 12.3877 17.2564 12.0372 17.511L8.06122 20.3995C7.27752 20.9689 6.2231 20.2028 6.5224 19.2815L8.04089 14.6075C8.17474 14.1955 8.02809 13.7441 7.67763 13.4895L3.70183 10.6007C2.91817 10.0313 3.32092 8.79172 4.28961 8.79168L9.20411 8.79149C9.63731 8.79148 10.0212 8.51254 10.1551 8.10055L11.674 3.42664Z"
                          stroke="#664EFE"
                          strokeWidth="2"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8518_5860">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(0.625 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </Button>
                </div>
              </div>
            );
          }
        )
      )}
    </section>
  );
};

export default EnrolledCourses;
