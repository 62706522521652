import { useContext, useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import Error from "../../Components/Error/Error";
import Input from "../../Components/Input/Input";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import ProfileSectionContainer from "../../Components/ProfileSectionContainer/ProfileSectionContainer";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { changeHandler } from "../../Utilities/inputChangeHandler";
import classes from "../ProfileAccountManageAccounts/ProfileAccountManageAccounts.module.css";
import ChangeEmailComformedModalBody from "./ChangeEmailComformedModalBody";
import ChangeEmailModalWarning from "./ChangeEmailModalWarning";

const ProfileAccountManageEmail = () => {
  // COntext
  const {
    emailUpdate,
    setEmailUpdate,
    user,
    updateEmailHandlerObject,
    logout,
  } = useContext(AuthUserContext);
  // States
  const [displayInput, setDisplayInput] = useState(false);
  const [displayWarningModal, setDisplayWarningModal] = useState(false);
  const [displayEmailChangeConfirmModal, setDisplayEmailChangeConfirmModal] =
    useState(false);

  // Router

  // Effects
  useEffect(() => {
    if (updateEmailHandlerObject?.data) {
      setDisplayEmailChangeConfirmModal(true);
    }
  }, [updateEmailHandlerObject?.data]);

  return (
    <ProfileSectionContainer
      header="Manage email"
      paragraph="Update to an active email. We'll send you updates here."
    >
      <div className={classes.container}>
        {displayWarningModal && (
          <AcceptedModal
            onClick={() => {
              setDisplayWarningModal(false);
            }}
            body={
              <ChangeEmailModalWarning
                onClick={() => {
                  setDisplayWarningModal(false);
                }}
              />
            }
          />
        )}
        {displayEmailChangeConfirmModal && (
          <AcceptedModal
            onClick={() => {
              setDisplayEmailChangeConfirmModal(false);
            }}
            body={
              <ChangeEmailComformedModalBody
                onClick={() => {
                  setDisplayEmailChangeConfirmModal(false);
                  logout();
                }}
              />
            }
          />
        )}
        <div className="error">
          <Error type="error">
            You can only change your email once in six months
          </Error>
        </div>
        <Input
          type="email"
          label="Account email"
          placeholder="oyegokeamirah@gmail.com"
          value={user?.data?.email || ""}
          readOnly
        />

        {!displayInput && (
          <Button
            type="null"
            onClick={() => {
              setDisplayInput(true);
            }}
          >
            Change email
          </Button>
        )}

        {displayInput && (
          <>
            <Input
              type="email"
              label="New Account email"
              placeholder="oyegokeamirah@gmail.com"
              name="newEmail"
              value={emailUpdate?.newEmail}
              onChange={(e) => {
                changeHandler(e, setEmailUpdate);
              }}
            />

            <div className={classes.buttonSection}>
              <Button
                type="secondary"
                onClick={() => {
                  setDisplayInput(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setDisplayWarningModal(true);
                }}
                disabled={
                  !emailUpdate.newEmail ||
                  !emailUpdate.newEmail.includes("@") ||
                  emailUpdate.newEmail === user?.data?.email
                }
              >
                Save new email
              </Button>
            </div>
          </>
        )}
      </div>
    </ProfileSectionContainer>
  );
};

export default ProfileAccountManageEmail;
