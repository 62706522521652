import Layout from "../Components/Layout/Layout";
import Referral from "../Containers/Referral/Referral";

const ReferralPage = () => {
  return (
    <Layout>
      <Referral />
    </Layout>
  );
};

export default ReferralPage;
