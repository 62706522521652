import { useContext } from "react";
import { AuthUserContext } from "../../Context/AuthUserContext";
import classes from "./UserOnboardingLayout.module.css";
import iseLogo from "../../Assets/Images/iseLogo.svg";

type UserOnboardingLayoutProps = {
  children: React.ReactNode;
  notShowHeader?: boolean;
};

const UserOnboardingLayout = ({
  children,
  notShowHeader,
}: UserOnboardingLayoutProps) => {
  // Context
  const { searchParams } = useContext(AuthUserContext);

  // Steps
  const steps = [1, 2, 3];

  const userStep = searchParams.get("step");

  return (
    <section className={classes.container}>
      {!notShowHeader && (
        <div className={classes.header}>
          <img src={iseLogo} alt="Ise Logo" className={classes.logo} />
        </div>
      )}
      <div className={classes.innerContainer}>
        <div className={classes.body}>
          <div className={classes.stepIndicator}>
            {steps.map((data) => {
              return (
                <div
                  className={`${classes.step} ${
                    userStep && userStep >= data.toString()
                      ? classes.active
                      : classes.inActive
                  }`}
                  key={data}
                >
                  <hr />
                  <span>{data}</span>
                </div>
              );
            })}
          </div>
          <div className={classes.children}>{children}</div>
        </div>
      </div>
    </section>
  );
};

export default UserOnboardingLayout;
