import classes from "./HelloUser.module.css";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthUserContext } from "../../Context/AuthUserContext";
import { CourseContext } from "../../Context/CourseContext";

type HelloUserProps = {
  notIncludePay?: boolean;
  notIncludeBg?: boolean;
  notIncludeParagraph?: boolean;
  payRoute?: string;
  text?: string;
  header?: string;
};

const HelloUser = ({
  notIncludePay,
  notIncludeBg,
  notIncludeParagraph,
  payRoute,
  text,
  header,
}: HelloUserProps) => {
  // Router
  const navigate = useNavigate();

  // Context
  const { user } = useContext(AuthUserContext);
  const { userEnrolledCOursesCount } = useContext(CourseContext);

  return (
    <div
      className={classes.helloUser}
      style={notIncludeBg ? { background: "#664efe" } : undefined}
    >
      <h2>
        {header || `Hello ${user?.data?.full_name?.split(" ")[0] || "user"}`}
      </h2>
      {!notIncludeParagraph && (
        <p>
          {text ||
            `Congratulations! You're eligible for the
          ${
            userEnrolledCOursesCount?.data?.lastEnrolledCourseUnpaid?.course
              ?.name || ""
          }
          course. Take the next step and start learning.`}
        </p>
      )}
      {!notIncludePay && (
        <Button
          type="tertiary"
          onClick={() => {
            navigate(payRoute as string);
          }}
        >
          Pay now
        </Button>
      )}
    </div>
  );
};

export default HelloUser;
