import { useContext, useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./CourseLessonQuizInfo.module.css";

type CourseLessonQuizInfoProps = {
  allRecords: any[];
};

const CourseLessonQuizInfo = ({ allRecords }: CourseLessonQuizInfoProps) => {
  // Context
  const { setSearchParams, getCourseQuiz, getCourseQuizQuestions } =
    useContext(CourseContext);

  // Router

  const currentParams = new URLSearchParams(window.location.search);
  const activeContentId = currentParams?.get("activeContentId");
  const quizId = currentParams.get("quizId");

  // States
  const [activeQuiz, setActiveQuiz] = useState<any>();

  useEffect(() => {
    if (allRecords.length > 1) {
      const activeRecord = allRecords.find((data: any) => {
        return data?.title === activeContentId?.toString();
      });

      setActiveQuiz(activeRecord);
    }

    // eslint-disable-next-line
  }, [allRecords]);

  // Effects
  useEffect(() => {
    if (quizId) {
      getCourseQuiz(quizId, "1");
    }

    // eslint-disable-next-line
  }, [quizId]);

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <h4>{activeQuiz?.title || "Quiz Title"}</h4>
        <p>
          {activeQuiz?.description ||
            "Welcome to the quiz section! Find a quiet, comfortable spot, free from distractions. Stay focused, read carefully, and trust in your abilities. Good luck and enjoy!"}
        </p>
        <div className={classes.quizDetails}>
          <span>Number of questions: {activeQuiz?.questions}</span>
          <span>Duration: {activeQuiz?.duration}</span>
        </div>
        <Button
          onClick={() => {
            // navigate(
            //   `/courses/${courseId}/${week}/${lessonType}/${lesson}/quiz-question/1`
            // );
            const currentParams = new URLSearchParams(window.location.search);
            currentParams.set("quizPhase", "ongoing");
            currentParams.set("quizQuestion", "1");
            setSearchParams(currentParams.toString());
          }}
          loading={getCourseQuizQuestions.isLoading}
        >
          <span>Start quiz</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M17 8L21 12M21 12L17 16M21 12L3 12"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
        {/* <p className={classes.warning} style={{ color: "#cd2a22" }}>
          You have exhausted your 3 trials for today. You can retake this quiz
          in <span style={{ color: "#2e2e2e" }}>23hrs : 45mins : 12secs</span>
        </p> */}
      </div>
    </div>
  );
};

export default CourseLessonQuizInfo;
