import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DropdownWithSearch from "../../Components/DropdownWithSearch/DropdownWithSearch";
import { activeToggler } from "../../HelperFunctions/activeTogglers";
import { capitalize } from "../../HelperFunctions/capitalize";
import classes from "./CourseMaterialsModuleContainer.module.css";

type CourseMaterialsModuleContainerProps = {
  activeComponent: {
    id: number;
    title: string;
    course_weeks: any[];
    course: any;
    description: string;
    name: string;
  };
};

const CourseMaterialsModuleContainer = ({
  activeComponent,
}: CourseMaterialsModuleContainerProps) => {
  const [weeks, setWeeks] = useState(
    activeComponent?.course_weeks.map((data, i) => {
      if (i === 0) {
        return { ...data, isActive: true };
      } else {
        return { ...data, isActive: false };
      }
    })
  );
  const [selectedWeek, setSelectedWeek] = useState("");

  // ROuter
  const navigate = useNavigate();
  const { courseId } = useParams();
  const currentParams = new URLSearchParams(window.location.search);
  const activeCohort = currentParams.get("activeCohort");

  // Utils
  const [activeWeek, setActiveWeek] = useState(
    weeks?.find((data) => {
      return data.isActive;
    })
  );

  useEffect(() => {
    setActiveWeek(
      weeks?.find((data) => {
        return data.isActive;
      })
    );
  }, [weeks]);

  useEffect(() => {
    setWeeks(
      activeComponent?.course_weeks.map((data, i) => {
        if (i === 0) {
          return { ...data, isActive: true };
        } else {
          return { ...data, isActive: false };
        }
      })
    );

    // eslint-disable-next-line
  }, [activeComponent]);

  useEffect(() => {
    if (weeks.length > 0) {
      const mobileSelectedWeek = weeks.find((data) => {
        return data.title === selectedWeek;
      });

      setActiveWeek(mobileSelectedWeek);
    }

    // eslint-disable-next-line
  }, [selectedWeek]);

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <div>
          <h4>{activeComponent?.name || "No title"}</h4>
          <p>Duration: 4weeks</p>
        </div>

        <div>
          <DropdownWithSearch
            title="Weeks"
            options={weeks.map((data) => data?.title) || []}
            selected={selectedWeek}
            setSelected={setSelectedWeek}
          />
        </div>
      </div>

      {weeks.length > 0 ? (
        <div className={classes.main}>
          <div>
            {weeks.map((data, i) => {
              return (
                <div
                  className={`${classes.week} ${
                    data?.isActive ? classes.activeWeek : classes.inActiveWeek
                  }`}
                  key={i}
                  onClick={() => {
                    activeToggler(i, weeks, setWeeks);
                  }}
                >
                  Week {i + 1}
                </div>
              );
            })}
          </div>
          <div className={classes.activeWeekDetails}>
            <div className={classes.activeWeekHeader}>
              <h4>{activeWeek?.title}</h4>
              <div className={classes.complete}>
                <span>Complete</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                >
                  <path
                    d="M6.75 9.5L8.25 11L11.25 8M15.75 9.5C15.75 13.2279 12.7279 16.25 9 16.25C5.27208 16.25 2.25 13.2279 2.25 9.5C2.25 5.77208 5.27208 2.75 9 2.75C12.7279 2.75 15.75 5.77208 15.75 9.5Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className={classes.activeWeekBody}>
              {activeWeek &&
                activeWeek?.records
                  ?.sort((a: any, b: any) => {
                    return a?.position - b?.position;
                  })
                  ?.map((datum: any, j: number) => {
                    return (
                      <div
                        className={classes.activeWeekTopic}
                        key={j}
                        onClick={() => {
                          if (datum?.type !== "quiz") {
                            navigate(
                              `/courses/${courseId}/view?activeCohort=${activeCohort}&activeModule=${activeComponent?.id}&activeWeek=${activeWeek?.id}&type=${datum?.type}&activeContentId=${datum?.title}`
                            );
                          } else {
                            navigate(
                              `/courses/${courseId}/view?activeCohort=${activeCohort}&activeModule=${activeComponent?.id}&activeWeek=${activeWeek?.id}&type=${datum?.type}&activeContentId=${datum?.title}&quizId=${datum?.id}`
                            );
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M7 10L9 12L13 8M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
                            // stroke="#63AD0E"
                            stroke="#000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div
                          onClick={() => {
                            navigate(`/courses/${courseId}`);
                          }}
                        >
                          <span>{datum?.title || "File"}</span>
                          <span>
                            {capitalize(datum?.type)}. {datum?.time}
                          </span>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.noCourseWeek}>
          No courses for this active week.
        </div>
      )}
    </section>
  );
};

export default CourseMaterialsModuleContainer;
