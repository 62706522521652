import classes from "../EnrolledCourses/EnrolledCourses.module.css";
// import courseImage from "../../Assets/Images/courseImage.svg";
// import ProgressBar from "../../Components/ProgressBar/ProgressBar";
// import Button from "../../Components/Button/Button";
// import { useContext } from "react";
// import { AppContext } from "../../Context/AppContext";
import NoCourse from "../../Components/NoCourse/NoCourse";
import noCourse from "../../Assets/Images/noCourse.svg";

const CompletedCourses = () => {
  return (
    <section className={classes.container}>
      {/* {courses.slice(0, 2).map((data, i) => {
        return (
          <div className={classes.course} key={i}>
            <img src={courseImage} alt={data.title} />
            <div>
              <div>
                <span>{data.title}</span>
                <span>Free</span>
              </div>
              <p>
                Master the skills needed to effectively plan, execute, and
                deliver successful projects, leading teams and stakeholders to
                achieve project goals.
              </p>
              <ProgressBar percentage={100} />
              <p className={classes.finishedDate}>
                <span>Completed Dec 2022</span>
                <span>Duration: 16 weeks</span>
              </p>
            </div>
            <div>
              <Button
                onClick={() => {
                  setDisplayRateModal(true);
                }}
              >
                <span>Rate course</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <g clipPath="url(#clip0_8518_16847)">
                    <path
                      d="M11.674 3.42664C11.9733 2.50537 13.2767 2.50538 13.576 3.42664L15.0949 8.10055C15.2288 8.51254 15.6127 8.79148 16.0459 8.79149L20.9604 8.79168C21.9291 8.79172 22.3318 10.0313 21.5482 10.6007L17.5724 13.4895C17.2219 13.7441 17.0753 14.1955 17.2091 14.6075L18.7276 19.2815C19.0269 20.2028 17.9725 20.9689 17.1888 20.3995L13.2128 17.511C12.8623 17.2564 12.3877 17.2564 12.0372 17.511L8.06122 20.3995C7.27752 20.9689 6.2231 20.2028 6.5224 19.2815L8.04089 14.6075C8.17474 14.1955 8.02809 13.7441 7.67763 13.4895L3.70183 10.6007C2.91817 10.0313 3.32092 8.79172 4.28961 8.79168L9.20411 8.79149C9.63731 8.79148 10.0212 8.51254 10.1551 8.10055L11.674 3.42664Z"
                      stroke="white"
                      strokeWidth="2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8518_16847">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.625 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </Button>

              <Button
                type="secondary"
                onClick={() => {
                  setDisplayShareModal(true);
                }}
              >
                <span>Share course</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M9.30887 13.8419C9.51116 13.4381 9.625 12.9824 9.625 12.5C9.625 12.0176 9.51116 11.5619 9.30887 11.1581M9.30887 13.8419C8.81634 14.8251 7.79949 15.5 6.625 15.5C4.96815 15.5 3.625 14.1569 3.625 12.5C3.625 10.8431 4.96815 9.5 6.625 9.5C7.79949 9.5 8.81634 10.1749 9.30887 11.1581M9.30887 13.8419L15.9411 17.1581M9.30887 11.1581L15.9411 7.84193M15.9411 7.84193C16.4337 8.82508 17.4505 9.5 18.625 9.5C20.2819 9.5 21.625 8.15685 21.625 6.5C21.625 4.84315 20.2819 3.5 18.625 3.5C16.9681 3.5 15.625 4.84315 15.625 6.5C15.625 6.98237 15.7388 7.43815 15.9411 7.84193ZM15.9411 17.1581C15.7388 17.5619 15.625 18.0176 15.625 18.5C15.625 20.1569 16.9681 21.5 18.625 21.5C20.2819 21.5 21.625 20.1569 21.625 18.5C21.625 16.8431 20.2819 15.5 18.625 15.5C17.4505 15.5 16.4337 16.1749 15.9411 17.1581Z"
                    stroke="#664EFE"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </div>
          </div>
        );
      })} */}

      <NoCourse
        image={noCourse}
        title="You haven't completed any courses yet."
        description="Finish courses to showcase your accomplishments and earn certificates."
      />
    </section>
  );
};

export default CompletedCourses;
