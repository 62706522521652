import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { AuthUserContext } from "../../Context/AuthUserContext";
import classes from "./DashboardEarnedBadges.module.css";

const DashboardEarnedBadges = () => {
  // Context
  const { userBadgesObject, badges } = useContext(AuthUserContext);

  // router
  const navigate = useNavigate();

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <div>
          <h4>Earned Badges</h4>
          <p>For every milestone you unlock on ise, we’ll gift you a badge. </p>
        </div>
        <div
          onClick={() => {
            navigate("/profile/badges");
          }}
        >
          <span>View all</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <path
              d="M12.75 6.5L15.75 9.5M15.75 9.5L12.75 12.5M15.75 9.5L2.25 9.5"
              stroke="#664EFE"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>

      <div className={classes.badgesSection}>
        {userBadgesObject?.isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          badges
            .filter((data) => data?.earned)
            .map((data, i) => {
              return (
                <div className={`${classes.badge} ${classes.locked}`} key={i}>
                  <div>
                    {data?.svg ? (
                      data.svg
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <g opacity="0.38">
                          <path
                            d="M9.49963 12.0006L11.4996 14.0006L15.4996 10.0006M8.33437 4.69766C9.05191 4.6404 9.73309 4.35824 10.281 3.89136C11.5594 2.80184 13.4398 2.80184 14.7183 3.89136C15.2662 4.35824 15.9474 4.6404 16.6649 4.69766C18.3393 4.83128 19.669 6.16092 19.8026 7.83535C19.8598 8.55288 20.142 9.23407 20.6089 9.78193C21.6984 11.0604 21.6984 12.9408 20.6089 14.2193C20.142 14.7672 19.8598 15.4483 19.8026 16.1659C19.669 17.8403 18.3393 19.1699 16.6649 19.3036C15.9474 19.3608 15.2662 19.643 14.7183 20.1099C13.4398 21.1994 11.5594 21.1994 10.281 20.1099C9.73309 19.643 9.05191 19.3608 8.33437 19.3036C6.65994 19.1699 5.3303 17.8403 5.19668 16.1659C5.13942 15.4483 4.85727 14.7672 4.39038 14.2193C3.30086 12.9408 3.30086 11.0604 4.39038 9.78193C4.85727 9.23407 5.13942 8.55288 5.19668 7.83535C5.3303 6.16092 6.65994 4.83128 8.33437 4.69766Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    )}
                  </div>
                  <p>{data.title}</p>
                  <p>{data.description}</p>
                </div>
              );
            })
        )}
      </div>
    </section>
  );
};

export default DashboardEarnedBadges;
