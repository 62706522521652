import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import Loader from "../Components/Loader/Loader";
import CourseDetailPageContainer from "../Containers/CourseDetailPageContainer/CourseDetailPageContainer";
import { CourseContext } from "../Context/CourseContext";

const CourseDetailPage = () => {
  // Context
  const {
    getCoursesById,
    iseCourseDetail,
    getCourseModules,
    getCourseModulesObject,
  } = useContext(CourseContext);

  // Router
  const { courseId } = useParams();
  const currentParams = new URLSearchParams(window.location.search);
  const activeCohort = currentParams.get("activeCohort");

  // Effects
  useEffect(() => {
    if (courseId) {
      getCourseModules(courseId as string, activeCohort as string, true, true);
      getCoursesById(courseId as string);

      // eslint-disable-next-line
    }

    // eslint-disable-next-line
  }, []);

  if (iseCourseDetail.isLoading || getCourseModulesObject.isLoading) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  return (
    <Layout>
      <CourseDetailPageContainer />;
    </Layout>
  );
};

export default CourseDetailPage;
