import CollaborationHubHintsAndTips from "../CollaborationHubHintsAndTips/CollaborationHubHintsAndTips";
import CollaborationHubNewToDiscord from "../CollaborationHubNewToDiscord/CollaborationHubNewToDiscord";
import CollaborationHubVideoGuide from "../CollaborationHubVideoGuide/CollaborationHubVideoGuide";
import CollaborationHubWelcome from "../CollaborationHubWelcome/CollaborationHubWelcome";
import CollaborationHubWhatsNext from "../CollaborationHubWhatsNext/CollaborationHubWhatsNext";
import classes from "./CollaborationHub.module.css";

const CollaborationHub = () => {
  return (
    <section className={classes.container}>
      <CollaborationHubWelcome />
      <CollaborationHubNewToDiscord />
      <CollaborationHubWhatsNext />
      <CollaborationHubHintsAndTips />
      <CollaborationHubVideoGuide />
    </section>
  );
};

export default CollaborationHub;
