import { useContext, useRef } from "react";
import classes from "./CourseCarousel2.module.css";
import CourseCard2 from "../CourseCard2/CourseCard2";
import { CourseContext } from "../../Context/CourseContext";
import Loader from "../Loader/Loader";

type CoursesCarousel2Props2 = {
  header: string;
  data: any[];
};

const CourseCarousel2 = ({ header, data }: CoursesCarousel2Props2) => {
  // Refs
  const carouselRef = useRef<HTMLDivElement>(null);

  // COntext
  const { getEnrolledCoursesObject, userEnrolledCOursesCount } =
    useContext(CourseContext);

  // Utils
  const scrollToNextDivItem = () => {
    if (carouselRef.current) {
      const container = carouselRef.current;
      const scrollAmount = container.offsetWidth;
      container.scrollLeft += scrollAmount;
    }
  };

  const scrollToPrevDivItem = () => {
    if (carouselRef.current) {
      const container = carouselRef.current;
      const scrollAmount = container.offsetWidth;
      container.scrollLeft -= scrollAmount;
    }
  };

  if (getEnrolledCoursesObject?.isLoading) {
    return (
      <section className={classes.container}>
        <Loader />
      </section>
    );
  }

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <div>
          <h2>{header}</h2>
        </div>
        <div>
          <span onClick={scrollToPrevDivItem}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15 19L8 12L15 5"
                stroke="#A79AFE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <span onClick={scrollToNextDivItem}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9 5L16 12L9 19"
                stroke="#A79AFE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
      <div className={classes.courseSection} ref={carouselRef}>
        {userEnrolledCOursesCount?.data?.paidEnrolledCourse?.map(
          (data: any, i: any) => {
            return (
              <div key={i}>
                <CourseCard2
                  title={data?.course?.name}
                  image={data?.course?.cover_image}
                  description={data?.course?.description}
                  paid={data?.cohort?.price > 0 ? true : false}
                  route={`/courses/${data?.course?.id}`}
                />
              </div>
            );
          }
        )}
      </div>
    </section>
  );
};

export default CourseCarousel2;
