import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import Loader from "../../Components/Loader/Loader";
import { AppContext } from "../../Context/AppContext";
import { CourseContext } from "../../Context/CourseContext";
import CourseLessonModuleList from "../CourseLessonModuleList/CourseLessonModuleList";
import CourseLessonsNameAndProgress from "../CourseLessonsNameAndProgress/CourseLessonsNameAndProgress";
import CourseLessonWeek from "../CourseLessonWeek/CourseLessonWeek";
import classes from "./CourseLessonsContainer.module.css";

const CourseLessonsContainer = () => {
  // Context
  const {
    getCourseModules,
    getCourseModulesObject,
    getCoursesById,
    iseCourseDetail,
    activeWeek,
    modules,
  } = useContext(CourseContext);

  const { screenWidthState } = useContext(AppContext);

  // Router
  const { courseId } = useParams();
  const currentParams = new URLSearchParams(window.location.search);
  const activeModule = currentParams.get("activeModule");
  const activeCohort = currentParams.get("activeCohort");

  // Utils
  const [breadCrumbs, setBreadCrumb] = useState({
    array: [
      {
        title: "Module",
        route: `/courses/${courseId}?activeCohort=${activeCohort}`,
      },
      {
        title: "Week",
        route: `/courses/${courseId}/overview?activeCohort=${activeCohort}`,
      },
    ],
  });

  // Effects
  useEffect(() => {
    getCourseModules(courseId as string, activeCohort as string, true, true);
    getCoursesById(courseId as string);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (modules) {
      const activeModuleObject = modules.find((data: any) => {
        return data?.id === Number(activeModule);
      });

      setBreadCrumb((prevState) => {
        return {
          ...prevState,
          array: prevState.array.map((data: any, i: number) => {
            if (i === 0) {
              return {
                ...data,
                title: activeModuleObject?.title,
                route: `/courses/${courseId}?activeCohort=${activeCohort}`,
              };
            } else if (i === 1 && activeWeek) {
              return {
                ...data,
                title: (activeWeek as any)?.title,
                route: `/courses/${courseId}/overview?activeCohort=${activeCohort}&activeModule=${activeModule}&activeWeek=${
                  (activeWeek as any)?.id
                }`,
              };
            } else {
              return { ...data };
            }
          }),
        };
      });
    }

    // eslint-disable-next-line
  }, [modules, activeModule, activeWeek]);

  useEffect(() => {
    if (activeWeek && screenWidthState < 768) {
      weeksContainer.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }

    // eslint-disable-next-line
  }, [activeWeek]);

  // Refs
  const weeksContainer = useRef<HTMLElement | null>(null);

  if (getCourseModulesObject.isLoading || iseCourseDetail.isLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.breadCrumbContainer}>
        <Breadcrumbs {...breadCrumbs} />
      </div>
      {/* <div className={classes.mobileHeader}>
        <CourseInteractionLayoutMobileHeader />
      </div> */}
      <div className={classes.body}>
        <CourseLessonsNameAndProgress />

        <div className={classes.bodyFlex}>
          <CourseLessonModuleList />
          <section ref={weeksContainer}>
            {activeWeek ? (
              <CourseLessonWeek />
            ) : (
              <div className={classes.noCourseWeek}>
                Please select a course week to preview
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default CourseLessonsContainer;
