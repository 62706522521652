import { useContext, useEffect } from "react";
import Button from "../../Components/Button/Button";
import AcceptedModal from "../../Components/Modals/AcceptedModal/AcceptedModal";
import ProfileSectionContainer from "../../Components/ProfileSectionContainer/ProfileSectionContainer";
import { AuthUserContext } from "../../Context/AuthUserContext";
import DeleteSuccessfulModalBody from "./DeleteSuccessfulModalBody";
import classes from "./ProfileAccountCloseAccount.module.css";

const ProfileAccountCloseAccount = () => {
  // Context
  const { closeAccount, closeAccountObject, setCloseAccountObject, logout } =
    useContext(AuthUserContext);

  // Utils
  const closeDisclaimers = [
    "Closing your account is a permanent action. You can’t undo it.",
    "If you close your account and later decide to create a new one, you will not be able to use the same username or email address.",
    "If you have any active subscriptions, they will be canceled.",
  ];

  useEffect(() => {
    setCloseAccountObject((prevState) => {
      return { ...prevState, data: null };
    });

    // eslint-disable-next-line
  }, []);
  return (
    <ProfileSectionContainer
      header="Close account"
      paragraph="Delete your account permanently"
    >
      {/* {closeAccountObject.data && (
        <AcceptedModal
          onClick={() => {
            setDisplauDeleteDisclaimeeModal(false);
          }}
          body={
            <DeleteDisclaimerModalBody
              onClick={() => {
                setDisplauDeleteDisclaimeeModal(false);
              }}
              onClick2={() => {
                setDisplauDeleteDisclaimeeModal(false);
                setDisplayDeletedModal(true);
              }}
            />
          }
        />
      )} */}
      {closeAccountObject?.data && (
        <AcceptedModal
          onClick={() => {
            setCloseAccountObject((prevState) => {
              return { ...prevState, data: null };
            });
          }}
          body={
            <DeleteSuccessfulModalBody
              onClick={() => {
                setCloseAccountObject((prevState) => {
                  return { ...prevState, data: null };
                });
                logout();
              }}
            />
          }
        />
      )}
      <div className={classes.container}>
        <h4>Warning</h4>
        <ul>
          {closeDisclaimers.map((data, i) => {
            return <li key={i}>{data}</li>;
          })}
          <li>
            If you have any questions, please contact us before closing your
            account.
          </li>
        </ul>

        <Button
          type="invalid"
          loading={closeAccountObject?.isLoading}
          onClick={() => {
            closeAccount();
          }}
        >
          Close account
        </Button>
      </div>
    </ProfileSectionContainer>
  );
};

export default ProfileAccountCloseAccount;
