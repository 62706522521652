import classes from "./KeepPushingRightCta.module.css";
import keepPushing from "../../Assets/Images/keepPushing.svg";

const KeepPushingRightCta = () => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <p>Keep pushing forward!</p>
        <img src={keepPushing} alt="Keep pushing" />
      </div>
      <p>
        Embrace your potential. With perseverance and dedication, there are no
        limits to what you can achieve
      </p>
    </div>
  );
};

export default KeepPushingRightCta;
