import { ChangeEventHandler } from "react";
import classes from "./CheckBox.module.css";

type CheckBoxProps = {
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  readonly?: boolean;
};

const CheckBox = ({ checked, onChange, readonly }: CheckBoxProps) => {
  return (
    <label className={classes.container}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        readOnly={readonly}
      />
      <span className={classes.checkmark}></span>
    </label>
  );
};

export default CheckBox;
