import { useNavigate } from "react-router-dom";
import CheckBox from "../../Components/CheckBox/CheckBox";
import RadioButton from "../../Components/RadioButton/RadioButton";
import { questions } from "../CourseLessonQuizQuestionContaoner/CourseLessonQuizQuestionContaoner";
import classes from "./ReviewQuizAnswers.module.css";

const ReviewQuizAnswers = () => {
  // Router
  const navigate = useNavigate();

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <h4>Review your answers</h4>
        <span
          onClick={() => {
            navigate(-1);
          }}
        >
          <span>Close</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6 18L18 6M6 6L18 18"
              stroke="#664EFE"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>

      <div className={classes.questionSection}>
        {questions.map((data, i) => {
          return (
            <div key={i} className={classes.question}>
              <div className={classes.indexSection}>
                ({questions.indexOf(data) + 1}/{questions.length})
              </div>
              <h4>{data?.question}</h4>
              <span className={classes.questionSelectMode}>
                {data?.questionType === "monoChoice"
                  ? "(Choose one answer)"
                  : "(Select all that apply)"}
              </span>

              <div className={classes.optionSection}>
                {data?.options.map((datum, j) => {
                  return (
                    <div
                      className={`${classes.anseer} ${
                        datum.isCorrect ? classes.isCorrect : undefined
                      }`}
                      key={j}
                    >
                      <span>
                        {data?.questionType === "monoChoice" ? (
                          <RadioButton readonly checked={datum.isCorrect} />
                        ) : (
                          <CheckBox checked={datum.isCorrect} readonly />
                        )}
                      </span>
                      <span>{datum.option}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ReviewQuizAnswers;
