import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { AuthUserContext } from "../../Context/AuthUserContext";
import GetStartedRightCta from "../GetStartedRightCta/GetStartedRightCta";
import KeepPushingRightCta from "../KeepPushingRightCta/KeepPushingRightCta";
import ProfileProgressRightCta from "../ProfileProgressRightCta/ProfileProgressRightCta";
import ReferAFriendRightCta from "../ReferAFriendRightCta/ReferAFriendRightCta";
import ScheduleRightCta from "../ScheduleRightCta/ScheduleRightCta";
import classes from "./RightCtaContainer.module.css";

const RightCtaContainer = () => {
  // Context
  const { showGetStarted } = useContext(AppContext);
  const { user } = useContext(AuthUserContext);

  return (
    <section className={classes.container}>
      {user?.data?.profile_completion_percentage !== 100 && (
        <ProfileProgressRightCta />
      )}
      <KeepPushingRightCta />
      {showGetStarted.rightCta && <GetStartedRightCta />}
      <ScheduleRightCta />
      <ReferAFriendRightCta />
    </section>
  );
};

export default RightCtaContainer;
