import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import AuthUserContextProvider from "./Context/AuthUserContext";
import CourseContextProvider from "./Context/CourseContext";
import AppContextProvider from "./Context/AppContext";
import Toast from "./Components/Toast/Toast";
import PaymentContextProvider from "./Context/PaymentContext";
import ReferralContextProvider from "./Context/ReferralContext";
import SupportContextProvider from "./Context/SupportContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID as string}
    >
      <AppContextProvider>
        <AuthUserContextProvider>
          <PaymentContextProvider>
            <CourseContextProvider>
              <ReferralContextProvider>
                <SupportContextProvider>
                  <React.StrictMode>
                    <Toast>
                      <App />
                    </Toast>
                  </React.StrictMode>
                </SupportContextProvider>
              </ReferralContextProvider>
            </CourseContextProvider>
          </PaymentContextProvider>
        </AuthUserContextProvider>
      </AppContextProvider>
    </GoogleOAuthProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
