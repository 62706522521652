import moment from "moment";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import classes from "./DashboardOneEnrolled.module.css";

type DashboardOneEnrolledProps = {
  image?: string;
  title?: string;
  cohortStart?: Date;
  id: string;
};

const DashboardOneEnrolled = ({
  image,
  title,
  cohortStart,
  id,
}: DashboardOneEnrolledProps) => {
  const formatDate = (dateString: Date) => {
    if (!dateString) {
      return "not known at this time";
    }
    const date = moment(dateString);

    const formattedDate = date.format("Do MMMM, YYYY");

    return formattedDate;
  };

  //   Navigate
  const navigate = useNavigate();

  return (
    <section className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.textSection}>
          <h4>You are enrolled to {title || "this course"}</h4>
          <p>
            The next cohort starts{" "}
            <span>{formatDate(cohortStart as Date) || "soon"}</span>
          </p>
          <Button
            onClick={() => {
              navigate(`/courses/${id}`);
            }}
          >
            <span>View course </span>
            <svg
              width="16"
              height="9"
              viewBox="0 0 16 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.75 1.5L14.75 4.5M14.75 4.5L11.75 7.5M14.75 4.5L1.25 4.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
        </div>
        <div className={classes.imageSection}>
          <img src={image} alt={title || ""} />
        </div>
      </div>
    </section>
  );
};

export default DashboardOneEnrolled;
