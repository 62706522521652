import Layout from "../Components/Layout/Layout";
import ProgramAdvisorHeroSection from "../Containers/ProgramAdvisorHeroSection/ProgramAdvisorHeroSection";
// import ProgramAdvisors from "../Containers/ProgramAdvisors/ProgramAdvisors";
import ProgramAdvisorsForm from "../Containers/ProgramAdvisorsForm/ProgramAdvisorsForm";
import ProgramAdvisorSimpleSteps from "../Containers/ProgramAdvisorSimpleSteps/ProgramAdvisorSimpleSteps";

const ProgramAdvisor = () => {
  return (
    <Layout closeSideNav>
      <ProgramAdvisorHeroSection />
      <ProgramAdvisorSimpleSteps />
      {/* <ProgramAdvisors /> */}
      <ProgramAdvisorsForm />
    </Layout>
  );
};

export default ProgramAdvisor;
