import { useEffect, useRef } from "react";
import CourseCard from "../CourseCard/CourseCard";
import classes from "./CoursesCarousel.module.css";
import Loader from "../Loader/Loader";

type CoursesCarouselProps = {
  header: string;
  description: string;
  data: any[];
  callFunction?: () => void;
  loading?: boolean;
};

const CoursesCarousel = ({
  header,
  description,
  data,
  callFunction,
  loading,
}: CoursesCarouselProps) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  // Effects
  useEffect(() => {
    if (callFunction) {
      callFunction();
    }

    // eslint-disable-next-line
  }, []);

  const scrollToNextDivItem = () => {
    if (carouselRef.current) {
      const container = carouselRef.current;
      const scrollAmount = container.offsetWidth;
      container.scrollLeft += scrollAmount;
    }
  };

  const scrollToPrevDivItem = () => {
    if (carouselRef.current) {
      const container = carouselRef.current;
      const scrollAmount = container.offsetWidth;
      container.scrollLeft -= scrollAmount;
    }
  };

  if (loading === true) {
    return (
      <section className={classes.container}>
        <Loader />
      </section>
    );
  }
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <div>
          <h2>{header}</h2>
          <p>{description}</p>
        </div>
        <div>
          <span onClick={scrollToPrevDivItem}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15 19L8 12L15 5"
                stroke="#A79AFE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <span onClick={scrollToNextDivItem}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9 5L16 12L9 19"
                stroke="#A79AFE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
      <div className={classes.courseSection} ref={carouselRef}>
        {data &&
          data.map((data, i) => {
            return (
              <div key={i}>
                <CourseCard
                  title={data?.name}
                  image={data?.cover_image}
                  description={data?.description}
                  ratings={4}
                  id={data?.id}
                  price={data?.price}
                />
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default CoursesCarousel;
