import { useContext, useEffect } from "react";
import Layout from "../Components/Layout/Layout";
import ActiveUserCourses from "../Containers/ActiveUserCourses/ActiveUserCourses";
import { CourseContext } from "../Context/CourseContext";
import NoCourseSelectedCourses from "../Containers/NoCourseSelectedCourses/NoCourseSelectedCourses";
import Loader from "../Components/Loader/Loader";

const Courses = () => {
  // Context
  const { getCourses, getUserEnrolledCoursesCount, userEnrolledCOursesCount } =
    useContext(CourseContext);

  // Effects
  useEffect(() => {
    getCourses(true);
    getUserEnrolledCoursesCount();

    // eslint-disable-next-line
  }, []);

  if (userEnrolledCOursesCount.isLoading) {
    return (
      <Layout displayRightCta>
        <Loader />
      </Layout>
    );
  }

  return (
    <Layout>
      {userEnrolledCOursesCount?.data?.courseCount > 0 ? (
        <>
          <ActiveUserCourses />
        </>
      ) : (
        <>
          <NoCourseSelectedCourses />
        </>
      )}
    </Layout>
  );
};

export default Courses;
