import { useContext, useEffect } from "react";
import { AuthUserContext } from "../../Context/AuthUserContext";
import UserOnboardingLayout from "../../Components/UserOnboardingLayout/UserOnboardingLayout";
import UserGetToKnowYou from "../UserGetToKnowYou/UserGetToKnowYou";
import UserBackgroundAndSkills from "../UserBackgroundAndSkills/UserBackgroundAndSkills";
import UserProfileCreated from "../UserProfileCreated/UserProfileCreated";

const UserOnboarding = () => {
  // Context
  const { setSearchParams } = useContext(AuthUserContext);

  // Search Params
  const currentParams = new URLSearchParams(window.location.search);
  const userStep = currentParams.get("step");

  useEffect(() => {
    if (!userStep) {
      currentParams.set("step", "1");
      setSearchParams(currentParams.toString());
    }

    // eslint-disable-next-line
  }, [userStep]);

  return (
    <UserOnboardingLayout>
      {userStep === "1" ? (
        <UserGetToKnowYou />
      ) : userStep === "2" ? (
        <UserBackgroundAndSkills />
      ) : userStep === "3" ? (
        <UserProfileCreated />
      ) : null}
    </UserOnboardingLayout>
  );
};

export default UserOnboarding;
