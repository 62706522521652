import { useContext, useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import { AuthUserContext } from "../../Context/AuthUserContext";
import classes from "./UserGetToKnowYou.module.css";
import noProfileImage from "../../Assets/Images/noProfileImage.svg";
import Input from "../../Components/Input/Input";
import DropdownWithSearch from "../../Components/DropdownWithSearch/DropdownWithSearch";
import TextArea from "../../Components/TextArea/TextArea";
import { inputChangeHandler } from "../../HelperFunctions/inputChangeHandler";

const UserGetToKnowYou = () => {
  // Context
  const {
    setSearchParams,
    fetchCountries,
    countriesRequestObject,
    completeProfileData,
    setCompleteProfileData,
    country,
    setCountry,
    gender,
    setGender,
  } = useContext(AuthUserContext);

  // States
  const [userImage, setUserImage] = useState<{
    frontendFile: null | string;
    file: null | string;
  }>({
    file: null,
    frontendFile: null,
  });

  //   Effects
  useEffect(() => {
    setSearchParams((params) => {
      params.delete("isVerified");
      return params;
    });

    setSearchParams({
      step: "1",
    });

    fetchCountries();

    // eslint-disable-next-line
  }, []);

  // Utils
  const imageHandler = (e: any) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setUserImage((prevState: any) => {
          return { ...prevState, frontendFile: reader.result };
        });
      }
    };
    reader.readAsDataURL(e.target.files[0]);

    setUserImage((prevState: any) => {
      return { ...prevState, file: e.target.files[0] };
    });

    setCompleteProfileData((prevState) => {
      return { ...prevState, profile_image: e.target.files[0] };
    });
  };

  // Effects
  useEffect(() => {
    if (gender) {
      setCompleteProfileData((prevState) => {
        return { ...prevState, gender: gender };
      });
    }
    if (country) {
      setCompleteProfileData((prevState) => {
        return { ...prevState, country: country };
      });
    }

    // eslint-disable-next-line
  }, [gender, country]);

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <h4>Let’s get to know you!</h4>
        <p>You can always edit this later</p>
      </div>
      <div className={classes.profilePhoto}>
        <p>Upload your profile picture</p>
        <div>
          <img
            src={
              !userImage.frontendFile
                ? noProfileImage
                : (userImage?.frontendFile as string)
            }
            alt="Profile"
          />
          <div className={classes.buttonUpper}>
            <input type="file" id="profilePhoto" onChange={imageHandler} />
            <label htmlFor="profilePhoto">Browse</label>
            <Button type="secondary">Remove</Button>
          </div>
        </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div>
          <Input
            label="Name"
            name="full_name"
            isRequired
            errorMessage="Check the name and try again"
            placeholder="e.g John Doe"
            onChange={(e) => {
              inputChangeHandler(e, setCompleteProfileData);
            }}
            value={completeProfileData.full_name}
          />
        </div>
        <div>
          <DropdownWithSearch
            title="Select"
            options={["Male", "Female", "Prefer not to say"]}
            label="Gender"
            isRequired
            errorMessage="Select an option to continue "
            selected={gender}
            setSelected={setGender}
          />
        </div>
        <div>
          <DropdownWithSearch
            title="Select"
            options={(countriesRequestObject.data as any[])
              ?.map((data: any) => {
                return data?.name?.common;
              })
              .sort()}
            label="Country"
            selected={country}
            setSelected={setCountry}
            isLoading={countriesRequestObject.isLoading}
            isRequired
            errorMessage="Select an option to continue "
          />
        </div>
        <div>
          <TextArea
            placeholder="Tell us more about you like your achievements "
            name="bio"
            label="Bio"
            onChange={(e) => {
              inputChangeHandler(e, setCompleteProfileData);
            }}
            value={completeProfileData.bio}
          />
        </div>
        <div className={classes.buttonSection}>
          <Button
            onClick={() => {
              setSearchParams({
                step: "2",
              });
            }}
            disabled={!country || !gender || !completeProfileData?.full_name}
          >
            <span>
              <span>Next</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M17.5 8.5L21.5 12.5M21.5 12.5L17.5 16.5M21.5 12.5L3.5 12.5"
                  stroke="#A79AFE"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </Button>
        </div>
      </form>
    </section>
  );
};

export default UserGetToKnowYou;
