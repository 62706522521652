import { useContext } from "react";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./CourseLessonsNameAndProgress.module.css";

const CourseLessonsNameAndProgress = () => {
  // Context
  const { iseCourseDetail } = useContext(CourseContext);

  return (
    <section className={classes.container}>
      <h4>{iseCourseDetail?.data?.name || "Course name"}</h4>
      <ProgressBar percentage={0} color="#fff" />
    </section>
  );
};

export default CourseLessonsNameAndProgress;
