import Button from "../../Components/Button/Button";
import classes from "./CourseLessonQuizQuestionContaoner.module.css";
import lessonQuizPass from "../../Assets/Images/lessonQuizPass.svg";
import { useNavigate, useParams } from "react-router-dom";

type CourseLessonQuizResultsModalProps = {
  onClick: () => void;
};

export const CourseLessonQuizResultsModalFail = ({
  onClick,
}: CourseLessonQuizResultsModalProps) => {
  return (
    <div className={classes.modalContainer}>
      <img src={lessonQuizPass} alt="Lesson Pass" />
      <h4>Oh, snap! You missed a few questions</h4>
      <p style={{ color: "#DC362E" }}>
        You score is 54% <span>Retake quiz</span>
      </p>
      <div className={classes.modalButtonSection}>
        <Button onClick={onClick}>Done</Button>
      </div>
    </div>
  );
};

const CourseLessonQuizResultsModal = ({
  onClick,
}: CourseLessonQuizResultsModalProps) => {
  // Router
  const { courseId, week, lessonType, lesson } = useParams();
  const navigate = useNavigate();

  return (
    <div className={classes.modalContainer}>
      <img src={lessonQuizPass} alt="Lesson Pass" />
      <h4>Great job! You’re crushing this.</h4>
      <p>
        You score is 80% <span>Retake quiz</span>
      </p>
      <div className={classes.modalButtonSection}>
        <Button
          type="secondary"
          onClick={() => {
            navigate(
              `/courses/${courseId}/${week}/${lessonType}/${lesson}/quiz-question/review`
            );
          }}
        >
          Review answers
        </Button>
        <Button onClick={onClick}>Done</Button>
      </div>
    </div>
  );
};

export default CourseLessonQuizResultsModal;
