import classes from "./CourseResources.module.css";
import { useState } from "react";
import SectionsNav3 from "../../Components/SectionsNav3/SectionsNav3";
import CourseResourcesByModule from "../CourseResourcesByModule/CourseResourcesByModule";

const CourseResources = () => {
  // States
  const [navItems, setNavItems] = useState<any[]>([
    {
      title: "Module 1",
      isActive: true,
      activeComponent: <CourseResourcesByModule />,
    },
    {
      title: "Module 2",
      isActive: false,
      activeComponent: null,
    },
    {
      title: "Module 3",
      isActive: false,
      activeComponent: null,
    },
    {
      title: "Module 4",
      isActive: false,
      activeComponent: null,
    },
  ]);

  //   Utils
  const activeComponent = navItems.find((data) => data.isActive);

  return (
    <section className={classes.container}>
      <SectionsNav3 navItems={navItems} setNavItems={setNavItems} />
      {activeComponent.activeComponent}
    </section>
  );
};

export default CourseResources;
