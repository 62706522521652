import { useContext, useEffect } from "react";
import DropdownWithSearch from "../../Components/DropdownWithSearch/DropdownWithSearch";
import Loader from "../../Components/Loader/Loader";
import { CourseContext } from "../../Context/CourseContext";
import { capitalize } from "../../HelperFunctions/capitalize";
import { gradeHandler } from "../../HelperFunctions/gradeHandler";
import classes from "./CourseGrades.module.css";

const CourseGrades = () => {
  // Context
  const { getCourseGrades, getCourseGradesObject } = useContext(CourseContext);

  const courseWeightedGrade = () => {
    let totalGrade = 0;
    for (let i = 0; i < getCourseGradesObject?.data?.length; i++) {
      totalGrade += Number(getCourseGradesObject?.data[i].grade);
    }

    const average = totalGrade / getCourseGradesObject?.data?.length;

    return average;
  };

  useEffect(() => {
    getCourseGrades();

    // eslint-disable-next-line
  }, []);

  if (getCourseGradesObject.isLoading) {
    return (
      <section className={classes.container}>
        <Loader />
      </section>
    );
  }

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <h4>Projects and Assignment Grades</h4>
        <div>
          <DropdownWithSearch title="Select" options={[]} />
        </div>
      </div>

      {getCourseGradesObject?.data &&
      getCourseGradesObject?.data?.length > 0 ? (
        <>
          <div className={classes.tableHeader}>
            <span>S/N</span>
            <span>Item</span>
            <span>Grade</span>
            <span>Score</span>
          </div>
          <div className={classes.tableBodyContainer}>
            {getCourseGradesObject?.data &&
            getCourseGradesObject?.data.length > 0 ? (
              getCourseGradesObject?.data.map(
                (
                  data: {
                    title: string;
                    id: number;
                    grade: string;
                    assignment: { id: number; title: string };
                  },
                  i: number
                ) => {
                  return (
                    <div className={classes.tableBody} key={i}>
                      <span>{i + 1}</span>
                      <span>{capitalize(data?.assignment?.title || "")}</span>
                      <span>{gradeHandler(Number(data?.grade))}</span>
                      <span>{data?.grade}%</span>
                    </div>
                  );
                }
              )
            ) : (
              <div>No grades available at this time</div>
            )}
          </div>

          <div className={classes.weightedGrade}>
            <p>Current weighted grade: {courseWeightedGrade()}%</p>
          </div>
        </>
      ) : (
        <div className={classes.noGrades}>No grades available at this time</div>
      )}
    </section>
  );
};

export default CourseGrades;
