import { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../Components/Button/Button";
import { AuthUserContext } from "../../Context/AuthUserContext";
import classes from "./EligibilityPreview.module.css";

const EligibilityPreview = () => {
  // COntext

  const { getEligibilityQuestionsrequest } = useContext(AuthUserContext);

  // Utils
  const whatToExpect = [
    {
      text: "Simple multiple-choice questions",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <path
            d="M19.8895 21.3338C20.7784 20.6671 20.7784 19.3338 19.8895 18.6671L11.0007 12.0004C9.90192 11.1764 8.33398 11.9603 8.33398 13.3338L8.33398 26.6671C8.33399 28.0405 9.90192 28.8245 11.0007 28.0004L19.8895 21.3338Z"
            stroke="#2E2E2E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.2229 21.3338C34.1118 20.6671 34.1118 19.3338 33.2229 18.6671L24.334 12.0004C23.2353 11.1764 21.6673 11.9603 21.6673 13.3338L21.6673 26.6671C21.6673 28.0405 23.2353 28.8245 24.334 28.0004L33.2229 21.3338Z"
            stroke="#2E2E2E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },

    {
      text: "No lengthy essay questions",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <path
            d="M8.33398 21.666L15.0007 28.3327L31.6673 11.666"
            stroke="#2E2E2E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },

    {
      text: "No payment or commitment required",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <path
            d="M23.5758 15.6554C23.9377 16.0725 24.5693 16.1173 24.9865 15.7553C25.4036 15.3933 25.4483 14.7618 25.0864 14.3446L23.5758 15.6554ZM16.4242 24.3446C16.0623 23.9275 15.4307 23.8827 15.0136 24.2447C14.5964 24.6067 14.5517 25.2382 14.9136 25.6554L16.4242 24.3446ZM21 11.6667C21 11.1144 20.5523 10.6667 20 10.6667C19.4477 10.6667 19 11.1144 19 11.6667H21ZM19 28.3333C19 28.8856 19.4477 29.3333 20 29.3333C20.5523 29.3333 21 28.8856 21 28.3334L19 28.3333ZM34 20C34 27.732 27.732 34 20 34V36C28.8366 36 36 28.8366 36 20H34ZM20 34C12.268 34 6 27.732 6 20H4C4 28.8366 11.1634 36 20 36V34ZM6 20C6 12.268 12.268 6 20 6V4C11.1634 4 4 11.1634 4 20H6ZM20 6C27.732 6 34 12.268 34 20H36C36 11.1634 28.8366 4 20 4V6ZM20 19C18.7892 19 17.7382 18.671 17.0192 18.1916C16.2931 17.7076 16 17.1488 16 16.6667H14C14 18.0255 14.8262 19.1333 15.9098 19.8557C17.0004 20.5828 18.4494 21 20 21V19ZM16 16.6667C16 16.1846 16.2931 15.6257 17.0192 15.1417C17.7382 14.6623 18.7892 14.3333 20 14.3333V12.3333C18.4494 12.3333 17.0004 12.7505 15.9098 13.4776C14.8262 14.2 14 15.3078 14 16.6667H16ZM20 14.3333C21.6441 14.3333 22.9497 14.9338 23.5758 15.6554L25.0864 14.3446C23.9835 13.0736 22.0574 12.3333 20 12.3333V14.3333ZM20 21C21.2108 21 22.2618 21.329 22.9809 21.8084C23.7069 22.2924 24 22.8512 24 23.3333H26C26 21.9745 25.1739 20.8667 24.0902 20.1443C22.9996 19.4172 21.5506 19 20 19V21ZM19 11.6667V13.3333H21V11.6667H19ZM19 26.6666L19 28.3333L21 28.3334L21 26.6667L19 26.6666ZM20 25.6667C18.356 25.6667 17.0504 25.0662 16.4242 24.3446L14.9136 25.6554C16.0166 26.9264 17.9427 27.6667 20 27.6667L20 25.6667ZM24 23.3333C24 23.8154 23.7069 24.3743 22.9809 24.8583C22.2618 25.3377 21.2109 25.6667 20 25.6667V27.6667C21.5506 27.6667 22.9997 27.2495 24.0903 26.5224C25.1739 25.8 26 24.6922 26 23.3333H24ZM19 13.3333L19 26.6667L21 26.6667L21 13.3333L19 13.3333Z"
            fill="#2E2E2E"
          />
        </svg>
      ),
    },
  ];

  //   Router
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <h4>Discover your eligibility: Let's determine if we're a match</h4>
        <p>
          Ready to embark on your machine learning journey? Take five minutes to
          fill out our eligibility form and get started
        </p>
      </div>

      <div className={classes.whatToExpect}>
        <p>What to expect</p>
        <div className={classes.arrayContainer}>
          {whatToExpect.map((data, i) => {
            return (
              <div key={i} className={classes.expectation}>
                {data.icon}
                <p>{data.text}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className={classes.buttonSection}>
        <Button
          type="secondary"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          <span className={classes.buttonInner}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7 16L3 12M3 12L7 8M3 12L21 12"
                stroke="#664EFE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Back to dashboard</span>
          </span>
        </Button>
        <Button
          onClick={() => {
            setSearchParams({ step: "1" });
          }}
          loading={getEligibilityQuestionsrequest.isLoading}
        >
          <span className={classes.buttonInner}>
            <span>Continue</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M17.5 8L21.5 12M21.5 12L17.5 16M21.5 12L3.5 12"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </Button>
      </div>
    </section>
  );
};

export default EligibilityPreview;
