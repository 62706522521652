export const countdown = (targetDate: any) => {
  const now = new Date().getTime();

  const timeRemaining = targetDate - now;

  if (timeRemaining <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  return { days: days, hours: hours, minutes: minutes, seconds: seconds };
};

export const countdownMinutesSeconds = (
  minutes: number,
  onUpdate: (time: { minutes: number; seconds: number }) => void,
  onFinish: () => void
) => {
  let remainingSeconds = minutes * 60;

  const intervalId = setInterval(() => {
    const remainingMinutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    onUpdate({ minutes: remainingMinutes, seconds: seconds });

    if (remainingSeconds <= 0) {
      clearInterval(intervalId);
      onFinish();
    } else {
      remainingSeconds--;
    }
  }, 1000); // Update every second

  // Return the intervalId so it can be cleared if needed
  return intervalId;
};
