import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import { CourseContext } from "../../Context/CourseContext";
import { countdown } from "../../HelperFunctions/countdown";
import classes from "./CourseDetailWhenWeStart.module.css";

const CourseDetailWhenWeStart = () => {
  // Context
  const { iseCourseDetail } = useContext(CourseContext);

  // Router
  const navigate = useNavigate();

  // State
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const startDate = new Date(
    iseCourseDetail?.data?.cohort?.start_date
  ).getTime();
  const endDate = new Date(iseCourseDetail?.data?.cohort?.end_date).getTime();

  useEffect(() => {
    if (startDate) {
      const countdownToStartDate = setInterval(() => {
        setTimeLeft(countdown(endDate));
      }, 1000);

      return () => {
        clearInterval(countdownToStartDate);
      };
    }
  });

  const formatDate = (dateString: Date) => {
    if (!dateString) {
      return "not known at this time";
    }
    const date = moment(dateString);

    const formattedDate = date.format("Do MMMM, YYYY");

    return formattedDate;
  };

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <SectionsHeader
          header="Next cohort starts"
          paragraph="BEGIN YOUR APPLICATION"
          style={{ textAlign: "center" }}
        />
      </div>
      <div className={classes.daysCounter}>
        <div>
          <div>
            <span>{timeLeft.days}</span>
          </div>
          <div>
            <span>Days</span>
          </div>
        </div>

        <div>
          <div>
            <span>{timeLeft.hours}</span>
          </div>
          <div>
            <span>Hours</span>
          </div>
        </div>

        <div>
          <div>
            <span>{timeLeft.minutes}</span>
          </div>
          <div>
            <span>Minutes</span>
          </div>
        </div>

        <div>
          <div>
            <span>{timeLeft.seconds}</span>
          </div>
          <div>
            <span>Seconds</span>
          </div>
        </div>
      </div>
      <div className={classes.deadline}>
        <div>
          <p>Course Starts</p>
          <p>
            <span>The next cohort starts</span>{" "}
            {formatDate(iseCourseDetail?.data?.cohort?.start_date)}
          </p>
        </div>

        <div>
          <p>Application deadline</p>
          <p>
            <span>Deadline for application is</span>{" "}
            {formatDate(iseCourseDetail?.data?.cohort?.application_deadline)}
          </p>
        </div>
      </div>
      <div className={classes.buttonSection}>
        <Button
          onClick={() => {
            navigate(`/eligibility/${iseCourseDetail?.data?.id}`);
          }}
        >
          Enroll now
        </Button>
      </div>
    </section>
  );
};

export default CourseDetailWhenWeStart;
