import { Dispatch, SetStateAction, useContext, useEffect, useRef } from "react";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./HelloWithSearch.module.css";

type HelloWithSearchProps = {
  setDisplayCourseList?: Dispatch<SetStateAction<boolean>>;
};

const HelloWithSearch = ({ setDisplayCourseList }: HelloWithSearchProps) => {
  // Context
  const { setSearchisActive, setSearchParams } = useContext(CourseContext);

  const currentSearchParams = new URLSearchParams(window.location.search);
  const sesarchedParam = currentSearchParams.get("search");

  // Refs
  const searchContainer = useRef<HTMLElement>(null);

  // Effects
  useEffect(() => {
    const removeDropdownHandler = (e: any) => {
      if (
        searchContainer &&
        searchContainer?.current?.contains(e.target) &&
        setDisplayCourseList
      ) {
        setDisplayCourseList(false);
      } else {
      }
    };
    document.addEventListener("mousedown", removeDropdownHandler);

    return () => {
      document.removeEventListener("mousedown", removeDropdownHandler);
    };
  });

  return (
    <section className={classes.container} ref={searchContainer}>
      <h4>Welcome to your Course Dashboard</h4>
      <div className={classes.inputSection}>
        <input
          type="text"
          placeholder="Search for course"
          onFocus={() => {
            setSearchisActive(true);
            if (setDisplayCourseList) {
              setDisplayCourseList(true);
            }
          }}
          onBlur={() => {
            setSearchisActive(false);
            // if (setDisplayCourseList) {
            //   setDisplayCourseList(false);
            // }
          }}
          value={(sesarchedParam as string) || ""}
          onChange={(e) => {
            currentSearchParams.set("search", e.target.value);
            setSearchParams(currentSearchParams.toString());

            if (e.target.value.length === 0) {
              currentSearchParams.set("search", "");
              setSearchParams(currentSearchParams.toString());
            }
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
            stroke="#2E2E2E"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </section>
  );
};

export default HelloWithSearch;
