import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import { CourseContext } from "../../Context/CourseContext";
import classes from "./CourseDetailKickStart.module.css";

const CourseDetailKickStart = () => {
  // Context
  const { iseCourseDetail } = useContext(CourseContext);

  // Navigate
  const navigate = useNavigate();
  return (
    <section className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.header}>
          <SectionsHeader
            header={`Kickstart your career in ${
              iseCourseDetail?.data?.name || "this course"
            }`}
            paragraph="ARE YOU READY?"
            style={{ textAlign: "center" }}
          />
        </div>

        <div className={classes.buttonSection}>
          <Button type="secondary" disabled>
            Download curriculum
          </Button>
          <Button
            onClick={() => {
              navigate(`/eligibility/${iseCourseDetail?.data?.id}`);
            }}
          >
            Get started
          </Button>
        </div>
      </div>
    </section>
  );
};

export default CourseDetailKickStart;
