import classes from "../UserGetToKnowYou/UserGetToKnowYou.module.css";
import Button from "../../Components/Button/Button";
import subClasses from "./UserProfileCreated.module.css";
import { useNavigate } from "react-router-dom";

const UserProfileCreated = () => {
  // Navigate
  const navigate = useNavigate();
  return (
    <section className={classes.container}>
      <div className={`${subClasses.header} ${classes.header}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
        >
          <path
            d="M10 10C10 7.79086 11.7909 6 14 6H34C36.2091 6 38 7.79086 38 10V42L24 35L10 42V10Z"
            stroke="#664EFE"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h4>Profile created</h4>
        <p>Your profile has been updated</p>
        <p>
          Thank you for choosing iṣẹ́ EdTech. Get ready for an amazing tech
          journey.
        </p>
      </div>

      <div className={`${subClasses.buttonSection} ${classes.buttonSection}`}>
        <Button
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          <span>
            <span>Go to dashboard</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M17.5 8.5L21.5 12.5M21.5 12.5L17.5 16.5M21.5 12.5L3.5 12.5"
                stroke="#A79AFE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </Button>
      </div>
    </section>
  );
};

export default UserProfileCreated;
