import { useContext, useEffect, useRef, useState } from "react";
import classes from "./CoursesFilter.module.css";
import { activeToggler } from "../../HelperFunctions/activeTogglers";
import { CourseContext } from "../../Context/CourseContext";

const CoursesFilter = () => {
  // Context
  const {
    handleFilterChange,
    searchParams,
    clearFilters,
    schools,
    getSchoolFilter,
  } = useContext(CourseContext);

  // Utils
  const [filters, setFilters] = useState([
    {
      title: "Schools",
      isActive: false,
      filterName: "school",

      options: [{ id: 0, title: "All schools" }],
    },
  ]);

  //   Ref
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Router
  const activeFilters = searchParams
    .get("school")
    ?.split(",")
    .filter((data) => {
      return data !== "0";
    })
    .join();

  // Effects
  useEffect(() => {
    getSchoolFilter();
    if (activeFilters) {
      handleFilterChange();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (schools?.data) {
      const filtersCopy = filters.map((data, i) => {
        if (i === 0) {
          return {
            ...data,
            options: [
              { id: 0, title: "All schools" },
              ...schools.data?.map((data: { id: number; name: string }) => {
                return { title: data?.name, id: data?.id };
              }),
            ],
          };
        } else {
          return { ...data };
        }
      });

      setFilters(filtersCopy);
    }

    // eslint-disable-next-line
  }, [schools.data]);

  useEffect(() => {
    const removeDropdownHandler = (e: any) => {
      if (dropdownRef && !dropdownRef.current?.contains(e.target)) {
        const filtersCopy = filters.map((data) => {
          return { ...data, isActive: false };
        });

        setFilters(filtersCopy);
      }
    };

    document.addEventListener("mousedown", removeDropdownHandler);

    return () => {
      document.removeEventListener("mousedown", removeDropdownHandler);
    };
  });

  return (
    <div className={classes.container}>
      <div>
        <div>Filter by:</div>
        {filters.map((data, i) => {
          const activeFilterBySection = activeFilters
            ?.toString()
            .split(",")
            .filter((filter) =>
              data.options.some(
                (option: { id: number; title: string }) =>
                  option.id.toString() === filter
              )
            ).length;
          return (
            <div key={i} className={classes.filter}>
              <span
                onClick={() => {
                  activeToggler(i, filters, setFilters);
                }}
              >
                <span>{data.title}</span>
                {activeFilterBySection && activeFilterBySection > 0 ? (
                  <span>{activeFilterBySection}</span>
                ) : null}
              </span>
              {data.isActive && (
                <div className={classes.dropdown} ref={dropdownRef}>
                  {data.options.map(
                    (option: { id: number; title: string }, j: number) => {
                      if (j === 0) {
                        return (
                          <span className={classes.option} key={j}>
                            <input
                              type="checkbox"
                              checked={
                                (activeFilters as string)?.length < 1 ||
                                activeFilters === undefined
                              }
                              name={data.filterName}
                              id={option?.title}
                              readOnly
                            />
                            <label htmlFor={option?.title}>
                              {option?.title}
                            </label>
                          </span>
                        );
                      } else {
                        return (
                          <span className={classes.option} key={j}>
                            <input
                              type="checkbox"
                              checked={
                                activeFilters?.includes(String(option.id)) ||
                                false
                              }
                              name={data.filterName}
                              id={option?.title}
                              onChange={(e) =>
                                handleFilterChange(option.id, data.filterName)
                              }
                            />
                            <label htmlFor={option?.title}>
                              {option?.title}
                            </label>
                          </span>
                        );
                      }
                    }
                  )}
                </div>
              )}
            </div>
          );
        })}
        {activeFilters && (
          <div
            className={classes.clearAll}
            onClick={() => {
              clearFilters();
            }}
          >
            Clear all
          </div>
        )}
      </div>
    </div>
  );
};

export default CoursesFilter;
