import { Dispatch, SetStateAction, useEffect, useState } from "react";
import classes from "./NotificationsContainer.module.css";

type notificationsTyes = {
  text: string;
  from: string;
  date: string;
  isRead: boolean;
}[];

type NotificationsContainerTypes = {
  notifications: notificationsTyes;
  setNotifications: Dispatch<SetStateAction<notificationsTyes | any>>;
  allIsRed: boolean;
  setAllIsRead: Dispatch<SetStateAction<boolean>>;
};

const NotificationsContainer = ({
  notifications,
  setNotifications,
  allIsRed,
  setAllIsRead,
}: NotificationsContainerTypes) => {
  const markAsRead = () => {
    const notificationsCopy: notificationsTyes = notifications.map((data) => {
      return { ...data, isRead: true };
    });

    setNotifications(notificationsCopy);
  };

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <span>Notifications</span>
        <span
          onClick={markAsRead}
          style={allIsRed ? { color: "#b9b9b9" } : undefined}
        >
          Mark all as read
        </span>
      </div>

      {notifications.length < 1 ? (
        <section className={classes.noNotification}>
          No notifications at this time
        </section>
      ) : (
        <div className={classes.notificationsList}>
          {notifications.map((data, i) => {
            return (
              <div key={i} className={classes.notification}>
                <span
                  style={
                    data.isRead
                      ? { backgroundColor: "red" }
                      : { backgroundColor: "#FFD952" }
                  }
                ></span>
                <span>
                  <p>{data?.text}</p>
                  <div>
                    <span>{data?.date}</span>
                    <span>{data?.from}</span>
                  </div>
                </span>
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
};

export default NotificationsContainer;
