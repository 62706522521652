import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./ProgramAdvisorSimpleSteps.module.css";

const ProgramAdvisorSimpleSteps = () => {
  // Utils
  const simpleSteps = [
    {
      header: "Send a  message",
      description:
        "Fill out the form on this page to express your interest in our courses. Our team will promptly reach out to you via email.",
    },
    {
      header: "Schedule a consultation",
      description:
        "Once we receive your message, we'll schedule a personalized one-on-one call to discuss your goals and expectations.",
    },
    {
      header: "Get personalized recommendations",
      description:
        "Our Program advisor will provide personalized course recommendations based on your career goals and learning preferences.",
    },
  ];
  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <SectionsHeader
          paragraph="IN 3 SIMPLE STEPS"
          header="Connect with our Program Advisor"
          style={{ textAlign: "center" }}
        />
      </div>

      <div className={classes.bodyMain}>
        {simpleSteps.map((data, i) => {
          return (
            <div className={classes.step} key={i}>
              <h4>{data.header}</h4>
              <p>{data.description}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ProgramAdvisorSimpleSteps;
