import { useContext } from "react";
import Button from "../../Components/Button/Button";
import { ReferralContext } from "../../Context/ReferralContext";
import classes from "./ReferralPendingInvites.module.css";

const ReferralPendingInvites = () => {
  // Context
  const { studentReferrals } = useContext(ReferralContext);

  return (
    <section className={classes.container}>
      <h4>
        Pending invites (
        {
          studentReferrals?.data?.filter(
            (data: any) => data.status === "pending"
          ).length
        }
        )
      </h4>

      <div className={classes.table}>
        {studentReferrals?.data
          ?.filter((data: any) => data.status === "pending")
          .map((data: any) => {
            return (
              <div key={data?.id} className={classes.tableBody}>
                <span>{data?.referred?.full_name}</span>
                <span>{data?.referred?.email}</span>
                <span>
                  <Button type="null">Remind</Button>
                </span>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default ReferralPendingInvites;
