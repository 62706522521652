import { useContext, useEffect, useState } from "react";
import SectionsNav3 from "../../Components/SectionsNav3/SectionsNav3";
import { CourseContext } from "../../Context/CourseContext";
import CourseMaterialsModuleContainer from "../CourseMaterialsModuleContainer/CourseMaterialsModuleContainer";
import classes from "./CourseMaterials.module.css";

const CourseMaterials = () => {
  // Context
  const { getCourseModulesObject } = useContext(CourseContext);

  const [navItems, setNavItems] = useState(
    getCourseModulesObject?.data?.map((data: any, i: number) => {
      if (i === 0) {
        return {
          ...data,
          name: data?.title,
          title: `Module ${i + 1}`,
          isActive: true,
        };
      }
      return {
        ...data,
        name: data?.title,
        title: `Module ${i + 1}`,
        isActive: false,
      };
    })
  );

  useEffect(() => {
    setNavItems(
      getCourseModulesObject?.data?.map((data: any, i: number) => {
        if (i === 0) {
          return {
            ...data,
            name: data?.title,
            title: `Module ${i + 1}`,
            isActive: true,
          };
        }
        return {
          ...data,
          name: data?.title,
          title: `Module ${i + 1}`,
          isActive: false,
        };
      })
    );
  }, [getCourseModulesObject.data]);

  const activeComponent = navItems?.find((data: any) => data.isActive);
  return (
    <section className={classes.container}>
      <SectionsNav3 navItems={navItems} setNavItems={setNavItems} />
      {activeComponent && (
        <CourseMaterialsModuleContainer activeComponent={activeComponent} />
      )}
    </section>
  );
};

export default CourseMaterials;
