import { useContext, useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import DropdownWithSearch from "../../Components/DropdownWithSearch/DropdownWithSearch";
import ProfileSectionContainer from "../../Components/ProfileSectionContainer/ProfileSectionContainer";
import { AuthUserContext } from "../../Context/AuthUserContext";
import classes from "./ProfileProfileSkillsAndExperience.module.css";

const ProfileProfileSkillsAndExperience = () => {
  // Context
  const {
    updateSkillsHandler,
    updateSkillsHandlerObject,
    setSkillsUpdate,
    user,
  } = useContext(AuthUserContext);

  // State
  const [topSkills, setTopSkills] = useState<null | string[]>([]);
  const [employmentStatus, setEmploymentStatus] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [isEdited, setisEdited] = useState(false);

  // Effects
  useEffect(() => {
    if (user.data) {
      setTopSkills(JSON.parse(user?.data?.top_skills));
      setEmploymentStatus(user?.data?.employement_status);
      setEducationLevel(user?.data?.education);
      setExperienceLevel(user?.data?.experience_level);
    }

    // eslint-disable-next-line
  }, [user.data]);

  useEffect(() => {
    if (experienceLevel) {
      setSkillsUpdate((prevState) => {
        return { ...prevState, experience_level: experienceLevel };
      });
    }

    if (employmentStatus) {
      setSkillsUpdate((prevState) => {
        return { ...prevState, employement_status: employmentStatus };
      });
    }

    if (topSkills) {
      setSkillsUpdate((prevState: any) => {
        return { ...prevState, top_skills: topSkills };
      });
    }

    if (educationLevel) {
      setSkillsUpdate((prevState) => {
        return { ...prevState, education: educationLevel };
      });
    }

    // eslint-disable-next-line
  }, [experienceLevel, topSkills, educationLevel, employmentStatus]);

  useEffect(() => {
    if (user?.data) {
      if (user?.data?.top_skills !== topSkills) {
        setisEdited(true);
      } else {
        setisEdited(false);
      }
    }

    // eslint-disable-next-line
  }, [topSkills]);

  useEffect(() => {
    if (user?.data?.employement_status !== employmentStatus) {
      setisEdited(true);
    } else {
      setisEdited(false);
    }

    // eslint-disable-next-line
  }, [employmentStatus]);

  useEffect(() => {
    if (user?.data?.education !== educationLevel) {
      setisEdited(true);
    } else {
      setisEdited(false);
    }

    // eslint-disable-next-line
  }, [educationLevel]);

  useEffect(() => {
    if (user?.data?.experience_level !== experienceLevel) {
      setisEdited(true);
    } else {
      setisEdited(false);
    }

    // eslint-disable-next-line
  }, [experienceLevel]);

  const [topSkillsText, setTopSkillsText] = useState("");

  useEffect(() => {
    if (topSkillsText && !topSkills?.includes(topSkillsText as string)) {
      setTopSkills((prevsState: any) => {
        if (prevsState) {
          return [...prevsState, topSkillsText];
        } else {
          return [topSkillsText];
        }
      });
    }

    // eslint-disable-next-line
  }, [topSkillsText]);

  const filtertopSkills = (skill: string) => {
    if ((topSkills as string[])?.length > 0) {
      setTopSkills(
        (topSkills as string[])?.filter((data: string) => {
          return data !== skill;
        })
      );
    }
  };

  return (
    <ProfileSectionContainer
      header="Skills and experience"
      paragraph="Tell us more about your work and skillset"
    >
      <div className={classes.container}>
        <DropdownWithSearch
          label="Top skills"
          options={[
            "Communication",
            "Collaboration",
            "Adaptability",
            "Public speaking",
            "Leadership",
            "Time management",
            "Emotional intelligence",
            "Creativity",
            "Problem solving",
            "Critical thinking",
          ]}
          isRequired
          title={"What amazing skills or talent do you have?"}
          selected={topSkillsText}
          setSelected={setTopSkillsText}
        />
        <div className={classes.topSkillsContainer}>
          {(topSkills as any[])?.length > 0 &&
            (topSkills as any[])?.map((data: any, i: number) => {
              return (
                <div key={i} className={classes.topSkillsChip}>
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      filtertopSkills(data);
                    }}
                  >
                    <path
                      d="M6 18L18 6M6 6L18 18"
                      stroke="#2E2E2E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span>{data}</span>
                </div>
              );
            })}
        </div>
        <DropdownWithSearch
          label="Employment status"
          options={["Unemployed", "Self-employed", "Freelancer", "Employed"]}
          isRequired
          title={"Select"}
          selected={employmentStatus}
          setSelected={setEmploymentStatus}
        />

        <DropdownWithSearch
          label="Education level "
          options={[
            "Secondary school",
            "Post secondary (vocational and technical)",
            "National diploma",
            "Higher national diploma",
            "Undergraduate",
            "Graduate",
            "Master’s degree",
            "Doctorate degree",
          ]}
          isRequired
          title={"Select"}
          selected={educationLevel}
          setSelected={setEducationLevel}
        />

        <DropdownWithSearch
          label="Experience level"
          options={["Beginner", "Intermediate", "Expert"]}
          isRequired
          title={"Select"}
          selected={experienceLevel}
          setSelected={setExperienceLevel}
        />
      </div>

      {isEdited && (
        <div className={classes.buttonSection}>
          <Button
            onClick={updateSkillsHandler}
            loading={updateSkillsHandlerObject?.isLoading}
          >
            Update
          </Button>
        </div>
      )}
    </ProfileSectionContainer>
  );
};

export default ProfileProfileSkillsAndExperience;
