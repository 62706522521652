import { useNavigate, useParams } from "react-router-dom";
import Button from "../../Components/Button/Button";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import classes from "./CourseDetailPageCourseProgress.module.css";

const CourseDetailPageCourseProgress = () => {
  // Router
  const navigate = useNavigate();
  const { courseId } = useParams();
  const currentParams = new URLSearchParams(window.location.search);
  const activeCohort = currentParams.get("activeCohort");

  return (
    <section className={classes.container}>
      <ProgressBar percentage={0} />
      <Button
        onClick={() => {
          navigate(
            `/courses/${courseId}/overview?activeCohort=${activeCohort}&activeModule=71`
          );
        }}
      >
        Resume Course
      </Button>
    </section>
  );
};

export default CourseDetailPageCourseProgress;
